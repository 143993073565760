import { TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { assistantStore } from '../../../store/assistant.store';

export const AssistantModalNameView = observer(() => {
    const { title, setTitle } = assistantStore.assistantModalStore;

    return (
        <>
            <TextField
                type="text"
                name="title"
                label="Название списка"
                placeholder="Введите название списка"
                required
                value={title}
                onChange={setTitle}
            />
        </>
    );
});
