import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accounts } from './accounts/Accounts';
import stls from '@f_qwep/styles/component/settings/Settings.module.sass';
import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { Suppliers } from './suppliers/Suppliers';
import { MenuSettings } from './MenuSettings';
import { useWindowSize } from '@f_hooks/index';
import { SettingsContext } from '../../context/Settings_context';
import { Margin } from './margin/Margin';
import { AdditionalSettings } from './additionalSettings/AdditionalSettings';
import { MyGroup } from '../group/MyGroup';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Tariff } from '@f_qwep/types';
import { Transfer1c } from './transfer1c/Transfer1c';

export function Settings() {
    const { tariff } = useContext(QwepContext);
    const { selectedPageName, navigatinHandler } = useContext(SettingsContext);
    const [mobile, setMobile] = useState(false);
    const [access, setAccess] = useState(false);
    const { width } = useWindowSize();

    useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    return (
        <LayoutPage classNames={[stls.container]}>
            {!mobile && <MenuSettings />}
            {selectedPageName === 'accounts' ? (
                <Accounts navigatinHandler={navigatinHandler} />
            ) : selectedPageName === 'suppliers' ? (
                <Suppliers />
            ) : selectedPageName === 'margin' ? (
                <Margin />
            ) : selectedPageName === 'addSettings' ? (
                <AdditionalSettings />
            ) : selectedPageName === 'group_accounts' ? (
                <MyGroup />
            ) : selectedPageName === 'transfer1c' ? (
                <Transfer1c />
            ) : (
                <></>
            )}
        </LayoutPage>
    );
}
