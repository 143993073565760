import { Alert } from '@f_general/alert';
import { TAssistantListProfile } from '@f_qwep/components/assistant/types/assistant-list-profile.type';
import {
    IAssistantProfile,
    ICreateAssistantProfile1cVariable,
    ICreateAssistantProfileVariables,
} from '@f_qwep/components/assistant/types/assistant-profile.type';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';

export class AssistantApi {
    static async getListProfile(): Promise<TAssistantListProfile[]> {
        const response = await callPlatformAPI('qwep/assistant/list-profile', 'get');

        if (response.data.isOk && response.data.result.isOk) {
            return response.data.result.nestedResult;
        }
        Alert({
            type: 'error',
            text: 'Запрос на получение профилей ассистента завершился с ошибкой, обратитесь в службу поддержки',
            singleShow: true,
        });
        return [];
    }

    static async getProfile(currentListId: string): Promise<IAssistantProfile[]> {
        const response = await callPlatformAPI(`qwep/assistant/get/profile/${currentListId}`, 'get');
        if (response.data.isOk && response.data.result.isOk) {
            return response.data.result.nestedResult;
        }

        Alert({
            type: 'error',
            text: 'Запрос на получение списка профиля ассистента завершился с ошибкой, обратитесь в службу поддержки',
            singleShow: true,
        });
        return [];
    }

    static async deleteProfile(currentListId: string): Promise<IAssistantProfile[]> {
        const response = await callPlatformAPI(`qwep/assistant/delete/profile/${currentListId}`, 'delete');
        if (response.data.isOk && response.data.result.isOk) {
            return response.data.result.nestedResult;
        }
        Alert({
            type: 'error',
            text: 'Запрос на получение удаление профиля ассистента завершился с ошибкой, обратитесь в службу поддержки',
            singleShow: true,
        });
        return [];
    }

    static async createProfile(body: ICreateAssistantProfileVariables): Promise<TAssistantListProfile | null> {
        const response = await callPlatformAPI(`qwep/assistant/create/profile`, 'post', body);
        if (response.data.isOk && response.data.result.isOk) {
            Alert({
                type: 'success',
                text: 'Новый список успешно добавлен',
                singleShow: true,
            });
            return response.data.result.nestedResult;
        }
        Alert({
            type: 'error',
            text: 'Запрос на создание списка профиля ассистента завершился с ошибкой, обратитесь в службу поддержки',
            singleShow: true,
        });
        return null;
    }

    static async createProfile1c(body: ICreateAssistantProfile1cVariable): Promise<TAssistantListProfile | null> {
        const response = await callPlatformAPI(`qwep/assistant/create/profile/1c`, 'post', body);
        if (response.data.isOk && response.data.result.isOk) {
            Alert({
                type: 'success',
                text: 'Новый список успешно добавлен',
                singleShow: true,
            });
            return response.data.result.nestedResult;
        }
        Alert({
            type: 'error',
            text: 'Запрос на создание списка профиля ассистента 1c завершился с ошибкой, обратитесь в службу поддержки',
            singleShow: true,
        });
        return null;
    }

    static async uploadFile(file: File): Promise<IAssistantProfile[]> {
        const formData = new FormData();
        formData.append('items-file', file);

        const response = await callPlatformAPI(
            'qwep/assistant/upload/file',
            'post',
            { 'items-file': file },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            },
        );

        if (response.data.isOk && response.data.result.isOk) {
            return response.data.result.nestedResult;
        }

        Alert({
            type: 'error',
            text: 'Ошибка при загрузке файла, обратитесь в службу поддержки',
            singleShow: true,
        });

        return [];
    }
}
