import { FormikController } from '@f_general/formik/FormikController';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { Box, Button, CardContent, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useContext } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/components/settings/Settings.module.sass';
import { TypeChangeEmail, TypeChangePasword, TypeChangePhone } from '@f_types/index';

type TypeDataProps = {
    open: boolean;
    setOpen: (value: boolean) => void;
    handleModalClose: () => void;
    dataChange: any;
    setDataChange: any;
    sendDataModalHandler: any;
};

export default function ProfileModal({ open, dataChange, sendDataModalHandler, handleModalClose }: TypeDataProps) {
    const { colors } = useContext(ThemeContext);
    const data = (() => {
        switch (dataChange.type) {
            case 'password':
                return {
                    password: '',
                    new_password: '',
                    repeatNew_password: '',
                } as TypeChangePasword;
            case 'email':
                return {
                    email: '',
                    new_email: '',
                } as TypeChangeEmail;
            case 'tel':
                return {
                    tel: '',
                    new_tel: '',
                } as TypeChangePhone;
        }
    })();

    return (
        <Modal open={open}>
            <Box style={{ background: colors.theta }} className={stls.modal}>
                <CardContent className={stls.modalContent}>
                    <FormikWrapper
                        icon={dataChange.icon}
                        title={dataChange.title}
                        onSubmit={sendDataModalHandler}
                        initialValues={data}
                        validShema={dataChange.shema}>
                        <Typography className={stls.modalTypography}>Текущий {dataChange.name}</Typography>
                        <FormikController
                            className={stls.inputModal}
                            control="input"
                            type={dataChange.type}
                            name={dataChange.type}
                            placeholder={`Введите текущий ${dataChange.name}`}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                        />
                        <Typography className={stls.modalTypography}>Новый {dataChange.name}</Typography>
                        <FormikController
                            control="input"
                            type={dataChange.type}
                            name={`new_${dataChange.type}`}
                            placeholder={`Введите новый ${dataChange.name}`}
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                        />
                        {dataChange.type == 'password' && (
                            <>
                                <Typography className={stls.modalTypography}>
                                    Подтвердите новый {dataChange.name}
                                </Typography>
                                <FormikController
                                    control="input"
                                    type={dataChange.type}
                                    name={`repeatNew_${dataChange.type}`}
                                    placeholder={`Повторите новый ${dataChange.name}`}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                />
                            </>
                        )}

                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                            }}>
                            <Button
                                className={stls.modalButton}
                                onClick={handleModalClose}
                                sx={{
                                    background: colors.delta,
                                    color: colors.phi,
                                    '&:hover': { color: colors.alpha },
                                }}>
                                Отменить
                            </Button>
                            <Button className={stls.modalButton} variant="contained" type="submit">
                                Подтвердить
                            </Button>
                        </div>
                    </FormikWrapper>
                </CardContent>
            </Box>
        </Modal>
    );
}
