interface IAssistantFilter {
    priceThreshold: number;
    availabilityCheck: boolean;
    onPromoInclude: boolean;
}

export interface IAssistantPrice {
    value: number[];
    currency: string;
}

export enum EAssistantItemState {
    FOUND = 'FOUND',
    ON_CHECK = 'ON_CHECK',
    CONFIRMED = 'CONFIRMED',
    NO_CONFIRMED = 'NO_CONFIRMED',
    NO_ACCESS = 'NO_ACCESS',
}

export enum EAssistantItemStatus {
    NULL = 'NULL', // в очереди на обработку
    FOUND = 'FOUND', // найден
    ON_CHECK = 'ON_CHECK', // проверен
    CONFIRMED = 'CONFIRMED', // подтвержден
    NO_CONFIRMED = 'NO_CONFIRMED', // не подтвержден
    NO_ACCESS = 'NO_ACCESS', // поставщик не авторизован
}

export interface INormalizedCurrentListProfile {
    id: number;
    brand: string;
    article: string;
    averageAvailability: number | null;
    price: IAssistantPrice;
    vendorName: string | null;
    partName: string | null;
    warehouse: string | null;
    state: EAssistantItemStatus;
    promo: boolean;
}

interface IAssistantProfileItem {
    brand: string;
    article: string;
    price: IAssistantPrice;
    averageAvailability: number;
    vendorName: string | null;
    partName: string | null;
    warehouse: string | null;
    state: EAssistantItemState;
    promo: boolean;
}

export interface IAssistantProfile {
    id: number;
    item: {
        id: number;
        brand: string;
        article: string;
    };
    filter: IAssistantFilter;
    result: IAssistantProfileItem[];
}

export interface ICreateAssistantProfileVariables {
    name: string;
    items: {
        item: {
            id: number;
            brand: string;
            article: string;
        };
        filter: IAssistantFilter;
    }[];
}

export interface ICreateAssistantProfile1cVariable {
    name: string;
    subscriptionCode: string;
}
