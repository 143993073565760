import { GeneralContext } from '@f_context/General_context';
import { Alert } from '@f_general/alert';
import { CustomTooltip } from '@f_general/CustomTooltip';
import { EAssistantItemStatus } from '@f_qwep/components/assistant/types/assistant-profile.type';
import { useAssistantStatus } from '@f_qwep/components/assistant/view/table/useAssistantStatus';
import { SearchContext } from '@f_qwep/context/Search_context';
import { SettingsContext } from '@f_qwep/context/Settings_context';
import HelpIcon from '@mui/icons-material/Help';
import SendIcon from '@mui/icons-material/Send';
import { IconButton } from 'alex-qwep-ui-kit';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

type TypeCellConfirmationProps = {
    article: string;
    brand: string;
    vendorName: string;
    state: EAssistantItemStatus;
    styles: any;
    promo: boolean;
};

export function AssistantConfirmationColumn({
    article,
    brand,
    vendorName,
    state,
    styles,
    promo,
}: TypeCellConfirmationProps) {
    const navigation = useNavigate();
    const { navigatinHandler } = useContext(SettingsContext);
    const { getSearchId, searchResults } = useContext(SearchContext);

    const { seIsRunAssistantVendorSearch, setAssistantVendor } = useContext(GeneralContext);

    const searchHandler = async () => {
        const resultSearchId = await getSearchId({ article, brand });
        if (resultSearchId.result.entity?.searchId) {
            await searchResults(resultSearchId.tabId, resultSearchId.result.entity?.searchId, article, brand);
        } else if (resultSearchId.result.errors) {
            if (resultSearchId.result.errors)
                resultSearchId.result.errors.map((item: any) => {
                    Alert({
                        type: 'warning',
                        text: item.message,
                        time: 8000,
                    });
                });
        }
    };

    const infoHandler = () => {
        navigation('/app-qwep/settings');
        navigatinHandler('suppliers');
        seIsRunAssistantVendorSearch(true);
        setAssistantVendor(vendorName);
    };

    const { borderColor, status } = useAssistantStatus(state, promo);

    return (
        <div className={styles.cellConfirmation}>
            <div className={styles.cellStatus} style={{ color: borderColor, border: `0.5px solid ${borderColor}` }}>
                {status}
            </div>
            {state === EAssistantItemStatus.CONFIRMED && (
                <CustomTooltip wrapperStyle={styles.customTooltipWrapper} componetTooltip={'Поиск'}>
                    <IconButton
                        style={{ width: '20px', height: '20px', backgroundColor: borderColor }}
                        className={styles.sendBtn}
                        onClick={() => searchHandler()}>
                        <SendIcon style={{ width: '15px', height: '15px' }} className={styles.icon} />
                    </IconButton>
                </CustomTooltip>
            )}
            {state === EAssistantItemStatus.NO_ACCESS && (
                <CustomTooltip
                    componetTooltip={'Перейти к информации о поставщике'}
                    customStyle={{ left: '-60px' }}
                    wrapperStyle={styles.customTooltipWrapper}>
                    <IconButton
                        style={{ width: '20px', height: '20px', backgroundColor: borderColor }}
                        className={styles.sendBtn}
                        onClick={() => infoHandler()}>
                        <HelpIcon style={{ width: '15px', height: '15px' }} className={styles.icon} />
                    </IconButton>
                </CustomTooltip>
            )}
        </div>
    );
}
