import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/orders/Orders.module.sass';
import { useWindowSize } from '@f_hooks/index';
import { SearchBase } from '@f_general/SearchBase';
import { PageNoData } from '@f_qwep/general/PageNoData';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import _ from 'lodash';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { ErrorsContext } from '@f_qwep/context/Errors_context';
import { OrdersPanel } from './OrdersPanel';
import { MobileButtonOrder } from './MobileButtonOrder';
import { OrderTable } from './table';

dayjs.extend(isBetween);

interface OrderProps {
    orders: any;
}

export function Order({ orders }: OrderProps) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const { setOpenExportModal, setExportData, setTypeExport } = useContext(QwepContext);
    const { filterOrder, setFilterOrder, orderFiltersReducer } = useContext(FilterContext);
    const { setAllErrors, vendorErrors } = useContext(ErrorsContext);

    const [mobile, setMobile] = useState(false);
    const [ordersTable, setOrdersTable] = useState(orders);

    const { width } = useWindowSize();

    useEffect(() => {
        setMobile(width < 950);
    }, [width]);

    useEffect(() => {
        setOrdersTable(orders);
    }, [orders]);

    const filterCalendar = (orders: any) => {
        const { minDate, maxDate } = filterOrder;
        return orders.filter((row: any) => dayjs(row.datetimeOrder).isBetween(minDate, maxDate, 'day', '[]'));
    };

    return (
        <LayoutPage classNames={stls.container}>
            {width > 950 && orders?.length > 0 && <OrdersPanel items={filterCalendar(orders)} />}
            <div style={{ width: '100%' }}>
                {orders?.length ? (
                    <>
                        <div className={stls.appbarGroup}>
                            <SearchBase
                                data={orders}
                                placeholder={t('qwep.orders.searchOrders')}
                                setData={setOrdersTable}
                                type={'ordersList'}
                            />
                            {mobile && <MobileButtonOrder items={filterCalendar(ordersTable)} />}
                        </div>
                        <OrderTable orders={ordersTable} />
                    </>
                ) : (
                    <PageNoData type={'orders'} />
                )}
            </div>
        </LayoutPage>
    );
}
