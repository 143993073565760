import { ThemeContext } from '@f_context/Theme_context';
import { Alert } from '@f_general/alert';
import { AccountsContext } from '@f_qwep/context/Accounts_context';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { SearchContext } from '@f_qwep/context/Search_context';
import { Tariff } from '@f_qwep/types';
import { useContext, useEffect, useMemo, useState } from 'react';
import { SearchResultsContext } from '../../../context/SearchResult_context';
import { IconBasket } from '../../icons/IconBasket';

export function AddToBasket({ info }: any) {
    const { colors, currentTheme } = useContext(ThemeContext);
    const { tariff } = useContext(QwepContext);
    const { accounts, vendors } = useContext(AccountsContext);
    const { setOpenAddBasket, setCardItem } = useContext(SearchResultsContext);
    const { filter } = useContext(SearchContext);

    // TODO: нужно было быстро закрыть задачу - очень неоптимальное решение
    const currentVendor = useMemo(() => {
        const vendor = vendors.find((vendor) => info.row.original.vendorId === vendor.id);
        return vendor;
    }, [vendors, info.row.original.vendorId]);

    const isOrderCheckOut = useMemo(() => {
        if (!currentVendor) {
            return false;
        }
        return currentVendor.checkout;
    },[currentVendor])


    const [style, setStyle] = useState({
        basketButtonColor: {
            background: colors.delta,
            border: `1px solid ${colors.phi}`,
            color: currentTheme == 'light' ? colors.omicron : colors.phi,
        },
        basketColor:isOrderCheckOut? colors.lightgreen : colors.phi,
    });

    useEffect(() => {
        setStyle({
            basketButtonColor: {
                background: colors.delta,
                border: `1px solid ${colors.phi}`,
                color: currentTheme == 'light' ? colors.omicron : colors.phi,
            },
            basketColor: isOrderCheckOut ? colors.lightgreen : colors.phi,
        });
    }, [filter]);

    const attachedHandler = () => {
        setOpenAddBasket(true);
        if (accounts.some((item: any) => info.row.original.accountId === item.id && item.promo)) {
            setCardItem({ ...info.row.original, promo: true });
        } else {
            setCardItem({ ...info.row.original, promo: false });
        }
    };

    const clickBasketHandler = () => {
        if (tariff === Tariff.LITE) {
            Alert({
                type: 'info',
                text: 'Этот функционал недоступен на вашем тарифе',
            });
        } else {
            attachedHandler();
        }
    };

    return (
        <button onClick={clickBasketHandler}>
            <IconBasket color={style.basketColor} />
        </button>
    );
}
