import React, { useContext, useEffect, useState } from 'react';
import normaiCSS from '@f_qwep/styles/component/searchResult/SortLeftPanel.module.sass';
import miniCSS from '@f_qwep/styles/component/searchResult/MiniSortLeftPanel.module.sass';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useWindowSize } from '@f_hooks/index';
import { useTranslation } from 'react-i18next';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { ThemeContext } from '@f_context/Theme_context';
import { useRenderVendors } from '@f_qwep/components/filters/filterLeftPanel/model/hooks/useRenderVendors';
import { FilterPopUpOverflow } from '@f_qwep/components/filters/filterLeftPanel/ui/FilterPopUpOverflow';
import { useRenderWarehouse } from '@f_qwep/components/filters/filterLeftPanel/model/hooks/useRenderWarehouse';
import { useRenderBrands } from '@f_qwep/components/filters/filterLeftPanel/model/hooks/useRenderBrands';
import { FilterLeftPanelActions } from '@f_qwep/components/filters/filterLeftPanel/ui/filterActions/FilterLeftPanelActions';
import { useFilterLeftPanelStore } from '@f_qwep/components/filters/filterLeftPanel/context/FilterLeftStore.context';
import { FilterLeftPanelCurrentTemplate } from '@f_qwep/components/filters/filterLeftPanel/ui/template/FilterLeftPanelCurrentTemplate';
import { observer } from 'mobx-react-lite';
import { FilterLeftGlobalFilter } from '@f_qwep/components/filters/filterLeftPanel/ui/filterItems/FilterLeftGlobalFilter';
import { FilterLeftQuantity } from '@f_qwep/components/filters/filterLeftPanel/ui/filterItems/FilterLeftQuantity';
import { FilterLeftShipmentDays } from '@f_qwep/components/filters/filterLeftPanel/ui/filterItems/FilterLeftShipmentDays';
import { FilterLeftPrice } from '@f_qwep/components/filters/filterLeftPanel/ui/filterItems/FilterLeftPrice';

export const FilterLeftPanel = observer(({ items, filter, filtersReducer }: any) => {
    const { t } = useTranslation();
    const store = useFilterLeftPanelStore();

    const { miniTableRes, priceMarginIsActive, hideVendorIsActive, priceMarginState } = useContext(QwepContext);
    const { colors, currentTheme } = useContext(ThemeContext);

    const stls = miniTableRes ? miniCSS : normaiCSS;

    const [sortVendor, setSortVendor] = useState<boolean>(false);
    const [sortWarehouse, setSortWarehouse] = useState<boolean>(false);
    const [sortByPrice, setSortByPrice] = useState<boolean>(false);

    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    const [inputBrand, setInputBrand] = useState<string>('');
    const [inputVendor, setInputVendor] = useState<string>('');
    const [inputWarehouse, setInputWarehouse] = useState<string>('');

    const tooltipLaunchSizeDefault: number = 13;
    const tooltipLaunchSizeBrand: number = 7;

    useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    // производители
    const { items: renderBrands, itemsLength: renderBrandsLength } = useRenderBrands({
        items,
        filtersReducer,
        filter,
        stls,
        currentTheme,
        isSort: sortByPrice,
        priceMarginState,
        priceMarginIsActive,
        colors,
        searchedWord: inputBrand,
        tooltipLaunchSize: tooltipLaunchSizeBrand,
    });

    // поставщики
    const { items: renderVendors, itemsLength: renderVendorsLength } = useRenderVendors({
        items,
        filtersReducer,
        filter,
        stls,
        currentTheme,
        isSort: sortVendor,
        searchedWord: inputVendor,
        tooltipLaunchSize: tooltipLaunchSizeDefault,
    });

    // склады
    const { items: renderWarehouse, itemsLength: renderWarehouseLength } = useRenderWarehouse({
        items,
        filtersReducer,
        filter,
        stls,
        currentTheme,
        isSort: sortWarehouse,
        searchedWord: inputWarehouse,
        tooltipLaunchSize: tooltipLaunchSizeDefault,
    });

    const handleClickBrand = () => {
        setSortByPrice((prev) => !prev);
    };

    const handleClickVendor = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setSortVendor(!sortVendor);
    };

    const handleClickWarehouse = () => {
        setSortWarehouse(!sortWarehouse);
    };

    return (
        <div id="filterLeftPanel" className={stls.listNav}>
            <FilterLeftPanelActions styles={stls} colors={colors} />
            <FilterLeftPanelCurrentTemplate />
            <FilterLeftGlobalFilter
                stls={stls}
                filter={filter}
                filtersReducer={filtersReducer}
                t={t}
                currentTheme={currentTheme}
                colors={colors}
            />
            <FilterPopUpOverflow
                verticalAlign={'top'}
                listLength={renderBrandsLength}
                inputWord={inputBrand}
                setInputWord={setInputBrand}
                currentTheme={currentTheme}>
                <div className={stls.listItemNav}>
                    <h5 className={stls.subtitle}>{t('qwep.search.brands')}</h5>
                    <div className={stls.icon} onClick={handleClickBrand}>
                        {sortByPrice ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </div>
                </div>
                <div id="searchBrands" className={stls.listItemCheck}>
                    {renderBrands}
                </div>
            </FilterPopUpOverflow>
            {!(hideVendorIsActive && priceMarginIsActive) && (
                <FilterPopUpOverflow
                    verticalAlign={'middle'}
                    listLength={renderVendorsLength}
                    inputWord={inputVendor}
                    setInputWord={setInputVendor}
                    currentTheme={currentTheme}>
                    <div className={stls.listItemNav}>
                        <h5 className={stls.subtitle}>{t('qwep.search.vendors')}</h5>
                        <div className={stls.icon} onClick={handleClickVendor}>
                            {sortVendor ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        </div>
                    </div>
                    <div id="searchVendors" className={stls.listItemCheckSmall}>
                        {renderVendors}
                    </div>
                </FilterPopUpOverflow>
            )}
            <FilterLeftQuantity
                styles={stls}
                filter={filter}
                t={t}
                currentTheme={currentTheme}
                colors={colors}
                store={store.filterLeft}
                filtersReducer={filtersReducer}
            />
            <FilterLeftShipmentDays
                styles={stls}
                filter={filter}
                t={t}
                currentTheme={currentTheme}
                colors={colors}
                store={store.filterLeft}
                filtersReducer={filtersReducer}
            />
            <FilterLeftPrice
                styles={stls}
                filter={filter}
                t={t}
                currentTheme={currentTheme}
                colors={colors}
                store={store.filterLeft}
                filtersReducer={filtersReducer}
            />
            <FilterPopUpOverflow
                verticalAlign={'bottom'}
                inputWord={inputWarehouse}
                setInputWord={setInputWarehouse}
                currentTheme={currentTheme}
                listLength={renderWarehouseLength}>
                <div className={stls.listItemNav}>
                    <h5 className={stls.subtitle}>{t('qwep.search.warehouses')}</h5>
                    <div className={stls.icon} onClick={handleClickWarehouse}>
                        {sortWarehouse ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                    </div>
                </div>
                <div className={stls.listItemCheck}>{renderWarehouse}</div>
            </FilterPopUpOverflow>
        </div>
    );
});
