import { useTranslation } from 'react-i18next';
import stls from '@f_admin/styles/components/userInfo/Tariff.module.sass';
import { useContext, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { Button } from '@mui/material';
import GeneralModal from '@f_general/GeneralModal';
import { Apps } from '@f_types/index';
import { Alert } from '@f_general/alert';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function Tariff({ app, usersToApplication }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { currentWorkspace, userInfo, updateTariff } = useContext(UserAccountContext);
    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});

    const closeHandler = () => {
        setDataModal({});
        setOpen(false);
    };

    const clickModalHandler = (type: string) => {
        switch (type) {
            case Apps['Admin Panel']:
                Alert({
                    type: 'warning',
                    text: 'Для этого приложения нет тарифов',
                    time: 6000,
                });
                break;
            case Apps.Qwep:
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `${t('adminPanel.updateTariff')} ${app.application.applicationName}`,
                    props: [
                        {
                            control: 'select',
                            name: 'tariff',
                            title: t('adminPanel.tariff'),
                            options: [
                                { key: 'WEB LITE', value: 'WEB_LITE' },
                                { key: 'WEB PRO', value: 'WEB_PRO' },
                                { key: 'WEB PRO+', value: 'WEB_PRO+' },
                                { key: 'WEB Business', value: 'WEB_Business' },
                            ],
                        },
                        {
                            title: t('adminPanel.countUser'),
                            control: 'input',
                            name: 'countUser',
                            type: 'number',
                        },
                    ],
                    shema: null,
                    type: Apps.Qwep,
                    data: {
                        tariff: app.propertyApplication?.tariff ?? '',
                        countUser: app.propertyApplication?.maxLimit ?? 1,
                        type: Apps.Qwep,
                    },
                } as TypeDataModal);
                setOpen(true);
                break;
            case Apps.Data:
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `${t('adminPanel.updateTariff')} ${app.application.applicationName}`,
                    props: [
                        {
                            control: 'checkbox',
                            title: '',
                            name: 'cross',
                            type: 'checkbox',
                            options: [
                                {
                                    key: 'Аналоги',
                                    value: true,
                                },
                            ],
                        },
                        {
                            control: 'checkbox',
                            title: '',
                            name: 'workWithLists',
                            type: 'checkbox',
                            options: [
                                {
                                    key: 'Работа со списками',
                                    value: false,
                                },
                            ],
                        },
                        {
                            control: 'checkbox',
                            title: '',
                            name: 'priceСontrol',
                            type: 'checkbox',
                            options: [
                                {
                                    key: 'Контроль цен',
                                    value: false,
                                },
                            ],
                        },
                        {
                            control: 'checkbox',
                            title: '',
                            name: 'workWithFiles',
                            type: 'checkbox',
                            options: [
                                {
                                    key: 'Работа с файлами',
                                    value: false,
                                },
                            ],
                        },
                        {
                            title: t('adminPanel.countUser'),
                            control: 'input',
                            name: 'countUser',
                            type: 'number',
                        },
                    ],
                    shema: null,
                    type: Apps.Data,
                    data: {
                        tariff: 'CUSTOM',
                        cross: app.propertyApplication.cross ?? true,
                        priceСontrol: app.propertyApplication.priceСontrol ?? false,
                        workWithLists: app.propertyApplication.workWithLists ?? false,
                        workWithFiles: app.propertyApplication.workWithFiles ?? false,
                        countUser: app.propertyApplication?.maxLimit ?? 1,
                        type: Apps.Data,
                    },
                } as TypeDataModal);
                setOpen(true);
                break;
        }
    };

    const sendDataModalHandler = async (values: any) => {
        switch (values.type) {
            case Apps.Qwep:
                if (values.tariff && values.countUser) {
                    const property = {
                        ...app.propertyApplication,
                        tariff: values.tariff,
                        maxLimit: values.countUser,
                        currentLimit: usersToApplication?.length ?? 1,
                    };
                    const resultUpdateTariffQwep = await updateTariff({
                        email: userInfo.email,
                        applicationWorkspaceId: app.applicationWorkspaceId,
                        workspaceId: currentWorkspace.workspaceId,
                        applicationId: app.application.applicationId,
                        property,
                        usersToApplication,
                    });
                    if (resultUpdateTariffQwep.isOk) {
                        Alert({
                            type: 'success',
                            text: t('general.success'),
                        });
                        closeHandler();
                    } else {
                        Alert({
                            type: 'error',
                            text: t('general.error'),
                        });
                    }
                } else {
                    Alert({
                        type: 'warning',
                        text: 'Не выбран тариф или количество пользователей',
                    });
                }
                break;
            case Apps.Data:
                if (values.tariff && values.countUser) {
                    const property = {
                        ...app.propertyApplication,
                        tariff: values.tariff,
                        maxLimit: values.countUser,
                        currentLimit: usersToApplication?.length ?? 1,
                        cross: values.cross,
                        priceСontrol: values.priceСontrol,
                        workWithLists: values.workWithLists,
                        workWithFiles: values.workWithFiles,
                    };
                    const resultUpdateTariffCrossdata = await updateTariff({
                        email: userInfo.email,
                        applicationWorkspaceId: app.applicationWorkspaceId,
                        workspaceId: currentWorkspace.workspaceId,
                        applicationId: app.application.applicationId,
                        property,
                        usersToApplication,
                    });
                    if (resultUpdateTariffCrossdata.isOk) {
                        Alert({
                            type: 'success',
                            text: t('general.success'),
                        });
                        closeHandler();
                    } else {
                        Alert({
                            type: 'error',
                            text: t('general.error'),
                        });
                    }
                } else {
                    Alert({
                        type: 'warning',
                        text: 'Не выбран тариф или количество пользователей',
                    });
                }
                break;
        }
    };

    return (
        <>
            <Button
                onClick={() => clickModalHandler(app.application.applicationSysName)}
                className={stls.btn}
                variant="contained">
                {t('adminPanel.changeTariff')}
            </Button>
            <GeneralModal
                openModal={open}
                closeModal={closeHandler}
                sendDataModalHandler={sendDataModalHandler}
                dataModal={dataModal}
            />
        </>
    );
}
