import { EAssistantItemStatus } from '@f_qwep/components/assistant/types/assistant-profile.type';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/assistant/view/table/AssistantColumn.config';
import { useAssistantStatus } from '@f_qwep/components/assistant/view/table/useAssistantStatus';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';

interface IAssistantPartNameColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    partName: string;
    state: EAssistantItemStatus;
    promo: boolean;
}

export const AssistantPartNameColumn = (props: IAssistantPartNameColumnProps) => {
    const { styles, partName, state, promo } = props;
    const { textColor } = useAssistantStatus(state, promo);

    return (
        <Tooltip title={partName}>
            <p style={{ color: textColor }} className={cn(styles.cellRow, styles.indentLeft)}>
                {partName ? partName : '-'}
            </p>
        </Tooltip>
    );
};
