import React from 'react';
import { AssistantProvider } from '@f_qwep/components/assistant/context/Assistant.context';
import { AssistantView } from '@f_qwep/components/assistant/view/Assistant.view';

export const Assistant = () => {
    return (
        <AssistantProvider>
            <AssistantView />
        </AssistantProvider>
    );
};
