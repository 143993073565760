import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { assistantStore } from '../../../store/assistant.store';

export const CreateListView = observer(() => {
    const { setIsOpen } = assistantStore.assistantModalStore;

    return (
        <Button
            variant="contained"
            onClick={() => {
                setIsOpen(true);
            }}>
            + Создать список
        </Button>
    );
});
