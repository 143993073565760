import { EAssistantItemStatus } from '@f_qwep/components/assistant/types/assistant-profile.type';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/assistant/view/table/AssistantColumn.config';
import { useAssistantStatus } from '@f_qwep/components/assistant/view/table/useAssistantStatus';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';

interface IAssistantVendorColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    vendorName: any;
    state: EAssistantItemStatus;
    promo: boolean;
}

export const AssistantVendorColumn = (props: IAssistantVendorColumnProps) => {
    const { styles, vendorName, state, promo } = props;
    const { textColor } = useAssistantStatus(state, promo);

    return (
        <Tooltip title={vendorName}>
            <p
                style={{
                    color: textColor,
                }}
                className={cn(styles.cellRow)}>
                {vendorName ? vendorName : '-'}
            </p>
        </Tooltip>
    );
};
