import { Login } from '@f_components/auth/Login';
import { IconQWEP } from '@f_components/icons/IconQwep';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/components/auth/Authorization.module.sass';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PasswordForgot } from './PasswordForgot';
import { Registration } from './Registration';
// import { IconVinPin } from '@f_qwep/components/icons/IconVinPin';

export function Unauthenticated() {
    const { t } = useTranslation();
    const [alignment, setAlignment] = useState('auth');
    const { colors, currentTheme } = useContext(ThemeContext);

    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    return (
        <Box className={stls.container}>
            <div className={stls.IconQwep}>
                {currentTheme == 'vinpin' ? null : (
                    // <IconVinPin width={'287'} height={'78'} />
                    <IconQWEP width={'287'} height={'78px'} />
                )}
                <div style={{ color: colors.omicron }} className={stls.text}>
                    Цифровой комплекс продуктов для автоматизации и принятия управленческих решений
                </div>
            </div>
            <ToggleButtonGroup
                onChange={handleChange}
                value={alignment}
                exclusive
                color="primary"
                className={stls.toggleGroup}>
                <ToggleButton className={stls.toggle} value="auth">
                    {t('auth.sidebar.authorization')}
                </ToggleButton>
                <ToggleButton className={stls.toggle} value="registr">
                    {t('auth.sidebar.registration')}
                </ToggleButton>
            </ToggleButtonGroup>
            {(alignment === 'auth' && <Login setAlignment={setAlignment} />) ||
                (alignment === 'registr' && <Registration setAlignment={setAlignment} />) ||
                (alignment === 'forgot' && <PasswordForgot setAlignment={setAlignment} />)}
        </Box>
    );
}
