import { useAssistantStatus } from '@f_qwep/components/assistant/view/table/useAssistantStatus';

export const IconPromoView = ({ state, promo }: any) => {
    const { textColor } = useAssistantStatus(state, promo);
    return (
        <svg
            style={{ display: 'flex', flexShrink: 0, width: '24px', height: '24px' }}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.20496" cy="8.16016" r="7.75" fill={'transparent'} stroke={textColor} strokeWidth="1" />
            <path
                d="M4.05427 4.5H6.15427C6.50427 4.5 6.75761 4.58 6.91427 4.74C7.07427 4.89667 7.15427 5.15 7.15427 5.5V6C7.15427 6.35 7.07427 6.605 6.91427 6.765C6.75761 6.92167 6.50427 7 6.15427 7H4.95427V8H4.05427V4.5ZM6.25427 5.5C6.25427 5.3 6.15427 5.2 5.95427 5.2H4.95427V6.3H5.95427C6.15427 6.3 6.25427 6.2 6.25427 6V5.5ZM9.07908 6.075C8.86908 6.075 8.65241 6.125 8.42908 6.225V8H7.55408V5.35H8.32908L8.37908 5.675C8.66574 5.425 8.96574 5.3 9.27908 5.3H9.50408V6.075H9.07908ZM12.4562 7.25C12.4562 7.5 12.3846 7.69667 12.2412 7.84C12.1012 7.98 11.9062 8.05 11.6562 8.05H10.5062C10.2562 8.05 10.0596 7.98 9.91623 7.84C9.77623 7.69667 9.70623 7.5 9.70623 7.25V6.1C9.70623 5.85 9.77623 5.655 9.91623 5.515C10.0596 5.37167 10.2562 5.3 10.5062 5.3H11.6562C11.9062 5.3 12.1012 5.37167 12.2412 5.515C12.3846 5.655 12.4562 5.85 12.4562 6.1V7.25ZM11.5812 6.175C11.5812 6.04167 11.5146 5.975 11.3812 5.975H10.7812C10.6479 5.975 10.5812 6.04167 10.5812 6.175V7.175C10.5812 7.30833 10.6479 7.375 10.7812 7.375H11.3812C11.5146 7.375 11.5812 7.30833 11.5812 7.175V6.175Z"
                fill={textColor}
            />
            <path
                d="M6.34324 9.3C6.61324 9.3 6.82157 9.4 6.96824 9.6C7.12157 9.49667 7.28657 9.42167 7.46324 9.375C7.64324 9.325 7.80324 9.3 7.94324 9.3H7.99324C8.2199 9.3 8.40157 9.36833 8.53824 9.505C8.6749 9.64167 8.74324 9.82333 8.74324 10.05V12H7.86824V10.2C7.86824 10.15 7.85157 10.1083 7.81824 10.075C7.7849 10.0417 7.74324 10.025 7.69324 10.025H7.66824C7.51824 10.025 7.32657 10.0583 7.09324 10.125V12H6.21824V10.2C6.21824 10.15 6.20157 10.1083 6.16824 10.075C6.1349 10.0417 6.09324 10.025 6.04324 10.025H6.01824C5.86824 10.025 5.67657 10.0583 5.44324 10.125V12H4.56824V9.35H5.34324L5.39324 9.6C5.5499 9.49667 5.7099 9.42167 5.87324 9.375C6.0399 9.325 6.1799 9.3 6.29324 9.3H6.34324ZM11.8923 11.25C11.8923 11.5 11.8206 11.6967 11.6773 11.84C11.5373 11.98 11.3423 12.05 11.0923 12.05H9.94226C9.69226 12.05 9.49559 11.98 9.35226 11.84C9.21226 11.6967 9.14226 11.5 9.14226 11.25V10.1C9.14226 9.85 9.21226 9.655 9.35226 9.515C9.49559 9.37167 9.69226 9.3 9.94226 9.3H11.0923C11.3423 9.3 11.5373 9.37167 11.6773 9.515C11.8206 9.655 11.8923 9.85 11.8923 10.1V11.25ZM11.0173 10.175C11.0173 10.0417 10.9506 9.975 10.8173 9.975H10.2173C10.0839 9.975 10.0173 10.0417 10.0173 10.175V11.175C10.0173 11.3083 10.0839 11.375 10.2173 11.375H10.8173C10.9506 11.375 11.0173 11.3083 11.0173 11.175V10.175Z"
                fill={textColor}
            />
        </svg>
    );
};
