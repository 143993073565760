import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { assistantStore } from '../../../../store/assistant.store';

export const AssistantPosition1cView: FC<any> = observer(() => {
    const { key1c, setKey1c } = assistantStore.assistantModalStore;

    return (
        <div className={styles.fileBlock}>
            <TextField
                type="text"
                label={'Ключ 1С'}
                name="key1c"
                placeholder="Укажите ключ 1C"
                required
                value={key1c}
                onChange={setKey1c}
            />
        </div>
    );
});
