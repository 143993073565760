import { ThemeContext } from '@f_context/Theme_context';
import { assistantStore } from '@f_qwep/components/assistant/store/assistant.store';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { MenuItem, Select } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

export const AssistantListsView = observer(() => {
    const { isLoadingListProfiles, listProfiles, currentListProfile, changeProfile } =
        assistantStore.assistantListProfilesStore;
    const { colors, CustomSelect } = useContext(ThemeContext);

    return currentListProfile?.id ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
                disabled={isLoadingListProfiles}
                input={<CustomSelect />}
                className={styles.select}
                sx={{
                    '& .MuiSelect-select': {
                        padding: '8px',
                        width: '170px',
                        display: 'flex',
                    },
                }}
                autoWidth
                value={currentListProfile.id}
                MenuProps={{
                    sx: {
                        '&& .MuiMenuItem-root': {
                            fontSize: 14,
                            fontFamily: 'Inter',
                            color: colors.omicron,
                            '&:hover': {
                                background: colors.beta,
                            },
                        },
                    },
                }}>
                {listProfiles.map((option) => {
                    return (
                        <MenuItem
                            onClick={async () => {
                                await changeProfile(option.id);
                            }}
                            key={option.id}
                            value={option.id}>
                            {option.name === 'Default' ? 'Основной профиль' : option.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </div>
    ) : (
        <></>
    );
});
