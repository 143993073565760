import {
    EAssistantItemStatus,
    INormalizedCurrentListProfile,
} from '@f_qwep/components/assistant/types/assistant-profile.type';
import { makeAutoObservable } from 'mobx';

interface IAssistantExcelItems {
    brand: string;
    article: string;
    averageAvailability: number | null;
    minPrice: number | null;
    maxPrice: number | null;
    vendorName: string | null;
    partName: string | null;
    warehouse: string | null;
    state: string;
}

export class AssistantExcelStore {
    allItems: IAssistantExcelItems[] = [];
    filteredItems: IAssistantExcelItems[] = [];

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });
    }

    private normalizeExcelItems(items: INormalizedCurrentListProfile[]): IAssistantExcelItems[] {
        return items.map((item) => ({
            brand: item.brand,
            article: item.article,
            partName: item.partName,
            vendorName: item.vendorName,
            averageAvailability: item.averageAvailability ? item.averageAvailability : null,
            minPrice: item.price.value[0] ? item.price.value[0] : null,
            maxPrice: item.price.value[1] ? item.price.value[1] : null,
            warehouse: item.warehouse,
            state: this.normalizeStateItems(item.state),
        }));
    }

    private normalizeStateItems(currentStatus: EAssistantItemStatus): string {
        const status = {
            [EAssistantItemStatus.NULL]: 'В обработке',
            [EAssistantItemStatus.NO_ACCESS]: 'Поставщик для знакомства',
            [EAssistantItemStatus.CONFIRMED]: 'Подтвержден',
        };

        return status[currentStatus];
    }

    setItems(items: INormalizedCurrentListProfile[], isFiltered: boolean = false): void {
        const normalizedItems = this.normalizeExcelItems(items);
        if (isFiltered) {
            this.filteredItems = normalizedItems;
        } else {
            this.allItems = normalizedItems;
        }
    }
}
