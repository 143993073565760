import { useTranslation } from 'react-i18next';
import { MyModal } from '@f_general/MyModal';
import { CardContent, Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import stls from '@f_ucs/styles/components/actionPanel/actionButtonHandlers/createCollectorModal/CreateCollectorModal.module.sass';
import { Alert } from '@f_general/alert';
import { randomKey } from '@f_utils/randomKey';
import { TypeAppInfo, useUCSContext } from '../../../../context/UCS_context';
import { TypeCreateCollector, useCollector } from '../../../../context/collector_context';
import { useWindowSize } from '@f_hooks/index';
import { CollectorModalTitle } from '@f_ucs/components/actionPanel/actionButtonHandlers/CreateCollectorModal/CollectorModalTitle';
import { CollectorPartOfModal } from '@f_ucs/components/actionPanel/actionButtonHandlers/CreateCollectorModal/CollectorPartOfModal';
import { CompanyPartOfModal } from '@f_ucs/components/actionPanel/actionButtonHandlers/CreateCollectorModal/CompanyPartOfModal';

interface CreateCollectorModalProps {
    open: boolean;
    handleClose: any;
    type: 'web' | '1c';
    appInfo: TypeAppInfo;
}

export function CreateCollectorModal({ open, handleClose, type, appInfo }: CreateCollectorModalProps) {
    const { t } = useTranslation();
    const { getUsersByKey, getTokens } = useUCSContext();
    const { createCollector } = useCollector();
    const { width } = useWindowSize();
    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(false);
    //1c
    const [isOpenKeys, setIsOpenKeys] = useState(false);
    const [key1c, setKey1c] = useState('');
    const [tokens1c, setTokens1c] = useState([{ id: randomKey(), key: '', alias: '' }]);

    //qwep
    const [keyWeb, setKeyWeb] = useState('');
    const [userIds, setUserIds] = useState<number[]>([]);
    const [usersKey, setUsersKey] = useState<[]>([]);
    const [webKeyInfo, setWebKeyInfo] = useState({});

    useEffect(() => {
        setMobile(width < 850);
    }, [width]);

    useEffect(() => {
        if (open) {
            if (type === 'web') {
                setKeyWeb(appInfo?.keysWeb[0]?.key);
                getWebUsersByKey(appInfo?.keysWeb[0]?.key);
            }
            if (type === '1c') {
                setKey1c(appInfo?.keys1c[0]?.key);
                get1CTokensByKey(appInfo?.keys1c[0]?.key);
            }
        }
    }, [open]);

    // Обрабатывает выбор ключа и загружает соответствующих пользователей или токены.
    const selectKeyHandler = (e: string) => {
        if (type === 'web') {
            setKeyWeb(e);
            getWebUsersByKey(e);
            setUserIds([]);
        }
        if (type === '1c') {
            setKey1c(e);
            get1CTokensByKey(e);
        }
    };

    // Получаем токены кпользователей
    const get1CTokensByKey = async (key: string) => {
        const result = await getTokens(key);
        if (result.isOk) {
            setUsersKey(result.result.users);
        }
    };

    // Получаем ключи пользователей
    const getWebUsersByKey = async (key: string) => {
        const result = await getUsersByKey(key);
        if (result.isOk) {
            setUsersKey(result.result.users);
            setWebKeyInfo({
                webKeyWorkspaceId: result.result.workspaceId,
                webKeyApplicationWorkspaceId: result.result.applicationWorkspaceId,
            });
        }
    };

    //Обрабатывает изменение состояния чекбоксов для пользователей

    const changeWebUserHandler = (checked: boolean, userId: number) => {
        if (checked) {
            setUserIds([...userIds, userId]);
        } else {
            setUserIds(userIds.filter((item) => item !== userId));
        }
    };

    // Закрывает модальное окно и сбрасывает состояния.
    const closeModal = () => {
        handleClose();
        setTokens1c([{ id: randomKey(), key: '', alias: '' }]);
        setUserIds([]);
    };

    // Обрабатывает изменение значений токенов и псевдонимов для 1С
    const changeRowHandle = (e: string, id: number, alias: boolean) => {
        setTokens1c(
            tokens1c.map((item) => (item.id === id ? { ...item, ...(alias ? { alias: e } : { key: e }) } : item)),
        );
    };

    // Добаавляет строки для ввода токенов 1С
    const addRowHandler = () => {
        setTokens1c([
            ...tokens1c,
            {
                id: randomKey(),
                key: '',
                alias: '',
            },
        ]);
    };

    // Удаляет строки для ввода токенов 1С
    const deleteRowHandler = (id: number) => {
        setTokens1c(tokens1c.filter((item) => item.id !== id));
    };

    // Обрабатывает переключение состояния для отображения токенов 1С
    const tokensHandler = (value: boolean) => {
        if (value) {
            setIsOpenKeys(true);
        } else {
            setIsOpenKeys(false);
            setTokens1c([{ id: randomKey(), key: '', alias: '' }]);
        }
    };

    // Отправляет данные для создания сборщика отчёта. Проверяет на заполненность необходимых полей, создаёт токены 1С и отправляет запрос для создания сборщика отчёта.
    const sendDataHandler = async (values: any) => {
        setLoading(true);

        // Валидация
        if (type === '1c' && isOpenKeys && tokens1c.some((item) => !item.key || !item.alias)) {
            return Alert({
                type: 'warning',
                text: 'Не заполнены ключи или псевдонимы токенов 1С',
            });
        }
        if (type === 'web' && !userIds.length) {
            setLoading(false);
            return Alert({
                type: 'warning',
                text: 'Укажите пользователей',
            });
        }

        let authKey = '';
        let tokens: { key: string; alias: string }[] | number[] = [];

        // Установка значений для типов "web"/"1c"
        if (type === 'web') {
            tokens = userIds;
            authKey = keyWeb;
        } else {
            tokens = isOpenKeys ? tokens1c.map((item) => ({ key: item.key, alias: item.alias })) : [];
            authKey = key1c;
        }

        // Создание сборщика
        const result = await createCollector({
            sameWarehouse: values.sameWarehouse,
            sameStatus: values.sameStatus,
            sameQuantity: values.sameQuantity,
            tokens: tokens,
            type: type,
            authKey: authKey,
            title: values.title,
            ...(type === 'web' && { webKeyInfo: webKeyInfo }),
        } as TypeCreateCollector);
        if (result.isOk) {
            Alert({
                type: 'success',
                text: 'Коллектор успешно создан',
            });
            closeModal();
        } else {
            Alert({
                type: 'error',
                text: result.data?.errors ? result.data.errors : t('general.error'),
            });
        }
        setLoading(false);
    };

    return (
        <Modal open={open} onClose={closeModal}>
            <MyModal className={stls.modal}>
                <CardContent className={stls.modalContent}>
                    <CollectorModalTitle type={type} />
                    <div
                        className={stls.contentUCSModel}
                        style={mobile ? { display: 'flex', flexDirection: 'column' } : {}}>
                        <CollectorPartOfModal
                            sendDataHandler={sendDataHandler}
                            closeModal={closeModal}
                            mobile={mobile}
                            loading={loading}
                        />
                        <CompanyPartOfModal
                            selectKeyHandler={selectKeyHandler}
                            changeWebUserHandler={changeWebUserHandler}
                            usersKey={usersKey}
                            userIds={userIds}
                            type={type}
                            appInfo={appInfo}
                            tokensHandler={tokensHandler}
                            isOpenKeys={isOpenKeys}
                            tokens1c={tokens1c}
                            changeRowHandle={changeRowHandle}
                            deleteRowHandler={deleteRowHandler}
                            addRowHandler={addRowHandler}
                        />
                    </div>
                </CardContent>
            </MyModal>
        </Modal>
    );
}
