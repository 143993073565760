import React from 'react';
import { YouTube } from '@mui/icons-material';
import { Button } from '@mui/material';
import { videoContent } from '@f_utils/content/video.content';

export const WatchEducationVideoView = () => {
    return (
        <Button
            variant="contained"
            style={{ backgroundColor: '#6EBA6E', display: 'flex', flexShrink: 0 }}
            href={videoContent.assistant.rutube}
            target="_blank">
            <YouTube style={{ color: 'white' }} /> {'\u00A0'} Обучающее видео
        </Button>
    );
};
