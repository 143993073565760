import { FormikController } from '@f_general/formik/FormikController';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import stls from '@f_styles/components/auth/Authorization.module.sass';
import colors from '@f_styles/config/Colors.module.sass';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import stl from '@f_styles/general/Universal.module.sass';
import cn from 'classnames';
import { TypeRegistration } from '@f_types/index';
import { useContext, useState } from 'react';
import { AuthContext } from '@f_context/Auth_context';
import { CreateUserCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { Alert } from '@f_general/alert';
import { IconEye } from '@f_components/icons/IconEye';
import { IconCloseEye } from '@f_components/icons/IconCloseEye';
import { useNavigate } from 'react-router-dom';

type TypeRegistrationProps = {
    setAlignment: (value: string) => void;
};

export function Registration({ setAlignment }: TypeRegistrationProps) {
    const { t } = useTranslation();
    const { registration } = useContext(AuthContext);
    const data: TypeRegistration = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        organization: '',
        position: '',
    };
    const [isLoading, setIsLoadind] = useState(false);
    const [showPass, setShowPass] = useState(false);

    const handleClick = () => {
        setShowPass((prev) => !prev);
    };

    const sendDataHandler = async (values: TypeRegistration) => {
        setIsLoadind(true);
        const result = await registration(values);
        if (result.data.isOk) {
            Alert({
                type: 'custom',
                text: `${t('auth.alert.userSuccessfully')} \n ${t('auth.alert.letterSentMail')} ${
                    result.data.result
                } \n`,
            });
            setAlignment('auth');
        } else {
            if (result.data.result) {
                switch (result.data.result.statusNumber) {
                    case 1:
                        Alert({
                            type: 'error',
                            text: t('auth.alert.notAllFields'),
                        });
                        break;
                    case 2:
                        Alert({
                            type: 'error',
                            text: t('auth.alert.passwordsNotMatch'),
                        });
                        break;
                    case 3:
                        Alert({
                            type: 'error',
                            text: t('auth.alert.invalidEmail'),
                        });
                        break;
                    case 4:
                        Alert({
                            type: 'error',
                            text: t('auth.alert.emailAlreadyExists'),
                        });
                        break;
                }
            } else {
                Alert({
                    type: 'error',
                    text: t('auth.alert.userNotCreated'),
                });
            }
        }
        setIsLoadind(false);
    };

    return (
        <Box className={stls.registration}>
            <FormikWrapper onSubmit={sendDataHandler} initialValues={data} validShema={CreateUserCustomerSchema}>
                <div className={stl.flexWrap}>
                    <div className={stls.regTypography}>
                        <Typography className={stls.authTypography}>{t('auth.name')}*</Typography>
                        <FormikController
                            className={stls.inputRegistration}
                            control="input"
                            type="text"
                            placeholder={t('auth.placeholderName')}
                            name="firstName"
                            required
                        />
                    </div>
                    <div className={stls.regTypography}>
                        <Typography className={stls.authTypography}>{t('auth.lastName')}*</Typography>
                        <FormikController
                            className={stls.inputRegistration}
                            control="input"
                            type="text"
                            placeholder={t('auth.placeholderLastName')}
                            name="lastName"
                            required
                        />
                    </div>
                </div>
                <div className={stl.flexWrap}>
                    <div className={stls.regTypography}>
                        <Typography className={stls.authTypography}>{t('auth.email')}*</Typography>
                        <FormikController
                            className={stls.inputRegistration}
                            control="input"
                            type="email"
                            placeholder={t('auth.placeholderEmail')}
                            name="email"
                            required
                            inputProps={{
                                autoComplete: 'new-password',
                            }}
                        />
                    </div>
                    <div className={stls.regTypography}>
                        <Typography className={stls.authTypography}>{t('auth.phone')}*</Typography>
                        <FormikController
                            className={stls.inputRegistration}
                            control="input"
                            type="tel"
                            placeholder="+7 (999) 000-00-00"
                            name="phoneNumber"
                            required
                        />
                    </div>
                </div>
                <div className={stl.flexWrap}>
                    <div className={stls.regTypography}>
                        <Typography className={stls.authTypography}>{t('auth.password')}*</Typography>
                        <FormikController
                            className={stls.inputRegistration}
                            control="input"
                            type={showPass ? 'text' : 'password'}
                            placeholder="хххххххх"
                            name="password"
                            required
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment onClick={handleClick} position="start">
                                        {showPass ? <IconEye /> : <IconCloseEye />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className={stls.regTypography}>
                        <Typography className={stls.authTypography}>{t('auth.confirmPassword')}*</Typography>
                        <FormikController
                            className={stls.inputRegistration}
                            control="input"
                            type={showPass ? 'text' : 'password'}
                            placeholder="хххххххх"
                            name="confirmPassword"
                            required
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment onClick={handleClick} position="start">
                                        {showPass ? <IconEye /> : <IconCloseEye />}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                </div>
                <div className={stl.flexWrap}>
                    <div className={stls.regTypography}>
                        <Typography className={stls.authTypography}>{t('auth.organization')}*</Typography>
                        <FormikController
                            className={stls.inputRegistration}
                            control="input"
                            type="text"
                            placeholder={t('auth.placeholderOrganization')}
                            name="organization"
                            required
                        />
                    </div>
                    <div className={stls.regTypography}>
                        <Typography className={stls.authTypography}>{t('auth.position')}*</Typography>
                        <FormikController
                            className={stls.inputRegistration}
                            control="input"
                            type="text"
                            placeholder={t('auth.placeholderPosition')}
                            name="position"
                        />
                    </div>
                </div>
                <Button
                    className={cn(stls.inputRegistration, stls.btn)}
                    variant="contained"
                    type="submit"
                    disabled={isLoading}>
                    {isLoading && (
                        <CircularProgress style={{ color: colors.psi }} size={'20px'} className={stls.loading} />
                    )}{' '}
                    {t('auth.send')}
                </Button>
            </FormikWrapper>
        </Box>
    );
}
