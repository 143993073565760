import '@f_qwep/styles/baseQwep.sass';
import { Route, Routes } from 'react-router-dom';
import { Info } from '@f_qwep/components/info';
import { SearchResult } from '@f_qwep/components/searchResult';
import { MyAcconts } from './components/group/MyAccounts';
import { Settings } from './components/settings';
import { QwepProviders } from '@f_qwep/context';
import stls from '@f_qwep/styles/general/AppQwep.module.sass';
import { Prices } from './components/prices';
import { Search } from './components/search';
import { SearchItems } from './components/searchItems';
import { IndexBasket } from './components/basket';
import { IndexOrders } from './components/orders';
import Navbar from './components/navbar';
import { BatchPricer } from './components/batchPricer';
import CardResult from './components/searchResult/CardResult';
import AddBasketModal from './components/searchResult/AddBasketModal';
import { HeaderComponent } from './components/header';
import { Subscription } from './components/licenses/Subscription';
import { useContext, useEffect, useState } from 'react';
import { GeneralContext } from '@f_context/General_context';
import { ExportExcelModal } from './general/ExportExcelModal';
import { useWindowSize } from '@f_hooks/index';
import { Manual } from './components/manual/Manual';
import { TariffIcon } from './components/header/TariffIcon';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryComponent } from '../../general/pageError/PageError';
import { Congratulation } from './general/Congratulation';
import { ModalPromoNotification } from './general/promoNotification/ModalPromoNotification';
import { ProtectedRoute } from '@f_qwep/general/ProtectedRoute';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';
import { Assistant } from '@f_qwep/components/assistant/Assistant';

export function AppQwep() {
    const baseRedirectPath = '/app-qwep';
    const { reloadQwep } = useContext(GeneralContext);

    const [loading, setLoading] = useState(false);
    const [mobile, setMobile] = useState(false);

    const { width } = useWindowSize();

    useEffect(() => {
        setMobile(width < 480);
    }, [width]);

    useEffect(() => {
        if (reloadQwep) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [reloadQwep]);

    return (
        <>
            {loading ? (
                <></>
            ) : (
                <ErrorBoundary fallbackRender={() => <ErrorBoundaryComponent app={'Qwep'} />}>
                    <QwepProviders>
                        <div className={stls.general}>
                            <TariffIcon />
                            <HeaderComponent />
                            <Search />
                            <Navbar />
                            <Routes>
                                <Route path={'/'} element={<Info />} />
                                <Route path={'search'} element={<SearchResult />} />
                                <Route path={'search/items'} element={<SearchItems />} />
                                <Route path={'settings/accounts'} element={<MyAcconts />} />
                                <Route
                                    path={'prices'}
                                    element={
                                        <ProtectedRoute redirectPath={baseRedirectPath} product={QwepProductEnum.PRICE}>
                                            <Prices />
                                        </ProtectedRoute>
                                    }
                                />
                                {/* <Route path={'prices'} element={<MaintenancePage />} /> */}
                                <Route path={'settings'} element={<Settings />} />
                                <Route
                                    path={'basket'}
                                    element={
                                        <ProtectedRoute
                                            redirectPath={baseRedirectPath}
                                            product={QwepProductEnum.BASKET}>
                                            <IndexBasket />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path={'orders'}
                                    element={
                                        <ProtectedRoute
                                            redirectPath={baseRedirectPath}
                                            product={QwepProductEnum.ORDERS}>
                                            <IndexOrders />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route path={'batch-pricer'} element={<BatchPricer />} />
                                {/* <Route path={'batch-pricer'} element={<MaintenancePage />} /> */}

                                <Route path={'subscription'} element={<Subscription />} />
                                <Route
                                    path={'purchasing-assistant'}
                                    element={
                                        <ProtectedRoute
                                            redirectPath={baseRedirectPath}
                                            product={QwepProductEnum.ASSISTANT}>
                                            <Assistant />
                                        </ProtectedRoute>
                                    }
                                />
                            </Routes>
                            <AddBasketModal />
                            <CardResult />
                            <ExportExcelModal />
                            {/* <WidgetInfo/> */}
                            <ModalPromoNotification />

                            <Congratulation />
                        </div>

                        <Manual />
                    </QwepProviders>
                </ErrorBoundary>
            )}
        </>
    );
}
