import { useContext, useState } from 'react';
import { FormikController } from '@f_general/formik/FormikController';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, InputAdornment, Link, Typography } from '@mui/material';
import stls from '@f_styles/components/auth/Authorization.module.sass';
import { AuthContext } from '@f_context/Auth_context';
import { TypeLoginData } from '@f_types/index';
import cn from 'classnames';
import { loginUserCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import { useTranslation } from 'react-i18next';
import { Alert } from '@f_general/alert';
import { useNavigate } from 'react-router-dom';
import { IconEye } from '@f_components/icons/IconEye';
import { IconCloseEye } from '@f_components/icons/IconCloseEye';

type TypeLoginProps = {
    setAlignment: (value: string) => void;
};

export function Login({ setAlignment }: TypeLoginProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { login, setOldUserInfo } = useContext(AuthContext);
    const data: TypeLoginData = {
        email: '',
        password: '',
    };
    const [showPass, setShowPass] = useState(false);

    const handleClick = (index: any) => {
        setShowPass((prev) => !prev);
    };

    const sendDataHandler = async (values: TypeLoginData) => {
        const result = await login(values);

        if (!result.data.isOk) {
            if (result.data?.statusNumber === 1) {
                Alert({
                    type: 'error',
                    text: 'Такого пользователя нет',
                    time: 6000,
                });
            }
            if (result.data?.statusNumber === 2) {
                Alert({
                    type: 'error',
                    text: t('auth.alert.wrongLoginOrPassword'),
                    time: 6000,
                });
            }
            if (result.data?.statusNumber === 3) {
                Alert({
                    type: 'warning',
                    text: 'Пользователь активирован \n На вашу почту отправлено письмо, пожалуйста перейдите по ссылке',
                    time: 6000,
                });
            }
            if (result.data?.statusNumber === 4) {
                Alert({
                    type: 'error',
                    text: 'Пользователь найден \n Однако в настоящий момент не перенесен, пожалуйста обратитесь в службу поддержки',
                    time: 6000,
                });
            }
            if (result.data?.statusNumber === 5) {
                Alert({
                    type: 'success',
                    text: 'Пользователь найден \n Необходимо сменить пароль',
                    time: 8000,
                });
                setOldUserInfo(result.data.result);
                return navigate('/password/reset/old');
            }
        } else {
            navigate('/');
        }
    };

    return (
        <Box className={stls.auth}>
            <FormikWrapper onSubmit={sendDataHandler} initialValues={data} validShema={loginUserCustomerSchema}>
                <Typography className={stls.authTypography}>{t('auth.email')}</Typography>
                <FormikController
                    className={stls.inputAuth}
                    control="input"
                    type="email"
                    placeholder={t('auth.placeholderEmail')}
                    name="email"
                    required
                />
                <Typography className={stls.authTypography}>{t('auth.password')}</Typography>
                <FormikController
                    className={stls.inputAuth}
                    control="input"
                    type={showPass ? 'text' : 'password'}
                    placeholder={t('auth.placeholderPassword')}
                    name="password"
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment onClick={handleClick} position="start">
                                {showPass ? <IconEye /> : <IconCloseEye />}
                            </InputAdornment>
                        ),
                    }}
                />
                <div className={stls.forgot}>
                    <Link className={stls.link} onClick={() => setAlignment('forgot')}>
                        {t('auth.forgotYourPassword')}
                    </Link>
                </div>

                <Button className={cn(stls.inputAuth, stls.authTypography)} variant="contained" type="submit">
                    {t('auth.toComeIn')}
                </Button>
            </FormikWrapper>
        </Box>
    );
}
