import { useTranslation } from 'react-i18next';
import stls from '@f_admin/styles/components/userInfo/ApplicationsInfo.module.sass';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { Button, Tab, Tabs } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import { Tariff } from './Tariff';
import { IconStatusOk } from '../icons/IconStatusOk';
import { IconStatusNotOk } from '../icons/IconStatusNotOk';
import { Apps } from '@f_types/index';

export function ApplicationsInfo({ applications, setCurrentApplication, clickModalHandler, usersToWorkspace }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getApp = (app: any) => {
        return usersToWorkspace?.filter((usr: any) =>
            usr.applications.some(
                (a: any) =>
                    a.applicationSysName === app.propertyApplication.sysNameApplication && a.applicationPanelIsActive,
            ),
        );
    };

    return (
        <>
            <Tabs sx={{ background: colors.iota }} value={value} onChange={handleChange} className={stls.tabs}>
                <AppsIcon className={stls.icon} />
                {applications?.map((app: any, idx: number) => {
                    return (
                        <Tab
                            className={stls.tab}
                            sx={{ color: `${colors.omicron} !important` }}
                            key={app.applicationWorkspaceId}
                            label={
                                <p>
                                    <span className={stls.userNumber}>{getApp(app).length} </span>
                                    {app.application.applicationName}
                                </p>
                            }
                            value={idx}
                        />
                    );
                })}
                <Button onClick={() => clickModalHandler('applacations')} className={stls.btn} variant="contained">
                    {t('adminPanel.addApplication')}
                </Button>
            </Tabs>
            <div className={stls.container}>
                {applications?.map((app: any, idx: number) => {
                    if (value === idx) {
                        setCurrentApplication(app);
                    }
                    return (
                        <div
                            style={{ margin: '10px 0' }}
                            key={app.applicationWorkspaceId}
                            role="tabpanel"
                            hidden={value !== idx}
                            id={`tabpanel-${idx}`}
                            aria-labelledby={`tab-${idx}`}>
                            {value === idx && (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '20px',
                                            alignItems: 'start',
                                        }}>
                                        <div>
                                            <div className={stls.text}>
                                                <p style={{ color: colors.omicronTitle }}>
                                                    {t('adminPanel.createDate')}:{' '}
                                                </p>
                                                {new Date(app.createdDate).toLocaleDateString()}
                                            </div>
                                            {app.propertyApplication?.property?.authorizationCode && (
                                                <div className={stls.text}>
                                                    <p style={{ color: colors.omicronTitle }}>
                                                        {t('adminPanel.authorizationCode')}:{' '}
                                                    </p>
                                                    {app.propertyApplication.property.authorizationCode}
                                                </div>
                                            )}
                                            {app.propertyApplication?.authToken && (
                                                <div className={stls.text}>
                                                    <p style={{ color: colors.omicronTitle }}>
                                                        {t('adminPanel.authorizationCode')}:{' '}
                                                    </p>
                                                    {app.propertyApplication?.authToken}
                                                </div>
                                            )}
                                            <div className={stls.text}>
                                                <p style={{ color: colors.omicronTitle }}>{t('adminPanel.tariff')}: </p>
                                                {app.propertyApplication.tariff ? app.propertyApplication.tariff : '-'}
                                            </div>
                                            <div className={stls.text}>
                                                <p style={{ color: colors.omicronTitle }}>{t('adminPanel.limit')}: </p>
                                                {app.propertyApplication.maxLimit
                                                    ? app.propertyApplication.maxLimit
                                                    : '-'}
                                            </div>

                                            {app.propertyApplication?.sysNameApplication === Apps.Data &&
                                                app.propertyApplication?.tariff && (
                                                    <>
                                                        <div className={stls.text}>
                                                            <p style={{ color: colors.omicronTitle }}>{'Аналоги'}: </p>
                                                            {app.propertyApplication?.cross
                                                                ? t('general.yes')
                                                                : t('general.no')}
                                                        </div>
                                                        <div className={stls.text}>
                                                            <p style={{ color: colors.omicronTitle }}>
                                                                {'Контроль цен'}:{' '}
                                                            </p>
                                                            {app.propertyApplication?.priceСontrol
                                                                ? t('general.yes')
                                                                : t('general.no')}
                                                        </div>
                                                        <div className={stls.text}>
                                                            <p style={{ color: colors.omicronTitle }}>
                                                                {'Работа с файлами'}:{' '}
                                                            </p>
                                                            {app.propertyApplication?.workWithFiles
                                                                ? t('general.yes')
                                                                : t('general.no')}
                                                        </div>
                                                        <div className={stls.text}>
                                                            <p style={{ color: colors.omicronTitle }}>
                                                                {'Работа со списками'}:{' '}
                                                            </p>
                                                            {app.propertyApplication?.workWithLists
                                                                ? t('general.yes')
                                                                : t('general.no')}
                                                        </div>
                                                    </>
                                                )}
                                        </div>

                                        <Tariff app={app} usersToApplication={getApp(app)} />
                                    </div>
                                    {app.applicationProps?.length ? (
                                        <p className={stls.title}>{t('adminPanel.property')}</p>
                                    ) : (
                                        <></>
                                    )}

                                    {app.applicationProps?.map((prop: any) => {
                                        let value;
                                        switch (prop.applicationProperty.sysName) {
                                            case 'qwep_token':
                                                value = prop.value.qwepToken;
                                                break;
                                            case 'qwep_exclude_promo':
                                                value = prop.value.value ? t('adminPanel.yes') : t('adminPanel.no');
                                                break;
                                            case 'qwep_mini_table':
                                                value = prop.value.value ? t('adminPanel.yes') : t('adminPanel.no');
                                                break;
                                            case 'qwep_margin':
                                                value = prop.value.value ? t('adminPanel.yes') : t('adminPanel.no');
                                                break;
                                        }

                                        return (
                                            <div
                                                style={{ background: colors.beta }}
                                                className={stls.prop}
                                                key={prop.applicationPropertyValueId}>
                                                <p className={stls.propTitle}>{prop.applicationProperty.description}</p>
                                                {value && <p>{value}</p>}

                                                <div className={stls.active}>
                                                    {prop.isActive ? <IconStatusOk /> : <IconStatusNotOk />}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </>
    );
}
