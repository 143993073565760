import { createContext, useContext, useEffect, useState } from 'react';
import { Range, RangeKeyDict } from 'react-date-range';
import { useLocalStorage } from '@f_hooks/index';
import { TypeCurrentReport, useCollector } from '@f_ucs/context/collector_context';
import { isWithinInterval, parseISO } from 'date-fns';
import { useReport } from '@f_ucs/context/report_context';

type TypeDatePickerContext = {
    showDatePicker: boolean;
    dateRange: Range[];
    currentTime: string;
    //methods
    updateFilteredReport: (newValue: string, range?: Range) => void;
    setShowDatePicker: (value: boolean) => void;
    setDateRange: (value: Range[]) => void;
    handleDateCustomRangeChange: (ranges: any) => void;
    setCurrentTime: (value: string) => void;
};

const DatePickerContext = createContext<any>({});

function DatePickerProvider(props: any) {
    const { currentReports, setTimeCutReports } = useReport();

    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [dateRange, setDateRange] = useLocalStorage('ucs_currentRange', [
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const [currentTime, setCurrentTime] = useLocalStorage('currentTime', '3');

    const handleDateCustomRangeChange = (ranges: any) => {
        setDateRange([ranges.selection]);
        updateFilteredReport('custom', ranges.selection);
    };

    useEffect(() => {
        updateFilteredReport(currentTime, dateRange[0]);
    }, [currentReports]);

    const updateFilteredReport = (newValue: string, range: Range) => {
        let filteredReport: any = [];
        if (newValue === '1') {
            filteredReport = currentReports.slice(0, 1);
        } else if (newValue === '2') {
            filteredReport = currentReports.slice(0, 7);
        } else if (newValue === '3') {
            filteredReport = currentReports.slice(0, 30);
        } else if (newValue === '4') {
            filteredReport = currentReports;
        } else if (newValue === 'custom' && range) {
            const { startDate, endDate } = range as Range;
            if (startDate && endDate) {
                const startISODate = parseISO(new Date(startDate).toISOString());
                const endISODate = parseISO(new Date(endDate).toISOString());
                filteredReport = currentReports.filter((item: TypeCurrentReport) => {
                    const itemDate = parseISO(item.timeStart);
                    return isWithinInterval(itemDate, { start: startISODate, end: endISODate });
                });
            } else {
                console.error('Start date or end date is missing');
            }
        }
        setTimeCutReports(filteredReport);
    };

    return (
        <DatePickerContext.Provider
            value={
                {
                    showDatePicker,
                    dateRange,
                    currentTime,
                    //methods
                    updateFilteredReport,
                    setShowDatePicker,
                    setDateRange,
                    handleDateCustomRangeChange,
                    setCurrentTime,
                } as TypeDatePickerContext
            }
            {...props}
        />
    );
}

const useDatePicker = (): TypeDatePickerContext => {
    const context = useContext(DatePickerContext);
    if (!context) {
        console.error('DatePickerContext');
    }
    return context as TypeDatePickerContext;
};

export { DatePickerProvider, useDatePicker };
