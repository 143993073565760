import { useContext, useEffect, useState } from 'react';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';
import { Tariff } from '@f_qwep/types';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { useLocalStorage } from '@f_hooks/index';
import { bool } from 'yup';

interface IGetIsAccessArgs {
    currentTariff: Tariff | null;
    accessTariffs: Tariff[];
}

interface IUseAccessReturn {
    isAccess: boolean;
    currentTariff: Tariff | null;
    isLoadedAndIsAccess: boolean;
}

export const useAccess = (product: QwepProductEnum): IUseAccessReturn => {
    const { tariff } = useContext(QwepContext);
    const [localStorageTariff, setLocalStorageTariff] = useLocalStorage('tariff', null);
    const [currentTariff, setCurrentTariff] = useState<Tariff | null>(null);
    const [isLoadedAndIsAccess, setIsLoadedAndIsAccess] = useState<boolean>(false);

    const getIsAccess = ({ currentTariff, accessTariffs }: IGetIsAccessArgs) => {
        return currentTariff ? accessTariffs.some((t: string) => t === currentTariff) : false;
    };

    // Установка значения из local storage
    useEffect(() => {
        if (localStorageTariff) {
            setCurrentTariff(localStorageTariff);
        }
    }, []);

    // Установка значения тарифа из загрузки и работа с local storage
    useEffect(() => {
        if (!localStorageTariff && tariff) {
            setCurrentTariff(tariff);
            setLocalStorageTariff(tariff);
        }

        if (localStorageTariff && tariff && localStorageTariff !== tariff) {
            setCurrentTariff(tariff);
            setLocalStorageTariff(tariff);
        }
    }, [tariff]);

    const withoutLiteTariff = [Tariff.PRO, Tariff.PROPlus, Tariff.Business, Tariff.UNLIMIT];

    const accessRules = {
        [QwepProductEnum.ASSISTANT]: [Tariff.Business, Tariff.PROPlus, Tariff.PRO],
        [QwepProductEnum.BASKET]: withoutLiteTariff,
        [QwepProductEnum.ORDERS]: withoutLiteTariff,
        [QwepProductEnum.BATCH_PRICE]: withoutLiteTariff,
        [QwepProductEnum.PRICE]: [Tariff.PROPlus, Tariff.Business, Tariff.UNLIMIT],
        [QwepProductEnum.SHOW_PROMOTION_ASSISTANT]: [Tariff.PROPlus, Tariff.PRO],
        [QwepProductEnum.WITHOUT_LITE]: withoutLiteTariff,
    };

    const isAccess = getIsAccess({
        currentTariff,
        accessTariffs: accessRules[product],
    });

    // Установка окончательного значения доступности после загрузки
    useEffect(() => {
        if (currentTariff) {
            setIsLoadedAndIsAccess(currentTariff && isAccess);
        }
    }, [currentTariff, isAccess]);

    return {
        isAccess,
        currentTariff,
        isLoadedAndIsAccess,
    };
};
