import { SearchResultsProvider } from '../context/SearchResult_context';
import { GroupProvider } from '@f_qwep/context/Group_context';
import { QwepProvider } from './Qwep_context';
import { AccountsProvider } from './Accounts_context';
import { SearchProvider } from './Search_context';
import { BasketProvider } from './Basket_context';
import { OrdersProvider } from './Orders_context';
import { SettingsProvider } from './Settings_context';
import { BatchPricerProvider } from './BatchPricer_context';
import { ErrorsProvider } from './Errors_context';
import { PriceProvider } from './Price_context';
import { FilterProvider } from './Filter_context';
import { ManualProvider } from './Manual_context';
import { PreviewTableProvider } from '@f_qwep/general/previewTable/context/PreviewTable.context';

export function QwepProviders({ children }: any) {
    return (
        <QwepProvider>
            <ErrorsProvider>
                <GroupProvider>
                    <SettingsProvider>
                        <AccountsProvider>
                            <PreviewTableProvider>
                                <PriceProvider>
                                    <SearchProvider>
                                        <OrdersProvider>
                                            <BasketProvider>
                                                <SearchResultsProvider>
                                                    <BatchPricerProvider>
                                                        <FilterProvider>
                                                            <ManualProvider>{children}</ManualProvider>
                                                        </FilterProvider>
                                                    </BatchPricerProvider>
                                                </SearchResultsProvider>
                                            </BasketProvider>
                                        </OrdersProvider>
                                    </SearchProvider>
                                </PriceProvider>
                            </PreviewTableProvider>
                        </AccountsProvider>
                    </SettingsProvider>
                </GroupProvider>
            </ErrorsProvider>
        </QwepProvider>
    );
}
