import { Drawer } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { LayoutMenuPage } from '@f_general/layout/LayoutMenuPage';
import { UserContext } from '@f_context/User_context';
import { Alert } from '@f_general/alert';
import { SupportContext } from '@f_context/Support_context';
import { AllMessages } from './AllMessages';
import { GeneralContext } from '@f_context/General_context';
import { useWindowSize } from '@f_hooks/index';
import { DrawerChat } from './DrawerChat';

type TypeData = {
    phone: string;
    name: string;
    description: string;
    title: string;
    product: string;
};

export function SupportPage() {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    const { colors, CustomSelect } = useContext(ThemeContext);
    const { userInfo } = useContext(UserContext);
    const {
        sendSupportChat,
        currentTask,
        currentChat,
        handleFileChange,
        supportTasks,
        files,
        setFiles,
        getLinkToFile,
        getSupportProperty,
        setCountNewMessage,
    } = useContext(SupportContext);
    const { updateUserProperty } = useContext(GeneralContext);

    const [mobile, setMobile] = useState(false);

    const [filesTask, setFilesTask] = useState<any>([]);
    const [filesTaskLoading, setFilesTaskLoading] = useState(false);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        getSupportProperty();
    }, []);

    useEffect(() => {
        if (currentTask?.id) {
            setOpen(true);
            setCountNewMessage(0);
        } else {
            setOpen(false);
        }
    }, [currentTask]);

    useEffect(() => {
        setMobile(width < 600);
    }, [width]);

    const drawerStyle = { width: mobile ? '100%' : '385px' };

    const products = [
        { value: '0823810fd01e', key: '1C 1.0', name: '1C 1.0' },
        { value: 'd6b284f81dd0', key: '1C 2.0', name: '1C 2.0' },
        { value: 'ce81540c8f76', key: 'WEB', name: 'WEB' },
    ];

    const [currentType, setCurrentType] = useState<any>('');
    const [data, setData] = useState<TypeData>({
        phone: userInfo.phoneNumber ?? '',
        name: userInfo.firstName ?? '',
        description: '',
        title: '',
        product: '',
    });

    const handleChange = (type: any) => {
        setCurrentType(type);
    };

    const sendDataHandler = async (values: TypeData) => {
        const result = await sendSupportChat({ ...values, filesTask, type: currentType });
        if (result.result) {
            updateUserProperty({ sysName: 'support_id', value: [...supportTasks, result.result] });
            setData({
                phone: userInfo.phoneNumber ?? '',
                name: userInfo.firstName ?? '',
                description: '',
                title: '',
                product: '',
            });
            Alert({
                type: 'success',
                text: 'Сообщение успешно отправлено',
                time: 6000,
            });
        } else {
            Alert({
                type: 'error',
                text: 'Сервис временно недоступен',
                time: 6000,
            });
        }
    };

    const handleAddFile = async (e: ChangeEvent<HTMLInputElement>) => {
        setFilesTaskLoading(true);
        if (e.target.files) {
            const result = await getLinkToFile(e.target.files[0]);
            if (result.isOk) {
                let reader = new FileReader();
                let title = e.target.files[0].name;
                let preview = e.target.files[0].type.includes('image');
                reader.onloadend = () => {
                    setFilesTask((prev: any) => [
                        {
                            imagePreviewUrl: preview ? reader.result : '/1200x600wa.png',
                            url: result.result,
                            title: title,
                        },
                        ...prev,
                    ]);
                };
                reader.readAsDataURL(e.target.files[0]);
            }
        }
        setFilesTaskLoading(false);
    };

    const deleteFileHandler = (url: any, index: number) => {
        const filesFilrer = filesTask.filter((i: any, idx: number) => i.imagePreviewUrl !== url && idx !== index);
        setFilesTask(filesFilrer);
    };

    return (
        <LayoutMenuPage title={t('support.support')}>
            {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Card className={stls.support}>
                    <CardContent className={stls.supportContent}>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '12px' }}>
                            <IconMessSupport />
                            <div style={{ margin: '0 0 0 20px' }}>
                                <Typography variant='h6' style={{ marginBottom: '8px' }}>
                                    {t('support.support')}
                                </Typography>
                                <Typography variant="body2">
                                    {t('support.question')}
                                </Typography>
                            </div>
                        </div>
                        <FormikWrapper
                            onSubmit={sendDataHandler}
                            initialValues={data}
                            validShema={SendSupportModalCustomerSchema}>
                            <Typography>Продукт</Typography>
                            <FormikController
                                control="select"
                                options={products}
                                name="product"
                                type="text"
                            />
                            <Typography>Тип обращения</Typography>
                            <Select
                                name="type"
                                input={<CustomSelect />}
                                className={stls.select}
                                onChange={(e: any) => handleChange(e.target.value)}
                                MenuProps={{
                                    sx: {
                                        '&& .MuiMenuItem-root': {
                                            fontSize: 14,
                                            fontFamily: 'Inter',
                                            color: colors.omicron,
                                            '&:hover': {
                                                background: colors.beta,
                                            },
                                        },
                                    },
                                }}
                            >
                                {types.map((option: any) => {
                                    return (
                                        <MenuItem
                                            id={option.value}
                                            key={option.key}
                                            value={option.value}
                                        >
                                            {option.key}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            <Typography>Тема</Typography>
                            <FormikController
                                disabled={!currentType.length}
                                control="select"
                                options={title[currentType] ?? []}
                                name="title"
                            />

                            <div style={{ height: '100%' }}>
                                <Typography>Описание</Typography>
                                <FormikController
                                    rows={4}
                                    fullWidth
                                    control="input"
                                    type="text"
                                    placeholder={t('support.text')}
                                    name="description"
                                    multiline
                                />
                            </div>
                            <div>
                                <Typography>{t('common.header.modal.phoneNumber')}</Typography>
                                <FormikController
                                    className={stls.modalInput}
                                    type='tel'
                                    control='input'
                                    name='phone'
                                    placeholder='+7 (999) 000-00-00'
                                />
                            </div>
                            <div >
                                <Typography>{t('common.header.modal.name')}</Typography>
                                <FormikController
                                    control='input'
                                    className={stls.modalInput}
                                    type='text'
                                    name='name'
                                    placeholder={t('common.header.modal.enterName')}
                                />
                            </div>

                            <div className={stls.sendBlock}>


                                <Typography className={stls.attach}>
                                    {
                                        filesTask.length ? <p style={{ background: colors.gamma }} className={stls.coutnFile}>
                                            <span className={stls.count}> {filesTask.length}</span>
                                        </p>
                                            :
                                            <></>
                                    }
                                    <label className={stls.inputAddFile} htmlFor="file">
                                        <IconAttach />
                                        <p id='inputAddFile' style={{ color: colors.alpha }} className={stls.text}>
                                            {t('qwep.price.modal.updateOrAddPriceModal.labelFile')}
                                        </p>{' '}
                                        <input id="file" type="file"
                                            onChange={handleAddFile}
                                        />
                                        {
                                            filesTaskLoading
                                            && <CircularProgress size={20} />
                                        }
                                    </label>
                                </Typography>



                                <div className={stls.files}>
                                    {filesTask.map((item: any, idx: number) => (
                                        <div key={item.imagePreviewUrl} className={stls.filesPreview}>
                                            <img
                                                className={stls.img}
                                                src={`${item.imagePreviewUrl}`}
                                                alt={item.title}
                                                loading="lazy"
                                            />
                                            <ImageListItemBar
                                                subtitle={item.title}
                                                sx={{ borderRadius: '12px', width: '200px' }}
                                                actionIcon={
                                                    <IconButton
                                                        sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                                        aria-label={`info about ${item.title}`}
                                                    >
                                                    </IconButton>
                                                }
                                            />
                                            <IconButton className={stls.deleteFile}
                                                onClick={() => deleteFileHandler(item, idx)}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </div>
                                    ))}
                                </div>



                                <Button type='submit' size="large" variant="contained">{t('support.send')}</Button>
                            </div>
                        </FormikWrapper>
                    </CardContent>
                </Card>
            </div> */}
            <AllMessages />
            <Drawer
                sx={{
                    width: drawerStyle.width,
                    '& .MuiDrawer-paper': {
                        width: drawerStyle.width,
                    },
                }}
                variant="persistent"
                anchor={mobile ? 'bottom' : 'right'}
                open={open}>
                <DrawerChat
                    currentTask={currentTask}
                    currentChat={currentChat}
                    setFiles={setFiles}
                    files={files}
                    handleFileChange={handleFileChange}
                />
            </Drawer>
        </LayoutMenuPage>
    );
}
