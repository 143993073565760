import { EAssistantItemStatus, IAssistantPrice } from '@f_qwep/components/assistant/types/assistant-profile.type';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/assistant/view/table/AssistantColumn.config';
import { useAssistantStatus } from '@f_qwep/components/assistant/view/table/useAssistantStatus';
import styles from '@f_qwep/styles/component/purchasingAssistant/TablePurchasingAssistant.module.sass';
import Tooltip from '@mui/material/Tooltip';

interface IConfirmedPriceProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    price: IAssistantPrice;
    state: EAssistantItemStatus;
    promo: boolean;
}

const priceFormatter = (prices, state, promo) => {
    if (state === EAssistantItemStatus.NO_ACCESS && !promo) {
        return `~ ${prices.value[0]} ₽`;
    }

    if (prices.value.length > 1) return `${prices.value[0]} ~ ${prices.value[1]} ₽`;

    return `${prices.value[0]} ₽`;
};

export function AssistantConfirmedPriceColumn({ price, state, promo }: IConfirmedPriceProps) {
    const { borderColor } = useAssistantStatus(state, promo);

    return !promo && state === EAssistantItemStatus.NO_ACCESS ? (
        <>
            <Tooltip title={'Цена не подтверждена'}>
                <div className={styles.cellPrice}>
                    <p style={{ color: borderColor, whiteSpace: 'nowrap' }} className={styles.price}>
                        {price.value[0] === 0 ? null : priceFormatter(price, state, promo)}
                    </p>
                </div>
            </Tooltip>
        </>
    ) : (
        <div className={styles.cellPrice}>
            <p style={{ color: borderColor, whiteSpace: 'nowrap' }} className={styles.price}>
                {price.value[0] === 0 ? null : priceFormatter(price, state, promo)}
            </p>
        </div>
    );
}
