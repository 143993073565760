import { Button, Chip, Paper, Popover, Popper } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/general/WorkPeriod.module.sass';
import GradeIcon from '@mui/icons-material/Grade';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from '@f_hooks/index';

type WprkPeriodProps = {
    loading: boolean;
    workPeriod: string;
    component?: any;
};

export const WorkPeriod = ({ loading, workPeriod, component }: WprkPeriodProps) => {
    const navigate = useNavigate();
    const { colors } = useContext(ThemeContext);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [mobile, setMobile] = useState(false);
    const { width } = useWindowSize();
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(0);
    const [day, setDay] = useState(0);

    useEffect(() => {
        if (workPeriod) {
            let period: any = new Date(workPeriod);
            let now: any = new Date();
            let differenceDay: number = Math.round((period - now) / 3600 / 24 / 1000);
            if (differenceDay >= 365) {
                setYear(Math.floor(differenceDay / 365));
                const countyear = Math.floor(differenceDay / 365);
                differenceDay -= countyear * 365;
            }
            if (differenceDay >= 30) {
                setMonth(Math.floor(differenceDay / 30));
                const countmonth = Math.floor(differenceDay / 30);
                differenceDay -= countmonth * 30;
            }
            if (differenceDay > 0) {
                setDay(differenceDay);
            }
        }
    }, [workPeriod]);

    useEffect(() => {
        setMobile(width < 480);
    }, [width]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const periodColor = loading ? colors.alpha : !year && !month && day <= 3 ? colors.lambda : colors.gamma;

    return (
        <>
            <button id="workPeriod" className={stls.workPeriod} onClick={handleClick}>
                <Chip
                    className={stls.chip}
                    label={
                        loading ? (
                            '...'
                        ) : mobile ? (
                            <p className={stls.lable}>
                                {year ? <>{`${year} ${t('general.year')}`}&nbsp;</> : <></>}
                                {month ? <>{`${month} ${t('general.month')}`}&nbsp;</> : <></>}
                            </p>
                        ) : (
                            <p className={stls.lable}>
                                {year ? <>{`${year} ${t('general.year')}`}&nbsp;</> : <></>}
                                {month ? <>{`${month} ${t('general.month')}`}&nbsp;</> : <></>}
                                {day ? <>{`${day} ${t('general.day')}`}</> : <>{`0 ${t('general.day')}`}</>}
                            </p>
                        )
                    }
                    variant="outlined"
                    size="small"
                    sx={{ color: periodColor, borderColor: periodColor }}
                    icon={<GradeIcon style={{ color: colors.eta, width: '12px', height: '12px' }} />}
                />
            </button>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{
                    zIndex: 1000,
                    '& .MuiPopover-paper': {
                        borderRadius: '12px',
                    },
                }}>
                <div className={stls.popper}>
                    <p style={{ color: colors.omicron }}>{t('general.workPeriod')}</p>
                    <p style={{ color: colors.alpha }} className={stls.date}>
                        {year ? <>{`${year} ${t('general.year')}`}&nbsp;</> : <></>}
                        {month ? <>{`${month} ${t('general.month')}`}&nbsp;</> : <></>}
                        {day ? <>{`${day} ${t('general.day')}`}</> : <>{`0 ${t('general.day')}`}</>}
                    </p>
                    {component}
                    <Button
                        id="chooseTariff"
                        onClick={() => {
                            navigate('subscription');
                            setAnchorEl(null);
                        }}
                        fullWidth
                        variant="contained"
                        size="small">
                        {t('general.rate')}
                    </Button>
                </div>
            </Popover>
        </>
    );
};
