import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { assistantStore } from '../../../store/assistant.store';
import { EAssistantPositionOptions } from '../../../types/assistant-position-options.enum';

export const AssistantModalActions: FC<any> = observer(({ colors }) => {
    const { t } = useTranslation();
    const {
        isShowPreviewTable,
        isFileLoading,
        setIsOpen,
        currentPositionLoadingOption,
        setIsShowPreviewTable,
        isProfileLoading,
    } = assistantStore.assistantModalStore;
    const isOptionFileInput = currentPositionLoadingOption === EAssistantPositionOptions.FILE_INPUT;

    return (
        <div className={styles.actions}>
            <Button
                className={styles.modalButton}
                onClick={() => {
                    isShowPreviewTable ? setIsShowPreviewTable(false) : setIsOpen(false);
                }}
                sx={{
                    background: colors.delta,
                    color: colors.phi,
                    '&:hover': { color: colors.alpha },
                }}>
                {isOptionFileInput && isShowPreviewTable ? t('general.back') : t('modal.cancel')}{' '}
            </Button>
            <Button
                className={styles.modalButton}
                variant={'contained'}
                type={'submit'}
                disabled={isShowPreviewTable ? isProfileLoading : isFileLoading}>
                {isOptionFileInput && !isShowPreviewTable ? t('general.next') : t('modal.success')}{' '}
            </Button>
        </div>
    );
});
