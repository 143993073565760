import { Autocomplete, Button, ButtonGroup, ListItemText, TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import stls from '@f_admin/styles/components/userSearch/UserSearch.module.sass';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { UserAccountContext } from '@f_admin/context/UserAccount_context';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { AdminContext } from '@f_admin/context/Admin_context';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useWindowSize } from '@f_hooks/index';
import { IconModalGroup } from '@f_components/icons/IconModalGroup';
import { Alert } from '@f_general/alert';
import GeneralModal from '@f_general/GeneralModal';
import { IconEditUser } from '../icons/IconEditUser';
import { IconMail } from '../icons/IconMail';
import { IconDeleteUser } from '../icons/IconDeleteUser';

type TypeDataModal =
    | {
          icon: any;
          title: string;
          props: Array<any>;
          shema: any;
          type: string;
          data: object;
      }
    | any;

export function BotPanel() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [dataModal, setDataModal] = useState<TypeDataModal>({});
    const { userInfo, deleteUser, updateUser, sendMessageUser } = useContext(UserAccountContext);

    const closeHandler = () => {
        setDataModal({});
        setOpen(false);
    };

    const clickModalHandler = (type: string) => {
        switch (type) {
            case 'deleteUser':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `${t('adminPanel.deleteUser')} ${userInfo.firstName} ${userInfo.lastName}`,
                    props: [],
                    shema: null,
                    type: 'deleteUser',
                    data: {
                        type: 'deleteUser',
                        userId: userInfo.userId,
                    },
                } as TypeDataModal);
                setOpen(true);
                break;
            // case 'BanUser':
            // setDataModal({
            //     icon: <IconModalGroup />,
            //     title: `Ban user ${userInfo.firstName} ${userInfo.lastName}`,
            //     props: [],
            //     shema: null,
            //     type: 'BanUser',
            //     data: {
            //         type: 'BanUser',
            //         userId: userInfo.userId,
            //     },
            // } as TypeDataModal);
            // setOpen(true);
            // break;

            case 'sendMessageUser':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `${t('adminPanel.sendMessage')} ${userInfo.firstName} ${userInfo.lastName}`,
                    props: [
                        {
                            control: 'select',
                            name: 'message',
                            title: t('modal.userTableModal.userRole'),
                            defaultValue: '',
                            options: [
                                {
                                    value: 1,
                                    key: 'Подтверждение регистрации',
                                },
                                {
                                    value: 2,
                                    key: 'Востановление пароля',
                                },
                                {
                                    value: 3,
                                    key: 'Добавление нового пользователя в компанию',
                                },
                            ],
                        },
                    ],
                    shema: null,
                    type: 'sendMessageUser',
                    data: {
                        type: 'sendMessageUser',
                        message: '',
                    },
                } as TypeDataModal);
                setOpen(true);
                break;

            case 'editUser':
                setDataModal({
                    icon: <IconModalGroup />,
                    title: `${t('modal.editUser')} ${userInfo.firstName} ${userInfo.lastName}`,
                    props: [
                        {
                            title: t('modal.addUser.firstName'),
                            control: 'input',
                            name: 'firstName',
                            type: 'text',
                            defaultValue: userInfo.firstName,
                            placeholder: t('modal.addUser.placeholderFirstName'),
                        },
                        {
                            title: t('modal.addUser.lastName'),
                            control: 'input',
                            name: 'lastName',
                            type: 'text',
                            defaultValue: userInfo.lastName,
                            placeholder: t('modal.addUser.placeholderLastName'),
                        },
                        {
                            title: 'E-mail*',
                            control: 'input',
                            name: 'newEmail',
                            type: 'text',
                            defaultValue: userInfo.email,
                            placeholder: 'E-mail',
                        },
                        {
                            title: 'Password*',
                            control: 'input',
                            name: 'password',
                            type: 'text',
                            placeholder: 'password',
                        },
                        {
                            title: t('modal.addUser.phoneNumber'),
                            control: 'input',
                            name: 'phoneNumber',
                            defaultValue: userInfo.phoneNumber,
                            type: 'text',
                            placeholder: '+7 (999) 000-00-00',
                        },
                        {
                            title: t('modal.addUser.position'),
                            control: 'input',
                            name: 'position',
                            defaultValue: userInfo.position,
                            type: 'text',
                            placeholder: t('modal.addUser.placeholderPosition'),
                        },
                        {
                            control: 'checkbox',
                            title: '',
                            name: 'isActive',
                            type: 'checkbox',
                            options: [
                                {
                                    key: 'Active',
                                    value: userInfo.isActive,
                                },
                            ],
                        },
                    ],
                    shema: null,
                    type: 'editUser',
                    data: {
                        type: 'editUser',
                        firstName: userInfo.firstName,
                        lastName: userInfo.lastName,
                        newEmail: userInfo.email,
                        password: userInfo.password,
                        phoneNumber: userInfo.phoneNumber,
                        position: userInfo.position,
                        isActive: userInfo.isActive,
                    },
                } as TypeDataModal);
                setOpen(true);
                break;
        }
    };

    const sendDataModalHandler = async (values: any) => {
        switch (values.type) {
            case 'sendMessageUser':
                const resultMessageUser = await sendMessageUser({
                    userId: userInfo.userId,
                    email: userInfo.email,
                    message: values.message,
                });
                if (resultMessageUser.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeHandler();
                } else {
                    Alert({
                        type: 'error',
                        text: t('general.error'),
                    });
                }
                break;

            case 'deleteUser':
                const resultDeleteUser = await deleteUser(values.userId);
                if (resultDeleteUser.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeHandler();
                } else {
                    if (resultDeleteUser.statusNumber === 1) {
                        Alert({
                            type: 'warning',
                            text: 'Нельзя удалять своего пользователя',
                        });
                    } else {
                        Alert({
                            type: 'error',
                            text: t('general.error'),
                        });
                    }
                }
                break;
            case 'editUser':
                const resultUpdateUser = await updateUser({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    newEmail: values.newEmail,
                    email: userInfo.email,
                    password: values.password,
                    phoneNumber: values.phoneNumber,
                    position: values.position,
                    isActive: values.isActive,
                    userId: userInfo.userId,
                });
                if (resultUpdateUser.isOk) {
                    Alert({
                        type: 'success',
                        text: t('general.success'),
                    });
                    closeHandler();
                } else {
                    if (resultUpdateUser.statusNumber === 1) {
                        Alert({
                            type: 'warning',
                            text: 'Email уже используется',
                        });
                    } else {
                        Alert({
                            type: 'error',
                            text: t('general.error'),
                        });
                    }
                }
                break;
        }
    };

    return (
        <>
            <ButtonGroup
                disabled={location.pathname !== '/app-admin/user-info'}
                variant="text"
                className={stls.actions}>
                <Button className={stls.btn} onClick={() => clickModalHandler('editUser')}>
                    <Tooltip title={t('adminPanel.editUser')}>
                        <IconEditUser />
                    </Tooltip>
                </Button>
                <Button className={stls.btn} onClick={() => clickModalHandler('sendMessageUser')}>
                    <Tooltip title={t('adminPanel.sendMessage')}>
                        <IconMail />
                    </Tooltip>
                </Button>
                {/* <Button className={stls.btn} onClick={() => clickModalHandler('BanUser')}>
          <Tooltip title={'Ban user'}>
            <AppBlockingIcon
              style={{ color: colors.alpha, fontSize: '30px' }}
            />
          </Tooltip>
        </Button> */}
                <Button className={stls.btn} onClick={() => clickModalHandler('deleteUser')}>
                    <Tooltip title={t('adminPanel.deleteUser')}>
                        <IconDeleteUser />
                    </Tooltip>
                </Button>
            </ButtonGroup>
            <GeneralModal
                openModal={open}
                closeModal={closeHandler}
                sendDataModalHandler={sendDataModalHandler}
                dataModal={dataModal}
            />
        </>
    );
}
