import { EAssistantItemStatus } from '@f_qwep/components/assistant/types/assistant-profile.type';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/assistant/view/table/AssistantColumn.config';
import { useAssistantStatus } from '@f_qwep/components/assistant/view/table/useAssistantStatus';
import cn from 'classnames';

interface IAssistantArticleColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    state: EAssistantItemStatus;
    article: string;
    promo: boolean;
}

export const AssistantArticleColumn = (props: IAssistantArticleColumnProps) => {
    const { state, styles, article, promo } = props;

    const { textColor } = useAssistantStatus(state, promo);

    return (
        <p style={{ color: textColor }} className={cn(styles.cellRow, styles.fwBold)}>
            {article}
        </p>
    );
};
