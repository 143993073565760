import { Divider, FormGroup, List, ListItem, ListItemAvatar, ListItemText, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/settings/Settings.module.sass';
import { useWindowSize } from '@f_hooks/index';
import { MobileButtonSettings } from '../MobileButtonSettings';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Property } from '@f_qwep/types';
import { Alert } from '@f_general/alert';
import React from 'react';

export function AdditionalSettings() {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const {
        miniTableRes,
        setMiniTableRes,
        updateProperty,
        workspaceApplication,
        filter1C,
        setFilter1C,
        priceCrossesCheck,
    } = useContext(QwepContext);

    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 1200);
    }, [width]);

    const handleChange = async () => {
        setMiniTableRes(!miniTableRes);
        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.MiniTable,
            value: { value: !miniTableRes },
        });
        if (result?.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };
    const handleFilter = async () => {
        setFilter1C(!filter1C);
        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.Filter1C,
            value: { value: !filter1C },
        });
        if (result?.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    const handlePriceCrosses = async (check: boolean) => {
        const result = await updateProperty({
            applicationWorkspaceId: workspaceApplication,
            propertyName: Property.CrossesPrice,
            value: { value: check },
        });
        if (result?.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };
    return (
        <div className={stls.page}>
            <div className={stls.appbarGroup}>
                <div style={{ display: 'flex', justifyContent: 'end' }}>{mobile && <MobileButtonSettings />}</div>
            </div>
            <List
                sx={{
                    width: '100%',
                    maxWidth: 600,
                }}>
                <ListItem>
                    <ListItemAvatar>
                        <FormGroup>
                            <Switch id="styleTables" onChange={handleChange} checked={miniTableRes} />
                        </FormGroup>
                    </ListItemAvatar>
                    <ListItemText
                        primary={'Строгий стиль таблицы результатов'}
                        secondary={
                            <React.Fragment>
                                <Typography sx={{ color: colors.phi }} component="span" variant="body2">
                                    Выберите это свойство если хотите применить строгий стиль таблицы результатов.
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <FormGroup>
                            <Switch id="styleTables" onChange={handleFilter} checked={filter1C} />
                        </FormGroup>
                    </ListItemAvatar>
                    <ListItemText
                        primary={'Вид результатов'}
                        secondary={
                            <React.Fragment>
                                <Typography sx={{ color: colors.phi }} component="span" variant="body2">
                                    Использовать для отображения в результатах поиска сначала искомый артикул, потом
                                    аналог.
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
                <Divider variant="inset" component="li" />
                <ListItem>
                    <ListItemAvatar>
                        <FormGroup>
                            <Switch
                                id="styleTables"
                                onChange={() => handlePriceCrosses(!priceCrossesCheck)}
                                checked={priceCrossesCheck}
                            />
                        </FormGroup>
                    </ListItemAvatar>
                    <ListItemText
                        primary={'Поиск по прайсам'}
                        secondary={
                            <React.Fragment>
                                <Typography sx={{ color: colors.phi }} component="span" variant="body2">
                                    Использовать предложения поставщиков для отображения аналогов
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </List>
        </div>
    );
}
