import { SettingsContext } from '@f_qwep/context/Settings_context';
import React, { useContext, useEffect, useState } from 'react';
import GeneralJoyride from '../GeneralJoyride';
import { ManualContext } from '@f_qwep/context/Manual_context';
import { IconMaskot } from '@f_components/icons/IconMascot';
import { useWindowSize } from '@f_hooks/index';

export function SettingsManual() {
    const { setChooseManual } = useContext(ManualContext);
    const { open } = useContext(SettingsContext);

    const [run, setRun] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [steps, setSteps] = useState<any>([
        {
            target: '#menuButtonSettings',
            content: 'Откройте меню настроек',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#accounts',
            title: 'Мои аккаунты',
            content: (
                <div>
                    {' '}
                    <IconMaskot />{' '}
                    <p>
                        Содержит список поставщиков, которых вы подключили для проценки, тут же есть возможность
                        подключать или отключать Промо-поставщиков.
                    </p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
        {
            target: '#suppliers',
            title: 'Поставщики',
            content: (
                <div>
                    {' '}
                    <IconMaskot /> <p>Здесь представлены все поставщики доступные для проценки.</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
        {
            target: '#group_accounts',
            title: 'Группы поставщиков',
            content: (
                <div>
                    {' '}
                    <IconMaskot />{' '}
                    <p>
                        Позволяет создавать/редактировать отдельные группы поставщиков для конкретных задач (например:
                        Группа для проценки по кузовным деталям).
                    </p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
        {
            target: '#margin',
            title: 'Наценка',
            content: (
                <div>
                    {' '}
                    <IconMaskot />{' '}
                    <p>
                        Позволяет назначать/управлять требуемую наценку в зависимости от стоимости товара при проценке.
                    </p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
        {
            target: '#addSettings',
            title: 'Доп. настройки',
            content: (
                <div>
                    {' '}
                    <IconMaskot /> <p>Спрятан прочий полезный функционал.</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
        {
            target: '#transfer1c',
            title: 'Переезд с 1с',
            content: (
                <div>
                    {' '}
                    <IconMaskot />{' '}
                    <p>
                        Позволяет загрузить поставщиков из 1с в веб версию для помощи клиентам при смене продукта и
                        будущих сервисов.
                    </p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
    ]);

    const { width } = useWindowSize();

    useEffect(() => {
        setRun(true);
    }, []);

    useEffect(() => {
        if (width) {
            if (currentStep == 0) {
                const target = document.querySelector('#menuButtonSettings');
                if (!target && width > 1200) {
                    setCurrentStep((prevStep) => prevStep + 1);
                }
            }
        }
    }, [currentStep, width]);

    useEffect(() => {
        if (open) {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    }, [open]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setChooseManual();

            setRun(false);
        }
    };

    return (
        <div>
            <GeneralJoyride steps={steps} run={run} callback={handleJoyrideCallback} stepIndex={currentStep} />
        </div>
    );
}
