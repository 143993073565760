import { ThemeContext } from '@f_context/Theme_context';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { useWindowSize } from '@f_hooks/index';
import { assistantStore } from '@f_qwep/components/assistant/store/assistant.store';
import { getAssistantTableConfig } from '@f_qwep/components/assistant/view/table/AssistantColumn.config';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import styles from '@f_qwep/styles/component/purchasingAssistant/TablePurchasingAssistant.module.sass';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TableAssistant = observer(() => {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { width, height } = useWindowSize();
    const [mobile, setMobile] = useState(false);
    const [tableHeight, setTableHeight] = useState<number | null>(null);
    const { assistantFilteredItems, assistantExcelStore: assistantExelStore } = assistantStore;
    const { setOpenExportModal, setExportData, setTypeExport } = useContext(QwepContext);

    const exportExcelHandler = () => {
        setTypeExport('assistant');
        setExportData({ all: assistantExelStore.allItems, filter: assistantExelStore.filteredItems });
        setOpenExportModal(true);
    };

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    useEffect(() => {
        if (height !== undefined) {
            setTableHeight(height - 300);
        }
    }, [height]);

    const columns = useMemo(
        () => getAssistantTableConfig({ t, styles, colors }),
        [t, styles, colors, assistantFilteredItems, width],
    );

    const tableStyles = {
        head: {
            container: {
                height: '50px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: mobile ? 'center' : null,
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: mobile ? '190px' : '28px',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <div>
            {tableHeight !== null && (
                <VirtualTable
                    data={assistantFilteredItems}
                    columnsTable={columns}
                    topFilterPanel={width > 700}
                    showColumnSearchAction={true}
                    heightTable={tableHeight}
                    configHeader={{
                        fullScrean: true,
                        exportExcel: true,
                        resize: true,
                        grouping: false,
                        columnFilter: true,
                        sorting: width > 700,
                        columnSearch: true,
                        dragging: true,
                    }}
                    styles={tableStyles}
                    overscan={2}
                    estimateSize={mobile ? 191 : 28}
                    isTableAssistant={true}
                    exportExcelHandler={exportExcelHandler}
                />
            )}
        </div>
    );
});
