import { TAssistantListProfile } from '@f_qwep/components/assistant/types/assistant-list-profile.type';
import { makeAutoObservable, reaction } from 'mobx';
import { ChangeEvent } from 'react';
import { AssistantApi } from '../api/assistant.api';
import { EAssistantPositionOptions } from '../types/assistant-position-options.enum';
import { IAssistantProfile } from '../types/assistant-profile.type';

type TNormalizedListProfile = string[];

export class AssistantModalStore {
    title: string = '';
    key1c: string = '';
    currentPositionLoadingOption: EAssistantPositionOptions = EAssistantPositionOptions.FILE_INPUT;
    fileTitle: string = '';
    isFileLoading: boolean = false;
    isOpen: boolean = false;
    isShowPreviewTable: boolean = false;
    file: File;
    newItemsProfile: IAssistantProfile[] = [];
    normalizedNewListProfile: TNormalizedListProfile[] = [];
    newProfile: TAssistantListProfile | null = null;
    isProfileLoading: boolean = false;

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true });

        reaction(
            () => this.newItemsProfile,
            (value) => this.normalizeNewListProfile(value),
        );
    }

    setTitle(event: ChangeEvent<HTMLInputElement>) {
        this.title = event.target.value;
    }

    setKey1c(event: ChangeEvent<HTMLInputElement>) {
        this.key1c = event.target.value;
    }

    setFile(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files) {
            this.file = event.target.files[0];
            this.fileTitle = this.file.name;
        }
    }

    setCurrentPositionLoadingOptions(value: EAssistantPositionOptions) {
        this.currentPositionLoadingOption = value;
    }

    setIsOpen(bool: boolean) {
        this.isOpen = bool;
        this.resetData();
    }

    setIsShowPreviewTable(bool: boolean) {
        this.isShowPreviewTable = bool;
    }

    async handleSubmit(e: any) {
        e.preventDefault();

        if (this.currentPositionLoadingOption === EAssistantPositionOptions.FILE_INPUT) {
            this.handleFileInput();
        } else {
            this.handle1cInput();
        }
    }

    async handleFileInput() {
        if (this.isShowPreviewTable) {
            this.isProfileLoading = true;
            const response = await AssistantApi.createProfile({ name: this.title, items: this.newItemsProfile });

            if (response) {
                this.newProfile = response;
            }

            this.isShowPreviewTable = false;
            this.setIsOpen(false);
            this.isProfileLoading = false;
        } else {
            await this.uploadFile();
            console.log(this.newItemsProfile, 'this.newItemsProfile');
            this.isShowPreviewTable = this.newItemsProfile.length !== 0;
        }
    }

    async handle1cInput() {
        this.isProfileLoading = true;
        const response = await AssistantApi.createProfile1c({ name: this.title, subscriptionCode: this.key1c });

        if (response) {
            this.newProfile = response;
        }
        this.setIsOpen(false);
        this.isProfileLoading = false;
    }

    private normalizeNewListProfile(value: IAssistantProfile[]) {
        this.normalizedNewListProfile = value.map((val) => {
            return [val.item.brand, val.item.article];
        });
    }

    private async uploadFile() {
        this.isFileLoading = true;
        this.newItemsProfile = await AssistantApi.uploadFile(this.file);
        this.isFileLoading = false;
    }

    private resetData() {
        this.file = null;
        this.isShowPreviewTable = false;
        this.isFileLoading = false;
        this.title = '';
        this.fileTitle = '';
        this.key1c = '';
        this.newItemsProfile = [];
        this.normalizedNewListProfile = [];
    }
}
