import { Box, Card, CardContent, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/components/licenses/Licenses.module.sass';
import CheckIcon from '@mui/icons-material/Check';
import { ChoosenTariff } from './ChoosenTariff';
import { QwepContext } from '@f_qwep/context/Qwep_context';

const tariff = [
    {
        id: 2,
        name: 'Pro',
        value: 'WEB-версия PRO',
        price: '21600',
        func: [
            'Проценка',
            'Проценка списком',
            'Корзина заказов',
            'Оформление заказов',
            'Функция «Наценка»',
            'Контроль статусов заказов',
            'Обновления еженедельно',
        ],
    },
    {
        id: 3,
        name: 'Pro +',
        value: 'WEB-версия PRO+',
        price: '28800',
        func: [
            'Проценка',
            'Проценка списком',
            'Корзина заказов',
            'Оформление заказов',
            'Функция «Наценка»',
            'Контроль статусов заказов',
            'Обновления еженедельно',
            'Работа с прайсами',
            'Загрузка неограниченного количества прайсов',
            'Обновления в интерфейсе или по почте',
            'Предложения по прайсам с учетом кроссов',
        ],
    },
    { id: 1, name: 'Lite', value: 'WEB-версия LITE', price: '15120', func: ['Проценка'] },
];

export function Subscription() {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);
    const { blocked } = useContext(QwepContext);
    const [open, setOpen] = useState(false);
    const [defaultOpt, setDefaultOpt] = useState('WEB-версия PRO');

    const handleOpen = (value: string) => {
        setOpen(true);
        setDefaultOpt(value);
    };
    const background =
        currentTheme === 'vinpin' ? '#e30613' : 'linear-gradient(164.54deg, #218EE1 0.02%, #5759B8 98.77%)';

    return (
        <Box className={stls.subscriptionBox}>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '36px', marginBottom: '32px' }}>
                {blocked && (
                    <Typography variant="h6" sx={{ margin: '16px 0 0 0', color: colors.lambda }}>
                        {t('licenses.blocked')}
                    </Typography>
                )}
            </div>
            <Typography sx={{ fontSize: '18px' }}>{t('licenses.budget')}</Typography>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '56px' }}>
                {tariff.map((el) => (
                    <Card
                        key={el.id}
                        style={el.name == 'Pro +' ? { background: `${background}` } : {}}
                        className={el.name == 'Pro +' ? stls.subscriptionPROCard : stls.subscriptionCard}>
                        <CardContent className={stls.licensesContent}>
                            <Typography
                                sx={{
                                    fontSize: '32px',
                                    fontWeight: 'bold',
                                    color: el.name == 'Pro +' ? 'white' : colors.alpha,
                                }}>
                                {t('licenses.version')}
                                {el.name.toUpperCase()}
                            </Typography>
                            <Typography className={stls.cardText}>{t('licenses.extend')}</Typography>
                            <div style={{ display: 'flex', marginTop: '16px' }}>
                                <Typography style={el.name == 'Pro +' ? {color: 'white'} : { color: colors.omicron }}  className={stls.cardText} variant="h2">
                                    {el.price.slice(0, 2)}
                                </Typography>
                                <Typography style={el.name == 'Pro +' ? {color: 'white'} : { color: colors.omicron }}  className={stls.cardText} variant="h4">
                                    {el.price.slice(2)}
                                </Typography>
                                <Typography style={el.name == 'Pro +' ? {color: 'white'} : { color: colors.omicron }}  variant="h6">
                                    ₽
                                </Typography>
                            </div>
                            <Typography className={stls.cardText}>{t('licenses.payback')}</Typography>
                            <Button
                                onClick={() => handleOpen(el.value)}
                                sx={el.name == 'Pro +' ? {width: '100%', margin: '20px 0 36px 0', height: '50px',
                                    '&.MuiButton-outlined': {border: `1px solid #c0c0c0`},
                                    '&.MuiButton-outlined:hover': {border: `1px solid #FFFFFF`}} :
                                    { width: '100%', margin: '20px 0 36px 0', height: '50px',
                                    '&.MuiButton-outlined': {border: `1px solid rgba(33, 142, 225, 0.5)`},
                                    '&.MuiButton-outlined:hover': {border: `1px solid #3F8DC8`},
                                }}
                                style={
                                    el.name == 'Pro +'
                                        ? { background: 'transparent', color: `white` }
                                        : { color: `${colors.alpha}` }
                                }
                                variant={'outlined'}>
                                {t('licenses.choose')}
                            </Button>
                            <div className={stls.functionListWrapper}>
                                <Typography
                                    style={ el.name === 'Pro +' ? { color: colors.white} : { color: colors.omicron} }
                                    className={stls.cardText}
                                    variant="subtitle2">
                                    {t('licenses.functions')}
                                </Typography>
                                <div className={stls.functionListContainer}>
                                    {el.func.map((item) => (
                                        <Typography
                                            className={stls.cardText}
                                            sx={{ display: 'flex', alignItems: 'center' }}>
                                            <CheckIcon
                                                style={
                                                    el.name == 'Pro +'
                                                        ? { color: `white` }
                                                        : { color: `${colors.alpha}` }
                                                }
                                                sx={{ marginRight: '16px' }}
                                            />
                                            {item}
                                        </Typography>
                                    ))}
                                </div>
                            </div>
                        </CardContent>
                    </Card>
                ))}
            </div>
            <ChoosenTariff open={open} setOpen={setOpen} defaultOpt={defaultOpt} />
        </Box>
    );
}
