import { ManualContext } from '@f_qwep/context/Manual_context';
import { SettingsContext } from '@f_qwep/context/Settings_context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import GeneralJoyride from '../GeneralJoyride';
import { useWindowSize } from '@f_hooks/index';
import { AccountsContext } from '@f_qwep/context/Accounts_context';
import { IconMaskotHappy } from '@f_components/icons/IconMaskotHappy';

export function StyleTableManual() {
    const { selectedPageName, setSelectedPageName, open } = useContext(SettingsContext);
    const { setChooseManual, newManualValue, setNewManualValue } = useContext(ManualContext);

    const [run, setRun] = useState(false);

    const [currentStep, setCurrentStep] = useState(0);

    const [steps, setSteps] = useState<any>([
        {
            target: '#settings',
            content: (
                <div>
                    <IconMaskotHappy />
                    <p>Чтобы изменить стиль таблиц перейдите в "Настройки"</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#menuButtonSettings',
            content: 'Откройте меню настроек',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#addSettings',
            content: 'Перейдите в раздел "Доп. настройки"',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#styleTables',
            content: 'Теперь вы можете включить строгий стиль таблиц',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: false,
        },
    ]);

    const { width } = useWindowSize();

    useEffect(() => {
        if (width) {
            if (currentStep == 1) {
                if (width > 1200) {
                    setCurrentStep((prevStep) => prevStep + 1);
                } else {
                    if (open) {
                        setCurrentStep((prevStep) => prevStep + 1);
                    }
                }
            }
        }
    }, [currentStep, width, open]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/' || window.location.pathname == '/app-qwep') {
            setRun(true);
            setSelectedPageName('accounts');
            if (!newManualValue.settings?.flag) {
                setNewManualValue({ ...newManualValue, settings: { flag: true } });
            }
        }
    }, []);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/settings') {
            setTimeout(() => {
                setCurrentStep((prevStep) => prevStep + 1);
            }, 100);
        }
    }, [window.location.pathname, selectedPageName]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setChooseManual();
            setRun(false);
        }
    };

    return (
        <div>
            <GeneralJoyride steps={steps} run={run} callback={handleJoyrideCallback} stepIndex={currentStep} />
        </div>
    );
}
