import stls from '@f_admin/styles/general/Loading.module.sass';
import { TypeClassNames } from '@f_types/index';
import cn from 'classnames';
import { IconLoading } from '../components/icons/IconLoading';

type LoadingSettingsProps = TypeClassNames;

export function Loading({ classNames }: LoadingSettingsProps) {
    return (
        <div className={cn(stls.container, classNames)}>
            <IconLoading classNames={[stls.icon]} />
        </div>
    );
}
