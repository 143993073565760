import { IconAddPrice } from '@f_qwep/components/icons/IconAddPrice';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';

export const AssistantModalTitleView = () => {
    return (
        <div className={styles.modalTitle}>
            <IconAddPrice />
            <p className={styles.title}>Создать пользовательский список</p>
        </div>
    );
};
