import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { AssistantListsView } from './AssistantLists.view';
import { CreateListView } from './CreateList.view';
import { CurrentListActionsView } from './CurrentListActionsView';
import { WatchEducationVideoView } from './WatchEducationVideo.view';

export const ConfigPanelActionsView = () => {
    return (
        <div className={styles.config}>
            <div className={styles.nav}>
                <CreateListView />
                <AssistantListsView />
                <CurrentListActionsView />
            </div>
            <WatchEducationVideoView />
        </div>
    );
};
