import { ThemeContext } from '@f_context/Theme_context';
import { MyModal } from '@f_general/MyModal';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { Box, CardContent, Modal } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { PreviewDownloadedFileTable } from '../../../../../general/previewTable/ui/PreviewDownloadedFileTable';
import { assistantStore } from '../../../store/assistant.store';
import { AssistantModalActions } from './AssistantModalActions.view';
import { AssistantModalTitleView } from './AssistantModalTitle.view';
import { AssistantModalPositionsView } from './positions/AssistantModalPositions.view';

export const AssistantModal = observer(() => {
    const { colors } = useContext(ThemeContext);
    const { isOpen, setIsOpen, isShowPreviewTable, handleSubmit, normalizedNewListProfile } =
        assistantStore.assistantModalStore;

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)}>
            <Box>
                <MyModal className={styles.modal}>
                    <CardContent className={styles.modalContent}>
                        {!isShowPreviewTable && <AssistantModalTitleView />}
                        <form className={styles.formContent} onSubmit={handleSubmit}>
                            {isShowPreviewTable ? (
                                <PreviewDownloadedFileTable
                                    maxRows={6}
                                    colors={colors}
                                    headers={['Бренд', 'Артикул']}
                                    rows={normalizedNewListProfile}
                                />
                            ) : (
                                <>
                                    <AssistantModalPositionsView colors={colors} />
                                </>
                            )}
                            <AssistantModalActions colors={colors} />
                        </form>
                    </CardContent>
                </MyModal>
            </Box>
        </Modal>
    );
});
