import { makeAutoObservable } from 'mobx';
import { EAssistantItemStatus, INormalizedCurrentListProfile } from '../types/assistant-profile.type';

export class AssistantFiltersStore {
    isActiveProcessing: boolean;
    isActiveAuthorized: boolean;
    isActiveConfirmed: boolean;

    constructor() {
        this.isActiveProcessing = false;
        this.isActiveAuthorized = false;
        this.isActiveConfirmed = false;

        makeAutoObservable(this, {}, { autoBind: true });
    }
    setIsActiveProcessing() {
        this.isActiveProcessing = !this.isActiveProcessing;
    }
    setIsActiveAuthorized() {
        this.isActiveAuthorized = !this.isActiveAuthorized;
    }
    setIsActiveConfirmed(): void {
        this.isActiveConfirmed = !this.isActiveConfirmed;
    }

    filterItems(value: INormalizedCurrentListProfile[]) {
        if (!this.isActiveProcessing && !this.isActiveAuthorized && !this.isActiveConfirmed) {
            return value;
        }

        return value.filter((item) => {
            const matchesProcessing = this.isActiveProcessing && item.state === EAssistantItemStatus.NULL;
            const matchesAuthorized = this.isActiveAuthorized && item.state === EAssistantItemStatus.NO_ACCESS;
            const matchesConfirmed = this.isActiveConfirmed && item.state === EAssistantItemStatus.CONFIRMED;
            return matchesProcessing || matchesAuthorized || matchesConfirmed;
        });
    }
}
