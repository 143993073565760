import { ThemeContext } from '@f_context/Theme_context';
import { Chip } from '@mui/material';
import cn from 'classnames';
import { useContext, useEffect, useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { useTranslation } from 'react-i18next';
import { TypeClassNames } from '@f_types/index';

type TypeUserRoleChipProps = {
    color?: string;
    sysName: Array<'admin' | 'user' | 'manager' | 'SUPER_ADMIN'>;
} & TypeClassNames;

type TypeRole = {
    title: string;
    bgColor: string;
    starColor: string;
};

export function UserRoleChip({ color, sysName, classNames }: TypeUserRoleChipProps) {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);
    const [role, setRole] = useState<TypeRole[]>([]);
    useEffect(() => {
        setRole(
            sysName.map((item: 'admin' | 'user' | 'manager' | 'SUPER_ADMIN') => {
                switch (item) {
                    case 'admin':
                        return {
                            title: t('userRole.admin'),
                            bgColor: currentTheme == 'light' ? '#6EBA6E' : '#69DE94',
                            starColor: currentTheme == 'light' ? '#6EBA6E' : '#69DE94',
                        };
                    case 'user':
                        return {
                            title: t('userRole.user'),
                            bgColor: '#FECD52',
                            starColor: '#FECD52',
                        };
                    case 'manager':
                        return {
                            title: t('userRole.manager'),
                            bgColor: '#6EBA6E',
                            starColor: '#6EBA6E',
                        };
                    case 'SUPER_ADMIN':
                        return {
                            title: t('userRole.superAdmin'),
                            bgColor: color ? color : '#F08E13',
                            starColor: color ? color : '#F08E13',
                        };
                }
            }),
        );
    }, [sysName]);

    return (
        <div className={cn(classNames)}>
            {role.map((item) => (
                <Chip
                    sx={{ background: `${item.bgColor}20` }}
                    style={{
                        color: currentTheme == 'light' ? '#1A242A' : item.starColor,
                        margin: '0 5px',
                    }}
                    size="small"
                    key={item.title}
                    label={item.title}
                    icon={<StarIcon sx={{ '&&': { color: item.starColor } }} />}
                />
            ))}
        </div>
    );
}
