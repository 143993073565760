import { ThemeContext } from '@f_context/Theme_context';
import { assistantStore } from '@f_qwep/components/assistant/store/assistant.store';
import { ConfigPanelView } from '@f_qwep/components/assistant/view/panel/AssistantPanel.view';
import { TableAssistant } from '@f_qwep/components/assistant/view/table';
import { LayoutPage } from '@f_qwep/general/layout/LayoutPage';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { CircularProgress } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { AuthLoading } from '@f_components/loadings/AuthLoading';

export const AssistantView = observer(() => {
    const { colors } = useContext(ThemeContext);
    const { isLoadingListProfiles, isLoadingProfile } = assistantStore.assistantListProfilesStore;

    return (
        <LayoutPage classNames={[styles.container]}>
            {isLoadingListProfiles && (
                <div className={styles.loading} style={{ color: colors.alpha }}>
                    <CircularProgress size={20} className={styles.icon} />
                    Загрузка...
                </div>
            )}
            <ConfigPanelView />
            {isLoadingProfile ? (
                <div style={{ display: 'flex', position: 'relative', height: '150px' }}>
                    <AuthLoading />
                </div>
            ) : (
                <TableAssistant />
            )}
        </LayoutPage>
    );
});
