import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { assistantStore } from '../../../../store/assistant.store';
import { EAssistantPositionOptions } from '../../../../types/assistant-position-options.enum';
import { AssistantModalNameView } from '../AssistantModalName';
import { AssistantPosition1cView } from './AssistantPosition1c.view';
import { AssistantPositionFileView } from './AssistantPositionFile.view';

export const AssistantModalPositionsView: FC<any> = observer(({ colors }) => {
    const { currentPositionLoadingOption: currentOption, setCurrentPositionLoadingOptions: setCurrentOption } =
        assistantStore.assistantModalStore;

    const { INPUT_1C, FILE_INPUT } = EAssistantPositionOptions;

    return (
        <>
            <div className={styles.choiceBlock}>
                <Button
                    variant={INPUT_1C === currentOption ? 'contained' : 'outlined'}
                    onClick={() => setCurrentOption(INPUT_1C)}
                    className={styles.btnChoiceData}>
                    Загрузить 1C
                </Button>
                <Button
                    variant={FILE_INPUT === currentOption ? 'contained' : 'outlined'}
                    onClick={() => setCurrentOption(FILE_INPUT)}
                    className={styles.btnChoiceData}>
                    Загрузить Excel
                </Button>
            </div>
            <div style={{ marginTop: '30px' }}>
                <AssistantModalNameView />
                {INPUT_1C === currentOption ? (
                    <AssistantPosition1cView />
                ) : (
                    <AssistantPositionFileView colors={colors} />
                )}
            </div>
        </>
    );
});
