import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';

import { SearchContext } from '@f_qwep/context/Search_context';
import GeneralJoyride from '../GeneralJoyride';
import { ManualContext } from '@f_qwep/context/Manual_context';
import { useWindowSize } from '@f_hooks/index';
import { IconMaskotHappy } from '@f_components/icons/IconMaskotHappy';
import { IconMaskotWait } from '@f_components/icons/IconMaskotWait';

export function FiltersSearchResultManual() {
    const { colors } = useContext(ThemeContext);
    const { inputSearch, preSearchLoading, arrSearchResults, open } = useContext(SearchContext);
    const { setChooseManual, newManualValue, setNewManualValue } = useContext(ManualContext);

    const [run, setRun] = useState(false);

    const [targetLoaded, setTargetLoaded] = useState(false);

    const [currentStep, setCurrentStep] = useState(0);

    const [steps, setSteps] = useState<any>([
        {
            target: '#searchResultInput',
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Как выполнить поиск</p>
                    <p style={{ color: colors.gamma }}>NEW</p>
                </div>
            ),
            content: (
                <div>
                    <IconMaskotHappy />{' '}
                    <p>Чтобы начать поиск введите артикул и выберите бренд из подсказок, либо нажмите enter</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#helpBrand',
            content: 'Это подсказка, нажмите на искомый бренд, чтобы совершить поиск по нему',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#searchItem',
            title: 'Результаты поиска',
            content: (
                <div>
                    <IconMaskotWait /> <p>Дождитесь результатов поиска</p>{' '}
                </div>
            ),
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#buttonFilters',
            content: 'Откройте фильтры',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#filterLeftPanel',
            title: 'Фильтры для работы с таблицей результатов',
            content: 'Фильтры помогут Вам быстрее и качественнее найти необходимую позицию',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
        {
            target: '#crosses',
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Кроссы QWEP</p>
                    <p style={{ color: colors.gamma }}>NEW</p>
                </div>
            ),
            content:
                'С помощью функции возможно отсортировать подтвержденные и не подтвержденные кроссы в результатах поиска.',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
        {
            target: '#searchArticle',
            content: 'Установите флажок, чтобы отфильтровать результаты только по артикулу поиска',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#searchInStock',
            content: 'Установите флажок, чтобы отфильтровать позиции по наличию',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#searchBrands',
            content: 'Отметьте интересующие бренды',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#searchVendors',
            content: 'Отметьте необходимых поставщиков',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#searchPrice',
            content: 'Задайте интервал цен, сдвинув бегунок либо пропишите вручную',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#searchDelivery',
            content: 'Задайте интервал сроков доставки, сдвинув бегунок, либо пропишите вручную',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#searchWarehouse',
            content: 'Отметьте для просмотра требуемые склады',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#filterLeftPanel',
            title: (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p>Шаблоны при поиске</p>
                    <p style={{ color: colors.gamma }}>NEW</p>
                </div>
            ),
            content:
                'Для работы с шаблонами необходимо: \n1. Выбрать фильтры\n2. Сохранить\n3. Указать название шаблона\n4. Выбрать шаблон',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
            placement: 'right',
        },
    ]);

    const { width } = useWindowSize();

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/' || window.location.pathname == '/app-qwep') {
            if (!newManualValue.search?.flag) {
                setNewManualValue({ ...newManualValue, search: { flag: true } });
            }
            setRun(true);
        }
    }, []);

    useEffect(() => {
        if (width) {
            if (currentStep == 3) {
                const target = document.querySelector('#buttonFilters');
                if (!target && width > 950) {
                    setCurrentStep((prevStep) => prevStep + 1);
                } else {
                    if (open) {
                        setCurrentStep((prevStep) => prevStep + 1);
                    }
                }
            }
        }
    }, [currentStep, width, open]);

    useEffect(() => {
        const target = document.querySelector('#helpBrand');
        if (target) {
            setTargetLoaded(true);
        }
    }, [preSearchLoading]);

    useEffect(() => {
        if (inputSearch.length > 2) {
            if (!preSearchLoading && targetLoaded) {
                setCurrentStep((prevStep) => prevStep + 1);
            }
        }
    }, [inputSearch, preSearchLoading, targetLoaded]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/search') {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    }, [window.location.pathname]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setRun(false);
            setChooseManual();
        } else if (type === 'step:after' && (action === 'back' || action === 'prev')) {
            setCurrentStep((prevStep) => prevStep - 1);
        }
    };

    return (
        <div>
            <GeneralJoyride
                steps={steps}
                run={run}
                callback={handleJoyrideCallback}
                stepIndex={currentStep}
                disabledButton={!arrSearchResults[0]?.finished}
            />
        </div>
    );
}
