import { EAssistantItemStatus } from '@f_qwep/components/assistant/types/assistant-profile.type';
import { IGetAssistantTableConfigArgs } from '@f_qwep/components/assistant/view/table/AssistantColumn.config';
import { IconPromoView } from '@f_qwep/components/assistant/view/table/IconPromo.view';
import { useAssistantStatus } from '@f_qwep/components/assistant/view/table/useAssistantStatus';
import Tooltip from '@mui/material/Tooltip';
import cn from 'classnames';

interface IAssistantBrandColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    brand: string;
    state: EAssistantItemStatus;
    promo: boolean;
}

export const AssistantBrandColumn = (props: IAssistantBrandColumnProps) => {
    const { styles, brand, state, promo } = props;
    const { textColor } = useAssistantStatus(state, promo);

    return promo ? (
        <Tooltip title={'Промо поставщик'} hidden={false}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p
                    style={{
                        color: textColor,
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '20px',
                    }}
                    className={cn(styles.cellRow, styles.fwBold)}>
                    {brand}
                </p>
                <IconPromoView state={state} promo={promo} />
            </div>
        </Tooltip>
    ) : (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <p
                style={{
                    color: textColor,
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '20px',
                }}
                className={cn(styles.cellRow, styles.fwBold)}>
                {brand}
            </p>
        </div>
    );
};
