import { IconQWEP } from '@f_components/icons/IconQwep';
import { AuthContext } from '@f_context/Auth_context';
import { ThemeContext } from '@f_context/Theme_context';
import { Alert } from '@f_general/alert';
import { FormikController } from '@f_general/formik/FormikController';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import { forgetPassworResetUserCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import stls from '@f_styles/components/auth/Authorization.module.sass';
import colors from '@f_styles/config/Colors.module.sass';
import { TypePasswordReset } from '@f_types/index';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { Box, CircularProgress } from '@mui/material';
import cn from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// import { IconVinPin } from '@f_qwep/components/icons/IconVinPin';

export function PasswordReset() {
    const { currentTheme } = useContext(ThemeContext);
    const { logout } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    const [isLoading, setIsLoadind] = useState(false);
    const data: TypePasswordReset = {
        password: '',
        confirmPassword: '',
    };

    useEffect(() => {
        if (!location.search || !location.hash) {
            navigate('/');
        }
    }, []);

    const sendDataHandler = async (values: TypePasswordReset) => {
        setIsLoadind(true);
        if (values.password === values.confirmPassword) {
            const email = location.search.replace('?email=', '').trim();
            const key = location.hash.replace('#', '').trim();

            const result = await callPlatformAPI(
                'auth/forgot/password/reset',
                'post',
                {
                    password: values.password,
                    email: email,
                    key: key,
                },
                { guard: false },
            );

            if (result.data.isOk) {
                Alert({
                    type: 'success',
                    text: 'Пароль успешно обновлен',
                });
            } else {
                Alert({
                    type: 'error',
                    text: 'Возникла ошибка при смене пароля',
                });
            }
            await logout();
            navigate('/');
        }
        setIsLoadind(false);
    };

    return (
        <Box className={cn(stls.auth, stls.reset, stls.resetRassword)}>
            <div className={stls.IconQwep}>
                {currentTheme == 'vinpin' ? null : (
                    // <IconVinPin width={'304px'} height={'41px'} />
                    <IconQWEP width={'287'} height={'78px'} />
                )}
                <div className={stls.text}>
                    Цифровой комплекс продуктов для автоматизации и принятия управленческих решений
                </div>
            </div>
            <FormikWrapper
                onSubmit={sendDataHandler}
                initialValues={data}
                validShema={forgetPassworResetUserCustomerSchema}>
                <div className={stls.title}>Задайте новый пароль</div>
                <div>
                    <p className={stls.authTypography}>Пароль*</p>
                    <FormikController
                        className={stls.inputAuth}
                        control="input"
                        type="password"
                        placeholder="хххххххх"
                        name="password"
                        required
                    />
                </div>
                <div>
                    <p className={stls.authTypography}>Повторите пароль*</p>
                    <FormikController
                        className={stls.inputAuth}
                        control="input"
                        type="password"
                        placeholder="хххххххх"
                        name="confirmPassword"
                        required
                    />
                </div>
                <button type="submit" className={cn(stls.inputAuth, stls.btn)}>
                    {isLoading && (
                        <CircularProgress style={{ color: colors.psi }} size={'20px'} className={stls.loading} />
                    )}{' '}
                    Сохранить новый пароль
                </button>
            </FormikWrapper>
        </Box>
    );
}
