import { Unauthenticated } from '@f_components/auth';
import { AuthLoading } from '@f_components/loadings/AuthLoading';
import { Main } from '@f_components/main';
import { AuthContext } from '@f_context/Auth_context';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_styles/general/App.module.sass';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, ThemeProvider as ThemeProviderMui } from '@mui/material/styles';
import { useContext } from 'react';

export function App() {
    const { theme, themeMui } = useContext(ThemeContext);
    const { checkAuth, authIsLoading } = useContext(AuthContext);

    // console.log('checkAuth', checkAuth);

    return (
        <ThemeProviderMui theme={themeMui}>
            <ThemeProvider theme={theme}>
                <CssBaseline />

                <div className={stls.general}>
                    {/* <Main /> */}
                    {checkAuth ? (
                        authIsLoading ? (
                            <AuthLoading />
                        ) : (
                            <Main />
                        )
                    ) : authIsLoading ? (
                        <AuthLoading />
                    ) : (
                        <Unauthenticated />
                    )}
                </div>
            </ThemeProvider>
        </ThemeProviderMui>
    );
}
