import { ThemeContext } from '@f_context/Theme_context';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { Button, Tooltip } from '@mui/material';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { assistantStore } from '../../store/assistant.store';

export const AssistantFilterView = observer(() => {
    const {
        isActiveProcessing,
        setIsActiveProcessing,
        setIsActiveAuthorized,
        setIsActiveConfirmed,
        isActiveAuthorized,
        isActiveConfirmed,
    } = assistantStore.assistantFiltersStore;
    const { colors } = useContext(ThemeContext);

    return (
        <div className={styles.filter}>
            <div className={styles.filtersLeft}>
                <div className={styles.filterDeviation}>
                    <Tooltip title={'В обработке'}>
                        <Button
                            className={cn(styles.btn, styles.filterAction)}
                            onClick={setIsActiveProcessing}
                            style={{
                                borderColor: isActiveProcessing ? colors.eta : colors.epsilon,
                                color: isActiveProcessing ? colors.eta : colors.epsilon,
                            }}>
                            <div style={{ whiteSpace: 'nowrap' }}>В обработке</div>
                        </Button>
                    </Tooltip>
                    <Tooltip title={'Поставщики для знакомства'}>
                        <Button
                            className={cn(styles.btn, isActiveAuthorized && styles.activeBtn)}
                            onClick={setIsActiveAuthorized}
                            style={{
                                borderColor: isActiveAuthorized ? colors.alpha : colors.epsilon,
                                color: isActiveAuthorized ? colors.alpha : colors.epsilon,
                            }}>
                            <div style={{ whiteSpace: 'nowrap', padding: '5px' }}>Поставщики для знакомства</div>
                        </Button>
                    </Tooltip>
                    <Tooltip title={'Подтвержден'}>
                        <Button
                            className={cn(styles.btn, isActiveConfirmed && styles.activeBtn)}
                            onClick={setIsActiveConfirmed}
                            style={{
                                borderColor: isActiveConfirmed ? colors.lightgreen : colors.epsilon,
                                color: isActiveConfirmed ? colors.lightgreen : colors.epsilon,
                            }}>
                            <div style={{ whiteSpace: 'nowrap' }}>Подтвержден</div>
                        </Button>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
});
