import { AssistantListProfilesStore } from '@f_qwep/components/assistant/store/assistant-list-profiles.store';
import { makeAutoObservable, reaction } from 'mobx';
import { INormalizedCurrentListProfile } from '../types/assistant-profile.type';
import { AssistantExcelStore } from './assistant-excel.store';
import { AssistantFiltersStore } from './assistant-filter.store';
import { AssistantModalStore } from './assistant-modal.store';

class AssistantStore {
    assistantListProfilesStore: AssistantListProfilesStore;
    assistantFiltersStore: AssistantFiltersStore;
    assistantFilteredItems: INormalizedCurrentListProfile[] = [];
    assistantModalStore: AssistantModalStore;
    assistantExcelStore: AssistantExcelStore;

    constructor() {
        this.assistantListProfilesStore = new AssistantListProfilesStore();
        this.assistantFiltersStore = new AssistantFiltersStore();
        this.assistantModalStore = new AssistantModalStore();
        this.assistantExcelStore = new AssistantExcelStore();
        makeAutoObservable(this, {}, { autoBind: true });

        reaction(
            () => this.assistantListProfilesStore.normalizedCurrentListProfile,
            (value) => {
                this.assistantFilteredItems = this.assistantFiltersStore.filterItems(value);
            },
        );

        reaction(
            () => this.assistantFiltersStore.isActiveAuthorized,
            () => {
                this.assistantFilteredItems = this.assistantFiltersStore.filterItems(
                    this.assistantListProfilesStore.normalizedCurrentListProfile,
                );
            },
        );

        reaction(
            () => this.assistantFiltersStore.isActiveConfirmed,
            () => {
                this.assistantFilteredItems = this.assistantFiltersStore.filterItems(
                    this.assistantListProfilesStore.normalizedCurrentListProfile,
                );
            },
        );

        reaction(
            () => this.assistantFiltersStore.isActiveProcessing,
            () => {
                this.assistantFilteredItems = this.assistantFiltersStore.filterItems(
                    this.assistantListProfilesStore.normalizedCurrentListProfile,
                );
            },
        );

        reaction(
            () => this.assistantListProfilesStore.currentItemsProfile,
            (value) =>
                (this.assistantListProfilesStore.normalizedCurrentListProfile =
                    this.assistantListProfilesStore.normalizeCurrentListProfile(value)),
        );

        reaction(
            () => this.assistantModalStore.newProfile,
            async (value) => {
                if (value) {
                    await this.assistantListProfilesStore.getListProfiles();
                    this.assistantListProfilesStore.currentListProfile =
                        this.assistantListProfilesStore.listProfiles.find((val) => val.id === value.id);
                    await this.assistantListProfilesStore.getProfile(value.id);
                    this.assistantModalStore.newProfile = null;
                }
            },
        );

        reaction(
            () => this.assistantListProfilesStore.normalizedCurrentListProfile,
            (value) => this.assistantExcelStore.setItems(value),
        );

        reaction(
            () => this.assistantFilteredItems,
            (value) => this.assistantExcelStore.setItems(value, true),
        );
    }

    async firstLoading() {
        const defaultListName = 'Default';
        await this.assistantListProfilesStore.getListProfiles();
        if (this.assistantListProfilesStore.listProfiles.length > 0) {
            this.assistantListProfilesStore.currentListProfile = this.assistantListProfilesStore.listProfiles.find(
                (profile) => profile.name === defaultListName,
            );
            await this.assistantListProfilesStore.getProfile(this.assistantListProfilesStore.currentListProfile.id);
        }
    }
}

export const assistantStore = new AssistantStore();
