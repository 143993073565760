import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTasks.module.sass';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { Button } from '@mui/material';
import { MiniResultTableBatchPricer } from '../tables/miniResultTableBatchPricer';
import { ResultTableBatchPricer } from '../tables/resultTableBatchPricer';
import { FilterContext } from '@f_qwep/context/Filter_context';
import { useWindowSize } from '@f_hooks/index';
import { MobileButtonBatchPricer } from './MobileButtonBatchPricer';
import { FilterLeft } from '@f_qwep/components/filters/filterLeftPanel/ui';
import { FilterTypesEnum } from '@f_qwep/components/filters/filterLeftPanel/types/FilterTypes.enum';

export function BatchPricerSearchResult({ setNavigations, taskResultId }: any) {
    const { t } = useTranslation();
    const { width } = useWindowSize();

    const { miniTableRes } = useContext(QwepContext);
    const { items } = useContext(BatchPricerContext);
    const { batchPricerFiltersReducer, filterBatchPricer } = useContext(FilterContext);
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 900);
    }, [width]);

    return (
        <div
            style={
                miniTableRes
                    ? { overflow: 'scroll', display: 'flex', margin: '0 auto' }
                    : { display: 'flex', overflow: 'hidden' }
            }>
            {width > 900 && (
                <>
                    <FilterLeft
                        items={items}
                        filter={filterBatchPricer}
                        filtersReducer={batchPricerFiltersReducer}
                        type={FilterTypesEnum.BATCH_PRICER}
                        taskResultId={taskResultId}
                    />
                </>
            )}
            <div className={stls.content}>
                <div className={stls.contentButtons} style={{ display: 'flex' }}>
                    <Button sx={{ marginRight: '10px' }} onClick={() => setNavigations('taskList')} variant="contained">
                        {t('qwep.batchPricer.backToTask')}
                    </Button>
                    <Button onClick={() => setNavigations('task')} variant="contained">
                        {t('qwep.batchPricer.backToList')}
                    </Button>
                    {mobile && (
                        <MobileButtonBatchPricer
                            items={items}
                            filterBatchPricer={filterBatchPricer}
                            batchPricerFiltersReducer={batchPricerFiltersReducer}
                        />
                    )}
                </div>
                {miniTableRes ? (
                    <MiniResultTableBatchPricer
                        filter={filterBatchPricer}
                        filtersReducer={batchPricerFiltersReducer}
                        items={items}
                    />
                ) : (
                    <ResultTableBatchPricer
                        filter={filterBatchPricer}
                        filtersReducer={batchPricerFiltersReducer}
                        items={items}
                    />
                )}
            </div>
        </div>
    );
}
