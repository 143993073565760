import { Button, ButtonGroup, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import stls from '@f_qwep/styles/component/batchPricer/BatchPricerTasks.module.sass';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { useWindowSize } from '@f_hooks/index';
import { BatchPricerContext } from '@f_qwep/context/BatchPricer_context';
import { SearchBase } from '@f_general/SearchBase';
import { CellStatus, HeadStatus } from './tadleTask/CellStatus';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import { LoadingSettings } from '../loaders/LoadingSettings';
import { CellTitle, HeadTitle } from './tadleTask/CellTitle';
import { Alert } from '@f_general/alert';
import { InputGetShortNumbers } from './InputGetShortNumbers';
import CloseIcon from '@mui/icons-material/Close';
import { ControlPanel } from './СontrolPanel';
import { CellMobile } from './tadleTask/CellMobile';
import { CastomCircularProgress } from '@f_utils/CastomCircularProgress';
import { useValidAccounts } from '@f_qwep/hooks/useValidAccounts';

export function BatchPricerTask({ setNavigations, taskInfo, shownItem, setShownItem }: any) {
    const { taskId, resultsId, taskName } = taskInfo;
    const { t } = useTranslation();
    const { task, getTaskItem, getStatusTask, progressList, updateTask, downloadTask } = useContext(BatchPricerContext);
    const validAccounts = useValidAccounts();

    const { width, height } = useWindowSize();
    const { colors } = useContext(ThemeContext);
    const { miniTableRes, getFiltersBatchPricerTask } = useContext(QwepContext);
    const [mobile, setMobile] = useState(false);
    const [taskTable, setTaskTable] = useState(task);
    const [statusItems, setStatusItems] = useState([]);

    //edit
    const [openEdit, setOpenEdit] = useState(false);
    const [editTask, setEditTask] = useState([]);
    //add item
    const [openAddItem, setOpenAddItem] = useState(false);
    //showResult
    const [loading, setLoading] = useState(false);

    const [loadingDownload, setLoadingDownload] = useState(false);

    useEffect(() => {
        setTaskTable(task);
    }, [task]);

    useEffect(() => {
        setMobile(width < 800);
    }, [width]);

    useEffect(() => {
        showStatus();
    }, [progressList[taskId]]);

    const showStatus = async () => {
        const result = await getStatusTask(taskId);
        if (result.isOk) {
            setStatusItems(result.result.message);
        }
    };

    const showResultItem = async (id: number) => {
        setLoading(true);
        await getTaskItem(resultsId, id);
        setLoading(false);
        setNavigations('resultItem');
    };

    const editHandler = () => {
        if (openEdit) {
            setOpenEdit(false);
        } else {
            setOpenEdit(true);
        }
    };

    const sendEditHandler = async () => {
        if (!validAccounts) {
            return;
        }
        if (editTask.length) {
            const result = await updateTask(editTask, taskId, validAccounts);
            if (result.isOk) {
                Alert({
                    type: 'success',
                    text: t('general.success'),
                });
            } else {
                Alert({
                    type: 'error',
                    text: t('general.error'),
                });
            }
        }
        setEditTask([]);
        setOpenEdit(false);
    };

    const sendAddItemHandler = async (option: any) => {
        if (!validAccounts) {
            return;
        }
        const result = await updateTask(
            [
                {
                    brand: option.brand,
                    article: option.article,
                    isActive: true,
                    priceThreshold: 'BLANK',
                    id: null,
                },
            ],
            taskId,
            validAccounts,
        );
        if (result.isOk) {
            Alert({
                type: 'success',
                text: t('general.success'),
            });
            setOpenAddItem(false);
        } else {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
    };

    const dowloadHandler = async () => {
        const { inStock, withCrosses } = getFiltersBatchPricerTask(resultsId);
        setLoadingDownload(true);
        const result = await downloadTask(resultsId, taskName, withCrosses, inStock);
        if (!result.isOk) {
            Alert({
                type: 'error',
                text: t('general.error'),
            });
        }
        setLoadingDownload(false);
    };

    const columns = useMemo<ColumnDef<any>[]>(
        () =>
            mobile
                ? [
                      {
                          id: 'price',
                          accessorKey: 'price',
                          header: () => <div style={{ display: 'flex' }}></div>,
                          cell: (info) => (
                              <CellMobile
                                  showResultItem={showResultItem}
                                  setShownItem={setShownItem}
                                  shownItem={shownItem}
                                  statusItems={statusItems}
                                  info={info}
                              />
                          ),
                          footer: (props) => props.column.id,
                      },
                  ]
                : [
                      {
                          id: 'title',
                          accessorKey: 'title',
                          header: () => <HeadTitle />,
                          cell: (info) => <CellTitle info={info} openEdit={openEdit} setEditTask={setEditTask} />,
                          footer: (props) => props.column.id,
                          size: 180,
                      },
                      {
                          id: 'status',
                          accessorKey: 'status',
                          header: () => <HeadStatus />,
                          cell: (info) => (
                              <CellStatus
                                  info={info}
                                  showResultItem={showResultItem}
                                  setShownItem={setShownItem}
                                  shownItem={shownItem}
                                  statusItems={statusItems}
                              />
                          ),
                          footer: (props) => props.column.id,
                          size: 120,
                      },
                  ],

        [task, shownItem, mobile, statusItems, openEdit],
    );

    const tableStyles = {
        head: {
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '45px',
            },
            marginRow: {
                margin: '8px 0',
            },
            cellRadius: true,
        },
    };

    return (
        <div style={miniTableRes ? { display: 'flex', margin: '0 auto' } : { display: 'flex' }}>
            {loading ? (
                <LoadingSettings />
            ) : (
                <div>
                    <div className={stls.appbarPrices}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SearchBase
                                data={task}
                                placeholder={t('qwep.batchPricer.searchBatchPricer')}
                                setData={setTaskTable}
                                type={'taskList'}
                            />
                            {progressList[taskId] && (
                                <CastomCircularProgress progress={+progressList[taskId].slice(0, -1)} />
                            )}
                        </div>
                        <div>
                            <ControlPanel taskId={taskId} info={progressList[taskId]} icons={false} />
                        </div>
                        <div className={stls.editButtons}>
                            {openAddItem && !progressList[taskId] ? (
                                <div style={{ display: 'flex' }}>
                                    <InputGetShortNumbers
                                        styles={{
                                            width: 300,
                                            '& .MuiInputBase-root': {
                                                padding: '12px 9px 12px 9px !important',
                                            },
                                            '& .MuiAutocomplete-endAdornment': {
                                                top: 0,
                                            },
                                        }}
                                        actionHandler={sendAddItemHandler}
                                        label={t('qwep.batchPricer.enterArticle')}
                                    />
                                    <Button
                                        variant="contained"
                                        sx={{ marginLeft: '5px' }}
                                        onClick={() => setOpenAddItem(false)}>
                                        <CloseIcon style={{ color: colors.tau }} />
                                    </Button>
                                </div>
                            ) : (
                                <>
                                    <Button
                                        style={{ marginRight: '20px' }}
                                        variant="contained"
                                        disabled={progressList[taskId]}
                                        onClick={dowloadHandler}>
                                        {t('general.download')}
                                        {loadingDownload && (
                                            <CircularProgress
                                                size={20}
                                                style={{ color: colors.tau, marginLeft: '10px' }}
                                            />
                                        )}
                                    </Button>
                                    <Button
                                        id="addPosition"
                                        variant="contained"
                                        disabled={progressList[taskId]}
                                        onClick={() => setOpenAddItem(true)}>
                                        {t('qwep.batchPricer.addPosition')}
                                    </Button>
                                </>
                            )}

                            {openEdit ? (
                                <ButtonGroup style={{ margin: '0 20px' }}>
                                    <Button
                                        variant="contained"
                                        disabled={progressList[taskId]}
                                        onClick={sendEditHandler}>
                                        {t('qwep.batchPricer.saveChanges')}
                                    </Button>
                                    <Button variant="contained" onClick={editHandler}>
                                        {t('qwep.batchPricer.hide')}
                                    </Button>
                                </ButtonGroup>
                            ) : (
                                <Button
                                    id="editBatchPricer"
                                    variant="contained"
                                    disabled={progressList[taskId]}
                                    style={{ margin: '0 20px' }}
                                    onClick={editHandler}>
                                    {t('qwep.batchPricer.edit')}
                                </Button>
                            )}
                            <Button onClick={() => setNavigations('taskList')} variant="contained">
                                {t('qwep.batchPricer.back')}
                            </Button>
                        </div>
                    </div>
                    <VirtualTable
                        data={taskTable}
                        columnsTable={columns}
                        topFilterPanel={true}
                        showColumnSearchAction={false}
                        heightTable={height - 150}
                        configHeader={{
                            fullScrean: false,
                            exportExcel: false,
                            resize: false,
                            grouping: false,
                            columnFilter: false,
                            sorting: false,
                            columnSearch: false,
                            dragging: false,
                        }}
                        styles={tableStyles}
                        overscan={2}
                        estimateSize={53}
                    />
                </div>
            )}
        </div>
    );
}
