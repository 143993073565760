import stls from '@f_crossdata/styles/components/header/HeaderComponent.module.sass';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { useWindowSize } from '@f_hooks/index';
import { WorkPeriod } from '@f_general/WorkPeriod';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';

export function HeaderComponent() {
    const { colors } = useContext(ThemeContext);
    const { applicationInfoLoading, appInfo } = useContext(CrossdataContext);

    //   const [mobile, setMobile] = useState(false)

    //   const { width, height } = useWindowSize();

    //   useEffect(() => {
    //     setMobile(width < 480);
    //   }, [width]);

    return (
        <div className={stls.container}>
            <div className={stls.item}>
                <WorkPeriod loading={applicationInfoLoading} workPeriod={appInfo?.expire} />
            </div>
        </div>
    );
}
