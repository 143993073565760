import { AssistantApi } from '@f_qwep/components/assistant/api/assistant.api';
import { TAssistantListProfile } from '@f_qwep/components/assistant/types/assistant-list-profile.type';
import {
    EAssistantItemState,
    EAssistantItemStatus,
    IAssistantProfile,
    INormalizedCurrentListProfile,
} from '@f_qwep/components/assistant/types/assistant-profile.type';
import { action, makeObservable, observable } from 'mobx';

export class AssistantListProfilesStore {
    isLoadingListProfiles: boolean = false;
    isLoadingProfile: boolean = true;
    listProfiles: TAssistantListProfile[] = [];
    currentListProfile: TAssistantListProfile = null;
    currentItemsProfile: IAssistantProfile[] = [];
    normalizedCurrentListProfile: INormalizedCurrentListProfile[] = [];
    isLoadingRemoveProfile: boolean = false;

    constructor() {
        makeObservable(this, {
            isLoadingListProfiles: observable,
            isLoadingProfile: observable,
            currentListProfile: observable,
            listProfiles: observable,
            currentItemsProfile: observable,
            normalizedCurrentListProfile: observable,
            getListProfiles: action.bound,
            getProfile: action.bound,
            normalizeCurrentListProfile: action.bound,
            changeProfile: action.bound,
            removeProfile: action.bound,
            isLoadingRemoveProfile: observable,
        });
    }

    async getListProfiles() {
        this.isLoadingListProfiles = true;
        this.listProfiles = await AssistantApi.getListProfile();
        this.isLoadingListProfiles = false;
    }

    async getProfile(id: string) {
        this.isLoadingProfile = true;
        this.currentItemsProfile = await AssistantApi.getProfile(id);
        this.isLoadingProfile = false;
    }

    normalizeCurrentListProfile(currentItems: IAssistantProfile[]) {
        return currentItems.reduce((acc: INormalizedCurrentListProfile[], currentValue: IAssistantProfile) => {
            if (!currentValue.result.length) {
                acc.push({
                    id: currentValue.item.id,
                    brand: currentValue.item.brand,
                    article: currentValue.item.article,
                    averageAvailability: 0,
                    price: {
                        value: [0],
                        currency: 'р.',
                    },
                    vendorName: '',
                    partName: '',
                    warehouse: '',
                    state: EAssistantItemStatus.NULL,
                    promo: false,
                });
            } else {
                const result = currentValue.result.map((item) => {
                    let priceValue = item.price.value;

                    // Check if the status is NO_ACCESS and adjust the price value
                    if (this.getStatus(item.state) === EAssistantItemStatus.NO_ACCESS && !item.promo) {
                        if (priceValue.length === 2) {
                            const averageValue = (priceValue[0] + priceValue[1]) / 2;
                            priceValue = [averageValue];
                        } else if (priceValue.length === 1) {
                            priceValue = [priceValue[0]];
                        }
                    }

                    return {
                        id: currentValue.id,
                        brand: currentValue.item.brand,
                        article: currentValue.item.article,
                        averageAvailability: item.averageAvailability,
                        price: {
                            value: priceValue,
                            currency: 'р.',
                        },
                        partName: item.partName,
                        vendorName: item.vendorName,
                        warehouse: item.warehouse,
                        state: this.getStatus(item.state),
                        promo: item.promo,
                    };
                });
                acc.push(...result);
            }
            return acc;
        }, [] as INormalizedCurrentListProfile[]);
    }

    async changeProfile(id: string) {
        this.currentListProfile = this.listProfiles.find((profile) => profile.id === id);
        await this.getProfile(id);
    }

    async removeProfile() {
        this.isLoadingRemoveProfile = true;
        await AssistantApi.deleteProfile(this.currentListProfile.id);
        await this.getListProfiles();
        this.currentListProfile = this.listProfiles.find((profile) => profile.name === 'Default');
        await this.getProfile(this.currentListProfile.id);
        this.isLoadingRemoveProfile = false;
    }

    private getStatus(state: EAssistantItemState) {
        if (state === EAssistantItemState.CONFIRMED) {
            return EAssistantItemStatus.CONFIRMED;
        }

        if (state === EAssistantItemState.NO_ACCESS) {
            return EAssistantItemStatus.NO_ACCESS;
        }

        return EAssistantItemStatus.NULL;
    }
}
