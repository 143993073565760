import { createContext, useEffect, useState } from 'react';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { TypeForgotPassword, TypeLoginData, TypeRegistration } from '@f_types/index';
import { setTokens } from '@f_utils/setToken';
import { getToken } from '@f_utils/getToken';
import { removeTokens } from '@f_utils/removeTokens';

interface AuthContextInterface {
    login: (data: TypeLoginData) => void;
}

const AuthContext = createContext<any>(null);

function AuthProvider(props: any) {
    const [checkAuth, setCheckAuth] = useState<boolean>(false);
    const [authIsLoading, setAuthIsLoading] = useState<boolean>(false);
    const [accountInfo, setAccountInfo] = useState<any>({});
    const [banAccount, setBanAccount] = useState(false);
    const [oldUserInfo, setOldUserInfo] = useState();

    useEffect(() => {
        authPlatform();
    }, []);

    const authPlatform = async () => {
        setAuthIsLoading(true);
        if (await getToken()) {
            const result = await callPlatformAPI('auth', 'get');
            console.log('AUTH', result);

            if (result.data.isOk) {
                setCheckAuth(true);
                setAccountInfo(result.data.result);
            } else {
                setCheckAuth(false);
            }
        }
        setAuthIsLoading(false);
    };

    const login = async (data: TypeLoginData) => {
        setAuthIsLoading(true);
        const result = await callPlatformAPI('auth/login', 'post', data, { guard: false });
        console.log('LOGIN', result);

        if (result.data.isOk) {
            setTokens(result.data.result.tokens);
            setAccountInfo(result.data.result.account);
            setCheckAuth(true);
        } else {
            setCheckAuth(false);
        }
        setAuthIsLoading(false);
        return result;
    };

    const registration = async (data: TypeRegistration) => {
        const result = await callPlatformAPI('auth/register', 'post', data, { guard: false });
        console.log('registration', result);
        return result;
    };

    const forgotPassword = async (data: TypeForgotPassword) => {
        const result = await callPlatformAPI('auth/forgot/password', 'post', data, { guard: false });
        console.log('forgotPassword', result);
        return result;
    };

    const logout = async () => {
        console.log('logout', logout);
        //Для отслеживания события и очищения сторов
        document.dispatchEvent(new CustomEvent('resetStores'));
        const result = await callPlatformAPI('auth/logout', 'get');
        if (result.data.isOk) {
            removeTokens();
            localStorage.clear();
            setCheckAuth(false);
        }
        return result;
    };

    const changeWorkspace = async (workspaceId: number) => {
        const result = await callPlatformAPI('auth/workspace/change', 'post', { workspaceId });
        console.log('changeWorkspace', result);
        if (result.data.isOk) {
            setTokens(result.data.result.tokens);
            setAccountInfo(result.data.result.account);
        }
        return result.data;
    };

    return (
        <AuthContext.Provider
            value={{
                login,
                registration,
                checkAuth,
                authIsLoading,
                forgotPassword,
                accountInfo,
                logout,
                banAccount,
                oldUserInfo,

                setOldUserInfo,
                changeWorkspace,
                setAccountInfo,
                setBanAccount,
                setCheckAuth,
            }}
            {...props}
        />
    );
}

export { AuthProvider, AuthContext };
