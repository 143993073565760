import { assistantStore } from '@f_qwep/components/assistant/store/assistant.store';
import { useAccess } from '@f_qwep/hooks/useAccess';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';
import { observer } from 'mobx-react-lite';
import { createContext, PropsWithChildren, useEffect } from 'react';

const AssistantContext = createContext<{ access: boolean } | null>(null);

const intervalMs = 120000;

const AssistantProvider = observer(({ children }: PropsWithChildren) => {
    const { isAccess: access } = useAccess(QwepProductEnum.ASSISTANT);

    // Обновление текущего списка
    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (access) {
            assistantStore.firstLoading();
            intervalId = setInterval(async () => {
                await assistantStore.assistantListProfilesStore.getProfile(
                    assistantStore.assistantListProfilesStore.currentListProfile.id,
                );
            }, intervalMs);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [access]);

    return <AssistantContext.Provider value={{ access }}>{children}</AssistantContext.Provider>;
});

export { AssistantProvider };
