import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import { observer } from 'mobx-react-lite';
import { ConfigPanelActionsView } from './actions/ConfigPanelActions';
import { AssistantFilterView } from './AssistantFilter.view';
import { AssistantModal } from './modal/AssistantModal.view';

export const ConfigPanelView = observer(() => {
    return (
        <div className={styles.panel}>
            <ConfigPanelActionsView />
            <AssistantFilterView />
            <AssistantModal />
        </div>
    );
});
