import { ThemeContext } from '@f_context/Theme_context';
import styles from '@f_qwep/styles/component/purchasingAssistant/PurchasingAssistant.module.sass';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';
import { assistantStore } from '../../../store/assistant.store';

export const CurrentListActionsView = observer(() => {
    const { colors } = useContext(ThemeContext);
    const { currentListProfile, removeProfile, isLoadingRemoveProfile } = assistantStore.assistantListProfilesStore;

    return (
        <div className={styles.portraitActionsWrapper}>
            {currentListProfile && currentListProfile?.name !== 'Default' && (
                <>
                    <Tooltip title={'Удалить'}>
                        <IconButton
                            disabled={isLoadingRemoveProfile}
                            className={styles.paramBtn}
                            onClick={() => removeProfile()}>
                            <DeleteForeverIcon style={{ color: colors.lambda, width: '35px' }} />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </div>
    );
});
