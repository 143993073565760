import { IconAttach } from '@f_components/icons/IconAttach';
import styles from '@f_qwep/styles/component/purchasingAssistant/AddCastomListModal.module.sass';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { assistantStore } from '../../../../store/assistant.store';

export const AssistantPositionFileView: FC<any> = observer(({ colors }) => {
    const { t } = useTranslation();
    const { fileTitle, isFileLoading, setFile } = assistantStore.assistantModalStore;

    return (
        <div className={styles.fileBlock}>
            <p>
                Укажите файл с колонками A - Бренд, B - Артикул, для автодобавления позиций в список. Поддерживаемые
                форматы xls, xlsx
            </p>
            <p>
                <label className={styles.inputAddFile} htmlFor="file">
                    <IconAttach />
                    <p id="inputAddFile" style={{ color: colors.alpha }} className={styles.text}>
                        {t('qwep.price.modal.updateOrAddPriceModal.labelFile')}
                    </p>{' '}
                    <p className={styles.fileName}>{fileTitle}</p>{' '}
                    {isFileLoading && <CircularProgress size={20} className={styles.loading} />}
                    <input id="file" type="file" onChange={(e) => setFile(e)} />
                </label>
            </p>
        </div>
    );
});
