import { ShowErrors } from './ShowErrors';
import stls from '@f_qwep/styles/component/header/HeaderComponent.module.sass';
import { useContext, useEffect, useState } from 'react';
import { IconBasket } from '../icons/IconBasket';
import { ThemeContext } from '@f_context/Theme_context';
import { useWindowSize } from '@f_hooks/index';
import { Library } from '../manual/Library';
import { WorkPeriod } from '@f_general/WorkPeriod';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';
import { HeaderAssistantPromo } from '@f_qwep/general/promoNotification/promoAssistant/HeaderAssistantPromo';
import { useAccess } from '@f_qwep/hooks/useAccess';
import { QwepProductEnum } from '@f_qwep/types/QwepProductEnum.type';

export function HeaderComponent() {
    const navigate = useQwepNavigate();
    const { colors } = useContext(ThemeContext);
    const { applicationInfoLoading, workPeriod } = useContext(QwepContext);
    const [mobile, setMobile] = useState(false);
    const { width } = useWindowSize();

    const { isAccess, currentTariff } = useAccess(QwepProductEnum.SHOW_PROMOTION_ASSISTANT);

    useEffect(() => {
        setMobile(width < 480);
    }, [width]);

    return (
        <div className={stls.container}>
            {currentTariff && isAccess && <HeaderAssistantPromo width={width} />}
            {mobile && (
                <button id="basket" onClick={() => navigate('basket')}>
                    <IconBasket color={colors.epsilon} height="24" width="24" />
                </button>
            )}
            <div className={stls.item}>
                <WorkPeriod
                    loading={applicationInfoLoading}
                    workPeriod={workPeriod?.slice(9).split('-').reverse().join('-')}
                />
            </div>
            <div className={stls.item}>
                <ShowErrors />
            </div>
            <div className={stls.item}>
                <Library />
            </div>
        </div>
    );
}
