import { ThemeContext } from '@f_context/Theme_context';
import { EAssistantItemStatus } from '@f_qwep/components/assistant/types/assistant-profile.type';
import { useContext } from 'react';

export const useAssistantStatus = (state: EAssistantItemStatus, promo: boolean) => {
    const { currentTheme, colors } = useContext(ThemeContext);

    let color;
    let borderColor;
    let status;
    let textColor;

    if (
        state === EAssistantItemStatus.NULL ||
        state === EAssistantItemStatus.NO_CONFIRMED ||
        state === EAssistantItemStatus.FOUND ||
        state === EAssistantItemStatus.ON_CHECK
    ) {
        color = colors.sigmaSecondary;
        borderColor = colors.sigmaSecondary;
        status = 'В обработке';
        textColor = colors.sigmaSecondary;
    }

    if (state === EAssistantItemStatus.NO_ACCESS) {
        if (promo) {
            color = colors.alpha;
            borderColor = colors.alpha;
            status = 'Реклама';
            textColor = colors.alpha;
        } else {
            color = colors.sigma;
            borderColor = colors.sigma;
            status = 'Не авторизован';
            textColor = colors.sigma;
        }
    }

    if (state === EAssistantItemStatus.CONFIRMED) {
        color = colors.green;
        borderColor = colors.green;
        status = 'Подтвержден';
        textColor = colors.green;
    }

    return {
        status,
        borderColor,
        color,
        textColor,
    };
};
