import { getToken } from './getToken';

//http://localhost:3000/
//https://web.qwep.ru/

export const exportExcelFetch = async (url: string, data: any) => {
    let token = await getToken();
    return fetch(`https://web.qwep.ru/api/${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((res: any) => res.blob())
        .then((file: any) => {
            let fileName = `${data.title}_${new Date().toLocaleDateString('ru')}`;
            let link = document.createElement('a');
            document.body.appendChild(link);
            link.download = fileName + '.xlsx';
            link.href = window.URL.createObjectURL(file);
            link.click();
            document.body.removeChild(link);
            return {
                isOk: true,
            };
        })
        .catch((error) => {
            console.error('error', error);
            return {
                isOk: false,
            };
        });
};

export const exportExcelFetchBatchPricer = async (url: string, titleFile: string) => {
    let token = await getToken();
    const response = await fetch(`https://web.qwep.ru/api/${url}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });

    if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Проценка-${titleFile}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        return {
            isOk: true,
        };
    } else {
        console.error('Failed to download file');
        return {
            isOk: false,
        };
    }
};
