import { useLocalStorage } from '@f_hooks/index';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useState } from 'react';
import { AuthContext } from './Auth_context';

type TypeAddWorkspace = {
    workspaceName: string;
    telegramNickname: string;
};

const WorkspaceContext = createContext<any>({});

function WorkspaceProvider(props: any) {
    const { setAccountInfo, accountInfo } = useContext(AuthContext);
    const [workspaces, setWorkspaces] = useState([]);
    const [childWorkspaces, setChildWorkspaces] = useState([]);
    const [currentWorkspace, setCurrentWorkspace] = useState();
    const [indexWorkspace, setIndexWorkspace] = useLocalStorage('indexWorkspace', '');

    const getInfoWorkspaces = async () => {
        const result = await callPlatformAPI('workspace/info/workspaces', 'get');
        console.log('getInfoWorkspaces', result);
        if (result.data.isOk) {
            setWorkspaces(result.data.result.workspaces);
            setChildWorkspaces(result.data.result.childWorkspaces);
            setCurrentWorkspace(result.data.result.currentWorkspace);
            // setIndexWorkspace()
        }
    };

    const addWorkspace = async (data: TypeAddWorkspace) => {
        const result = await callPlatformAPI('workspace/add', 'post', data);
        console.log('addWorkspace', result);
        if (result.data?.isOk) {
            getInfoWorkspaces();
            const workspaces = [...accountInfo.drawerWorkspaces, result.data?.result];
            setAccountInfo({ ...accountInfo, drawerWorkspaces: workspaces });
        }
        return result.data;
    };

    return (
        <WorkspaceContext.Provider
            value={{
                currentWorkspace,
                childWorkspaces,
                workspaces,
                indexWorkspace,

                //methods
                addWorkspace,
                getInfoWorkspaces,
                setIndexWorkspace,
            }}
            {...props}
        />
    );
}

export { WorkspaceProvider, WorkspaceContext };
