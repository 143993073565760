import { AssistantArticleColumn } from '@f_qwep/components/assistant/view/table/columns/AssistantArticleColumn';
import { AssistantAvailabilityColumn } from '@f_qwep/components/assistant/view/table/columns/AssistantAvailabilityColumn';
import { AssistantBrandColumn } from '@f_qwep/components/assistant/view/table/columns/AssistantBrandColumn';
import { AssistantConfirmationColumn } from '@f_qwep/components/assistant/view/table/columns/AssistantConfirmationColumn';
import { AssistantConfirmedPriceColumn } from '@f_qwep/components/assistant/view/table/columns/AssistantConfirmedPriceColumn';
import { AssistantPartNameColumn } from '@f_qwep/components/assistant/view/table/columns/AssistantPartNameColumn';
import { AssistantVendorColumn } from '@f_qwep/components/assistant/view/table/columns/AssistantVendorColumn';
import { AssistantWarehouseColumn } from '@f_qwep/components/assistant/view/table/columns/AssistantWarehouseColumn';
import { ColumnDef } from '@tanstack/react-table';
import { TFunction } from 'i18next';

export interface IGetAssistantTableConfigArgs {
    t: TFunction<'translation', undefined>;
    colors: any;
    styles: any;
}

export const getAssistantTableConfig = (args: IGetAssistantTableConfigArgs): ColumnDef<any>[] => {
    const { t, colors, styles } = args;
    return [
        {
            id: 'Производитель',
            accessorKey: 'brand',
            header: `${t('qwep.search.brand')}`,
            cell: (info) => {
                const { brand, state, promo } = info.row.original;
                const props = { state, brand, styles, colors, promo };
                return <AssistantBrandColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 60,
        },
        {
            id: 'Артикул',
            accessorKey: 'article',
            header: `${t('qwep.search.article')}`,
            cell: (info) => {
                const { state, article, promo } = info.row.original;
                const props = { state, article, styles, colors, promo };
                return <AssistantArticleColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 50,
        },
        {
            id: 'Наименование',
            accessorKey: 'partName',
            header: `${t('qwep.search.partname')}`,
            cell: (info) => {
                const { state, partName, promo } = info.row.original;
                const props = {
                    state,
                    partName,
                    styles,
                    colors,
                    promo,
                };
                return <AssistantPartNameColumn {...props} />;
            },
            footer: (props) => props.column.id,
            minSize: 170,
        },
        {
            id: 'Поставщик',
            accessorKey: 'vendorName',
            header: `${t('qwep.search.vendor')}`,
            cell: (info) => {
                const { vendorName, state, promo } = info.row.original;
                const props = {
                    styles,
                    colors,
                    vendorName,
                    state,
                    promo,
                };
                return <AssistantVendorColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 70,
        },
        {
            id: 'Цена',
            accessorKey: 'confirmedPrice',
            header: `${t('qwep.search.confirmedPrice')}`,
            cell: (info) => {
                const { price, state, promo } = info.row.original;
                const props = {
                    styles,
                    colors,
                    price,
                    state,
                    promo,
                };
                return <AssistantConfirmedPriceColumn {...props} />;
            },
            footer: (props) => props.column.id,
            maxSize: 90,
            sortingFn: (a: any, b: any) => b.original.price?.value[0] - a.original?.price.value[0],
        },
        {
            id: 'Наличие',
            accessorKey: 'averageAvailability',
            header: `${t('qwep.search.availability')}`,
            cell: (info) => {
                const { averageAvailability, state, promo } = info.row.original;
                const props = {
                    styles,
                    colors,
                    averageAvailability,
                    state,
                    promo,
                };

                return <AssistantAvailabilityColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 30,
        },
        {
            id: 'Склад',
            accessorKey: 'warehouse',
            header: `${t('qwep.search.warehouse')}`,
            cell: (info) => {
                const { warehouse, state, promo } = info.row.original;
                const props = {
                    warehouse,
                    styles,
                    colors,
                    state,
                    promo,
                };

                return <AssistantWarehouseColumn {...props} />;
            },
            footer: (props) => props.column.id,
            size: 70,
        },
        {
            id: 'Статус',
            accessorKey: 'state',
            header: `${t('qwep.search.status')}`,
            cell: (info) => (
                <AssistantConfirmationColumn
                    state={info.row.original.state}
                    article={info.row.original.article}
                    brand={info.row.original.brand}
                    vendorName={info.row.original.vendorName}
                    styles={styles}
                    promo={info.row.original.promo}
                />
            ),
            footer: (props) => props.column.id,
            size: 70,
        },
    ];
};
