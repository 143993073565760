import { IGetAssistantTableConfigArgs } from '@f_qwep/components/assistant/view/table/AssistantColumn.config';
import Tooltip from '@mui/material/Tooltip';
import { EAssistantItemStatus } from '../../../types/assistant-profile.type';
import { useAssistantStatus } from '../useAssistantStatus';

interface IAssistantWarehouseColumnProps extends Omit<IGetAssistantTableConfigArgs, 't'> {
    warehouse: string;
    state: EAssistantItemStatus;
    promo: boolean;
}

export const AssistantWarehouseColumn = (props: IAssistantWarehouseColumnProps) => {
    const { styles, warehouse, state, promo } = props;

    const { textColor } = useAssistantStatus(state, promo);

    return (
        <Tooltip title={warehouse}>
            <p
                className={styles.cellRow}
                style={{
                    color: textColor,
                }}>
                {warehouse ? warehouse : '-'}
            </p>
        </Tooltip>
    );
};
