import { FormikController } from '@f_general/formik/FormikController';
import { FormikWrapper } from '@f_general/formik/FormikWrapper';
import cn from 'classnames';
import colors from '@f_styles/config/Colors.module.sass';
import { useNavigate } from 'react-router-dom';
import stls from '@f_styles/components/auth/Authorization.module.sass';
import { Box, Button, CircularProgress, ThemeProvider, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { forgetPassworResetUserCustomerSchema } from '@f_general/formik/helpers/ValidationSchema';
import { TypePasswordReset } from '@f_types/index';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { Alert } from '@f_general/alert';
import { AuthContext } from '@f_context/Auth_context';

export function PasswordResetOld() {
    const { oldUserInfo, setOldUserInfo, logout } = useContext(AuthContext);

    const navigate = useNavigate();
    const [isLoading, setIsLoadind] = useState(false);
    const data: TypePasswordReset = {
        password: '',
        confirmPassword: '',
    };

    useEffect(() => {
        if (!oldUserInfo) {
            navigate('/');
        }
    }, []);

    const sendDataHandler = async (values: TypePasswordReset) => {
        setIsLoadind(true);
        if (values.password === values.confirmPassword) {
            const result = await callPlatformAPI(
                'auth/old/user/forgot/password/reset',
                'post',
                {
                    password: values.password,
                    userId: oldUserInfo.user.userId,
                },
                { guard: false },
            );

            if (result.data.isOk) {
                Alert({
                    type: 'success',
                    text: 'Пароль успешно обновлен',
                });
            } else {
                Alert({
                    type: 'error',
                    text: 'Возникла ошибка при смене пароля.\nОбратитесь в службу поддержки',
                });
            }
            await logout();
            navigate('/');
            setOldUserInfo();
        }
        setIsLoadind(false);
    };

    return (
        <Box className={cn(stls.auth, stls.reset, stls.resetRassword)}>
            <FormikWrapper
                title={`${oldUserInfo?.user?.firstName}, смените, пожалуйста, пароль`}
                onSubmit={sendDataHandler}
                initialValues={data}
                validShema={forgetPassworResetUserCustomerSchema}>
                <div>
                    <Typography className={stls.authTypography}>Пароль*</Typography>
                    <FormikController
                        className={stls.inputAuth}
                        control="input"
                        type="password"
                        placeholder="хххххххх"
                        name="password"
                        required
                    />
                </div>
                <div>
                    <Typography className={stls.authTypography}>Повторите пароль*</Typography>
                    <FormikController
                        className={stls.inputAuth}
                        control="input"
                        type="password"
                        placeholder="хххххххх"
                        name="confirmPassword"
                        required
                    />
                </div>
                <Button type="submit" className={cn(stls.inputAuth, stls.btn)} variant="contained">
                    {isLoading && (
                        <CircularProgress style={{ color: colors.psi }} size={'20px'} className={stls.loading} />
                    )}{' '}
                    Сохранить новый пароль
                </Button>
            </FormikWrapper>
        </Box>
    );
}
