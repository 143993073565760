import { GeneralContext } from '@f_context/General_context';
import { UserContext } from '@f_context/User_context';
import { Apps } from '@f_types/index';
import { callPlatformAPI } from '@f_utils/callPlatformAPI';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { exportExcelFetch } from '@f_utils/exportExcelFetch';

type TypeChangeTariff = {
    phone: string;
    name: string;
    tariff: string;
    comment: string;
};
export type TypeAppInfo = {
    authorizationCode: string;
    keys1c: { key: string; alias: string }[];
    keysWeb: { key: string; alias: string }[];
    paidTime: string;
};
export type TypeExportExcel = {
    type: string;
    data: any;
    title: string;
};

export type TypeKeyAliasConfiguration = {
    key: string;
    alias: string;
};

type TypeUCSContext = {
    isApplicationInfoLoading: boolean;
    workspaceApplicationId: number;
    blocked: boolean;
    isActiveApp: boolean;
    appInfo: TypeAppInfo;
    isReportLoading: boolean;
    isOpenExportModal: boolean;
    typeExport: any;
    exportData: any;
    key1cAliasConfigurationList: TypeKeyAliasConfiguration[];
    keyWebAliasConfigurationList: TypeKeyAliasConfiguration[];
    //methods
    getUsersId: () => number[];
    getTokens: (key: string) => Promise<any>;
    getUsersByKey: (key: string) => Promise<any>;
    changeTariffRequest: (data: TypeChangeTariff) => Promise<any>;
    setTypeExport: any;
    setExportData: any;
    setIsOpenExportModal: any;
    exportExcel: (data: TypeExportExcel) => Promise<any>;
};

const UCSContext = createContext<any>({});

function UCSProvider(props: any) {
    const navigate = useNavigate();
    const { users } = useContext(UserContext);
    const { drawerPanels } = useContext(GeneralContext);
    const [workspaceApplicationId, setWorkspaceApplicationId] = useState<number>(0);
    //app info
    const [appInfo, setAppInfo] = useState<TypeAppInfo>();
    const [isOpenExportModal, setIsOpenExportModal] = useState(false);
    const [typeExport, setTypeExport] = useState();
    const [exportData, setExportData] = useState();
    const [isActiveApp, setIsActiveApp] = useState<boolean>(true);
    const [blocked, setBlocked] = useState<boolean>(false);
    const [isApplicationInfoLoading, setIsApplicationInfoLoading] = useState<boolean>(false);
    const [isReportLoading, setIsReportLoading] = useState(false);
    const [key1cAliasConfigurationList, setKey1cAliasConfigurationList] = useState<TypeKeyAliasConfiguration[]>([]);
    const [keyWebAliasConfigurationList, setKeyWebAliasConfigurationList] = useState<TypeKeyAliasConfiguration[]>([]);

    const getUsersId = () => {
        const usersId: number[] = [];

        users?.filter((item: any) => {
            if (item.panels.some((i: any) => i.applicationSysName === Apps.Qwep)) {
                usersId.push(item.userId);
            }
        });
        return usersId;
    };

    const changeTariffRequest = async (data: TypeChangeTariff) => {
        const result = await callPlatformAPI('ucs/change/tariff', 'post', data);
        return result.data;
    };

    useEffect(() => {
        if (drawerPanels?.length) {
            const workspaceApp = drawerPanels?.find((item: any) => item.applicationSysName === Apps.UCS);
            setWorkspaceApplicationId(workspaceApp?.applicationWorkspaceId);
            applicationInfo();
            setBlocked(!workspaceApp.applicationPanelIsActive);
        }
    }, [drawerPanels]);

    useEffect(() => {
        if (blocked) {
            navigate('subscription');
        }
    }, [blocked]);

    const getKeyAliasConfiguration1c = (keys1c: TypeKeyAliasConfiguration[]) => {
        if (keys1c?.length) {
            setKey1cAliasConfigurationList(keys1c);
        }
    };

    const getKeyAliasConfigurationWeb = (keysWeb: TypeKeyAliasConfiguration[]) => {
        if (keysWeb?.length) {
            setKeyWebAliasConfigurationList(keysWeb);
        }
    };

    // аутентификация
    const applicationInfo = async () => {
        setIsApplicationInfoLoading(true);
        const result = await callPlatformAPI(`ucs/auth`, 'get');
        if (result.data.isOk) {
            setAppInfo(result.data.result);
            getKeyAliasConfiguration1c(result.data.result.keys1c);
            getKeyAliasConfigurationWeb(result.data.result.keysWeb);
        } else {
            setIsActiveApp(false);
            navigate('subscription');
        }
        setIsApplicationInfoLoading(false);
    };

    // получить пользователей компании по ключу
    const getUsersByKey = async (key: string) => {
        const result = await callPlatformAPI(`ucs/get/users/${key}`, 'get');
        return result.data;
    };

    // получить токены пользователей 1С компании из словаря
    const getTokens = async (key: string) => {
        const result = await callPlatformAPI(`ucs/get/tokens/1c/${key}`, 'get');
        return result.data;
    };

    const exportExcel = async (data: TypeExportExcel) => {
        const lang = localStorage.getItem('language');
        return await exportExcelFetch('ucs/export/excel', {
            ...data,
            lang,
        });
    };

    return (
        <UCSContext.Provider
            value={
                {
                    isApplicationInfoLoading,
                    workspaceApplicationId,
                    blocked,
                    isActiveApp,
                    appInfo,
                    isReportLoading,
                    isOpenExportModal,
                    typeExport,
                    exportData,
                    key1cAliasConfigurationList,
                    keyWebAliasConfigurationList,
                    //methods
                    getUsersId,
                    getUsersByKey,
                    changeTariffRequest,
                    setTypeExport,
                    setExportData,
                    setIsOpenExportModal,
                    exportExcel,
                    getTokens,
                } as TypeUCSContext
            }
            {...props}
        />
    );
}

const useUCSContext = (): TypeUCSContext => {
    const context = useContext(UCSContext);
    if (!context) {
        console.error('UCSContext');
    }
    return context as TypeUCSContext;
};

export { UCSProvider, useUCSContext };
