import { Button, CircularProgress } from '@mui/material';
import { IconExcel } from '@f_ucs/components/icons/IconExcel';
import { IconList } from '@f_ucs/components/icons/iconList';
import { IconAddCollector } from '@f_ucs/components/icons/IconAddCollector';
import React, { useContext, useState, useEffect } from 'react';
import { ThemeContext } from '@f_context/Theme_context';
import { useUCSContext } from '@f_ucs/context/UCS_context';
import { useCollector } from '@f_ucs/context/collector_context';
import { ReportListModal } from '@f_ucs/components/actionPanel/actionButtonHandlers/ReportListModal/ReportListModal';
import { CreateCollectorModal } from '@f_ucs/components/actionPanel/actionButtonHandlers/CreateCollectorModal/CreateCollectorModal';
import { useWindowSize } from '@f_hooks/index';
import stls from '../../styles/components/actionPanel/ActionPanel.module.sass';
import { useReport } from '@f_ucs/context/report_context';

export function ActionPanel() {
    const { colors } = useContext(ThemeContext);

    const { appInfo } = useUCSContext();

    const { currentType, loading, currentCollector } = useCollector();

    const { exportExcelHandler } = useReport();

    const { width } = useWindowSize();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isOpenAddCollectorModal, setIsOpenAddCollectorModal] = useState(false);
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        setMobile(width < 700);
    }, [width]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'ReportListModal' : undefined;

    return (
        <>
            <div className={stls.container}>
                <div className={stls.title}>
                    {!loading && currentCollector?.name && `Общий отчет: ${currentCollector?.name}`}
                </div>
                <div className={stls.buttonContainer} style={{ flexDirection: mobile ? 'column' : 'row' }}>
                    <Button
                        className={stls.button}
                        style={{ border: `2px solid ${colors.alpha}` }}
                        onClick={() => exportExcelHandler('UCS')}
                        disabled={loading}>
                        <div className={stls.icon}>
                            <IconExcel />
                        </div>
                        <div>Выгрузить</div>
                    </Button>
                    <Button
                        aria-describedby={id}
                        type={'button'}
                        className={stls.button}
                        style={{ border: `2px solid ${colors.alpha}` }}
                        onClick={handleClick}
                        disabled={loading}>
                        <div className={stls.icon}>
                            <IconList color={colors.alpha} />
                        </div>
                        Список отчетов
                    </Button>
                    <Button
                        className={stls.button}
                        style={{ border: `2px solid ${colors.alpha}` }}
                        onClick={() => setIsOpenAddCollectorModal(true)}
                        disabled={loading}>
                        <div className={stls.icon}>
                            <IconAddCollector color={colors.alpha} />
                        </div>
                        Добавить отчет
                    </Button>
                </div>
            </div>
            <ReportListModal
                isOpen={open}
                id={id}
                anchorEl={anchorEl}
                close={handleClose}
                setIsOpenAddCollectorModal={setIsOpenAddCollectorModal}
            />
            <CreateCollectorModal
                open={isOpenAddCollectorModal}
                handleClose={() => setIsOpenAddCollectorModal(false)}
                type={currentType}
                appInfo={appInfo}
            />
        </>
    );
}
