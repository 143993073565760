import { ManualContext } from '@f_qwep/context/Manual_context';
import { SettingsContext } from '@f_qwep/context/Settings_context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import GeneralJoyride from '../GeneralJoyride';
import { useWindowSize } from '@f_hooks/index';
import { IconMaskot } from '@f_components/icons/IconMascot';

export function AddGroupsAccountsManual() {
    const { setChooseManual, newManualValue, setNewManualValue } = useContext(ManualContext);

    const { selectedPageName, setSelectedPageName, open } = useContext(SettingsContext);

    const [run, setRun] = useState(false);

    const [currentStep, setCurrentStep] = useState(0);

    const [steps, setSteps] = useState<any>([
        {
            target: '#settings',
            content: (
                <div>
                    <IconMaskot />
                    <p>Перейдите в раздел "Настройки"</p>
                </div>
            ),
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#menuButtonSettings',
            content: 'Откройте меню настроек',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#group_accounts',
            content: 'Далее в раздел "Группы поставщиков"',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
            placement: 'right',
        },
        {
            target: '#addGroupsAccounts',
            content: 'Нажмите для создания новой группы поставщиков',
            disableBeacon: true,
            hideFooter: true,
            spotlightClicks: true,
        },
        {
            target: '#chooseAccounts',
            content: 'Выберите поставщиков для Вашей группы',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
        {
            target: '#saveNewGroup',
            content: 'Сохраните Вашу группу',
            disableBeacon: true,
            hideFooter: false,
            spotlightClicks: false,
        },
    ]);

    const { width } = useWindowSize();

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/' || window.location.pathname == '/app-qwep') {
            setRun(true);
            setSelectedPageName('accounts');
            if (!newManualValue.settings?.flag) {
                setNewManualValue({ ...newManualValue, settings: { flag: true } });
            }
        }
    }, []);

    useEffect(() => {
        if (width) {
            if (currentStep == 1) {
                // const target = document.querySelector('#menuButtonSettings');
                if (width > 1200) {
                    setCurrentStep((prevStep) => prevStep + 1);
                } else {
                    if (open) {
                        setCurrentStep((prevStep) => prevStep + 1);
                    }
                }
            }
        }
    }, [currentStep, width, open]);

    useEffect(() => {
        if (window.location.pathname == '/app-qwep/settings') {
            setTimeout(() => {
                setCurrentStep((prevStep) => prevStep + 1);
            }, 100);
        } else if (window.location.pathname == '/app-qwep/settings/accounts') {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    }, [window.location.pathname, selectedPageName]);

    const handleJoyrideCallback = (data: any) => {
        const { action, step, index, status, type } = data;

        if (type === 'step:after' && action === 'next' && !step.hideFooter) {
            setCurrentStep((prevStep) => prevStep + 1);
        } else if (type === 'step:before') {
        } else if (
            type === 'step:after' &&
            (action === 'skip' || action === 'close' || action === 'last' || action === 'skip')
        ) {
            setCurrentStep(0);
            setRun(false);
            setChooseManual();
        }
    };

    return (
        <div>
            <GeneralJoyride steps={steps} run={run} callback={handleJoyrideCallback} stepIndex={currentStep} />
        </div>
    );
}
