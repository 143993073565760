import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import stls from '@f_crossdata/styles/components/report/TableReport.module.sass';
import { VirtualTable } from '@f_general/stackTable/VirtualTable';
import { ColumnDef } from '@tanstack/react-table';
import { ThemeContext } from '@f_context/Theme_context';
import { CrossdataContext } from '@f_crossdata/context/Crossdata_context';
import cn from 'classnames';
import { useWindowSize } from '@f_hooks/index';

export function TableReport({ data }: any) {
    const { t } = useTranslation();
    const { colors } = useContext(ThemeContext);
    const { width, height } = useWindowSize();
    const { setTypeExport, setExportData, setOpenExportModal, reportFilter, reportType, appInfo } =
        useContext(CrossdataContext);

    const columns = useMemo<ColumnDef<any>[]>(
        () => [
            {
                id: 'vendor',
                accessorKey: 'vendor',
                header: `${t('crossdata.vendor')}`,
                cell: (info) => <p style={{ marginLeft: '5px' }}>{info.row.original.vendor}</p>,
                footer: (props) => props.column.id,
                size: 100,
            },
            {
                id: 'brand',
                accessorKey: 'brand',
                header: `${t('crossdata.brand')}`,
                cell: (info) => <p className={stls.cellBrand}>{info.row.original.brand}</p>,
                footer: (props) => props.column.id,
                size: 100,
            },
            {
                id: 'article',
                accessorKey: 'article',
                header: `${t('crossdata.article')}`,
                cell: (info) => <p className={stls.cellBrand}>{info.row.original.article}</p>,
                footer: (props) => props.column.id,
                size: 100,
            },
            {
                id: 'minprice',
                accessorKey: 'minprice',
                header: `${t('crossdata.minPrice')}`,
                cell: (info) => (
                    <p className={cn(stls.cellPrice, stls.cellPriceMin)}>
                        {info.row.original.minprice &&
                            `${Math.round(info.row.original.minprice)} ${
                                info.row.original.currency === 'RUB' ? '₽' : '€'
                            }`}
                    </p>
                ),
                footer: (props) => props.column.id,
                size: 50,
            },
            {
                id: 'avgprice',
                accessorKey: 'avgprice',
                cell: (info) => (
                    <p className={cn(stls.cellPrice, stls.cellPriceAvg)}>
                        {info.row.original.avgprice &&
                            `${Math.round(info.row.original.avgprice)} ${
                                info.row.original.currency === 'RUB' ? '₽' : '€'
                            }`}
                    </p>
                ),
                header: `${t('crossdata.avgPrice')}`,
                footer: (props) => props.column.id,
                size: 50,
            },
            {
                id: 'maxprice',
                accessorKey: 'maxprice',
                header: `${t('crossdata.maxPrice')}`,
                cell: (info) => (
                    <p className={cn(stls.cellPrice, stls.cellPriceMax)}>
                        {info.row.original.maxprice &&
                            `${Math.round(info.row.original.maxprice)} ${
                                info.row.original.currency === 'RUB' ? '₽' : '€'
                            }`}
                    </p>
                ),
                footer: (props) => props.column.id,
                size: 50,
            },
            {
                id: 'availabilityTotalByPeriodEnd',
                accessorKey: 'availabilityTotalByPeriodEnd',
                header: `${t('crossdata.availability')}`,
                footer: (props) => props.column.id,
                size: 50,
            },
            {
                id: 'status',
                accessorKey: 'status',
                cell: (info) => (
                    <p className={stls.cellPrice}>{info.row.original.status == 'In stock' ? 'В наличии' : 'Заказ'}</p>
                ),
                header: `${t('crossdata.status')}`,
                footer: (props) => props.column.id,
                size: 50,
            },
            ...(() => {
                let columns: any = [];
                if (reportType === 'avgReq' || reportType === 'avgReqPlusAvgBuy') {
                    columns.push({
                        id: 'analyticCountReq',
                        accessorKey: 'analyticCountReq',
                        cell: (info: any) => (
                            <p className={stls.cellPrice}>
                                {info.row.original.analyticCountReq ? `${info.row.original.analyticCountReq} %` : '0 %'}
                            </p>
                        ),
                        header: 'Доля запросов',
                        footer: (props: any) => props.column.id,
                        size: 50,
                    });
                }
                if (reportType === 'avgBuy' || reportType === 'avgReqPlusAvgBuy') {
                    columns.push({
                        id: 'analyticCountBuy',
                        accessorKey: 'analyticCountBuy',
                        cell: (info: any) => (
                            <p className={stls.cellPrice}>
                                {info.row.original.analyticCountBuy ? `${info.row.original.analyticCountBuy} %` : '0 %'}
                            </p>
                        ),
                        header: 'Доля заказов',
                        footer: (props: any) => props.column.id,
                        size: 50,
                    });
                }
                return columns;
            })(),
        ],
        [data],
    );

    const tableStyles = {
        head: {
            container: {
                padding: '5px',
            },
            base: true,
            title: {
                fontSize: '14px',
            },
            inputFilter: {
                borderRadius: '7px',
            },
        },
        body: {
            rowRadius: true,
            row: {
                background: colors.psi,
                height: '25px',
                textAlign: 'center',
            },
            marginRow: {
                margin: '1px 0',
            },
            cellRadius: true,
        },
    };

    const getFiltredResults = () => {
        let result: any[] = [];

        if (data) {
            result = data;
        }
        if (reportFilter?.length > 0) {
            result = result.filter(
                (row) => reportFilter[0].includes(row.brand) && reportFilter[1].includes(row.article),
            );
        }
        return result;
    };

    const dataTable = useMemo(() => getFiltredResults() ?? [], [data, reportFilter]);

    const exportExcelHandler = () => {
        setTypeExport('report');
        setExportData({ filter: dataTable, all: data });
        setOpenExportModal(true);
    };

    return (
        <div className={stls.container}>
            <VirtualTable
                data={dataTable}
                columnsTable={columns}
                topFilterPanel={true}
                showColumnSearchAction={false}
                heightTable={height - 80}
                configHeader={{
                    fullScrean: true,
                    exportExcel: appInfo?.tariff?.workWithFiles,
                    resize: false,
                    grouping: false,
                    columnFilter: false,
                    sorting: true,
                    columnSearch: true,
                    dragging: true,
                }}
                styles={tableStyles}
                overscan={5}
                estimateSize={25}
                exportExcelHandler={exportExcelHandler}
            />
        </div>
    );
}
