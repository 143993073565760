import { useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete, CircularProgress, MenuItem, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import stls from '@f_qwep/styles/component/inputSearch/InputSearch.module.sass';
import HistorySearch from './HistorySearch';
import { SearchContext } from '@f_qwep/context/Search_context';
import { ThemeContext } from '@f_context/Theme_context';
import SearchIcon from '@mui/icons-material/Search';
import { Alert } from '@f_general/alert';
import { useWindowSize } from '@f_hooks/index';
import { QwepContext } from '@f_qwep/context/Qwep_context';
import useQwepNavigate from '@f_qwep/hooks/useQwepNavigate';

export function InputSearch() {
    const { t } = useTranslation();
    const { colors, currentTheme } = useContext(ThemeContext);
    const {
        getSearchId,
        searchResults,
        inputSearch,
        setInputSearch,
        optionsPriSearch,
        preSearchLoading,
        setOptionsPreSearch,
        getPreSearch,
    } = useContext(SearchContext);
    const { setCongratulationOpenModal } = useContext(QwepContext);
    const navigate = useQwepNavigate();
    const [open, setOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [mobile, setMobile] = useState(false);

    const { width } = useWindowSize();

    useEffect(() => {
        setMobile(width < 480);
    }, [width]);

    const [value, setValue] = useState('');

    useEffect(() => {
        if (inputSearch.length >= 3) {
            setTimeout(() => {
                setValue(inputSearch);
            }, 1);
        }
    }, [inputSearch]);

    useEffect(() => {
        if (inputSearch.toLowerCase() === 'дед') {
            setCongratulationOpenModal(true);
        } else {
            if (inputSearch.length >= 3) {
                if (inputSearch === value) {
                    getOptions();
                }
            }
        }
    }, [value]);

    const getOptions = async () => {
        const res = await getPreSearch(inputSearch);
        if (!res.result?.length) {
            Alert({
                type: 'warning',
                text: 'По этому артикулу нет подсказок!',
                time: 3000,
            });
        }
    };

    useMemo(() => {
        if (inputSearch.length < 3 || !inputSearch) {
            setOpen(false);
            setOptionsPreSearch([]);
            setValue('');
        }
    }, [preSearchLoading, inputSearch]);

    const showOptionsListHandler = (e: any) => {
        e.preventDefault();
        if (!disabled) {
            setTimeout(() => {
                navigate('search/items');
            }, 250);
        }
    };

    const startSearch = async (brand: string, article: string) => {
        setDisabled(true);
        const resultSearchId = await getSearchId({
            brand,
            article,
        });
        if (resultSearchId?.result?.entity?.searchId) {
            await searchResults(resultSearchId.tabId, resultSearchId.result.entity.searchId, article, brand);
        } else if (resultSearchId?.result?.errors) {
            if (resultSearchId.result.errors)
                resultSearchId.result.errors.map((item: any) => {
                    Alert({
                        type: 'warning',
                        text: item.message,
                        time: 8000,
                    });
                });
        } else if (resultSearchId?.status === 'not vendors') {
            Alert({
                type: 'warning',
                text: 'Нет активных поставщиков!',
                time: 8000,
            });
        }

        setDisabled(false);
    };

    const getHighlightedText = (skuInfo: any, article: string) => {
        return (
            <>
                {article && <div className={stls.text} style={{ color: colors.omicron }}>{`${article}\u00A0`}</div>}
                {skuInfo?.brand && (
                    <div className={stls.text} style={{ color: colors.alpha }}>{`– ${skuInfo?.brand}\u00A0`}</div>
                )}
                {skuInfo?.partname && (
                    <div
                        className={stls.textList}
                        style={{ color: colors.omicron }}>{`– ${skuInfo?.partname}\u00A0`}</div>
                )}
            </>
        );
    };

    return (
        <Autocomplete
            id="searchResultInput"
            disabled={disabled}
            className={stls.autocomplete}
            sx={{
                '& .MuiInputBase-root': {
                    padding: '9px 8px 9px 50px !important',
                },
                '& .MuiAutocomplete-endAdornment': {
                    paddingRight: mobile ? '60px' : '100px',
                },
            }}
            onOpen={() => setOpen((prev) => !prev)}
            onClose={() => setOpen((prev) => !prev)}
            open={open}
            freeSolo
            key="search-autocomplete"
            // поиск по описанию и бренду
            getOptionLabel={(option: any) => {
                if (inputSearch) {
                    return inputSearch;
                }
                return inputSearch + ' ' + option?.brand;
            }}
            forcePopupIcon={inputSearch?.length >= 3}
            filterSelectedOptions={false}
            filterOptions={(x) => x}
            // onKeyDown={(e: any) => console.log('AAAAA', e)}
            renderOption={(props, option) => {
                return (
                    <>
                        <MenuItem
                            {...props}
                            key={option?.brand}
                            className={stls.itemList}
                            onClick={() => startSearch(option?.brand, inputSearch)}>
                            {getHighlightedText(option, inputSearch)}{' '}
                        </MenuItem>
                    </>
                    // <div id='helpBrand' onClick={() => startSearch(option?.brand, inputSearch)}>
                    //   <li {...props} key={option?.brand} className={stls.itemList}>
                    //   {getHighlightedText(option, inputSearch)}
                    //   </li>
                    // </div>
                );
            }}
            onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                    setInputSearch(value.replace(/[^a-zA-Zа-яА-Я0-9]+/g, '').toUpperCase());
                    if (value.length < 3 || !value) {
                        setOptionsPreSearch([]);
                    }
                } else if (reason === 'clear') {
                    setOptionsPreSearch([]);
                    setInputSearch('');
                    setValue('');
                }
            }}
            options={optionsPriSearch}
            isOptionEqualToValue={(option, value) => option.inputSearch === value.article}
            size={'small'}
            renderInput={(params) => (
                <div className={stls.inputContainer}>
                    <div className={stls.historySearch}>
                        <HistorySearch />
                    </div>

                    <form className={stls.form} onSubmit={(e: any) => showOptionsListHandler(e)}>
                        <TextField
                            className={stls.inputSearch}
                            {...params}
                            variant="outlined"
                            value={inputSearch}
                            sx={{
                                '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                    color: colors.omicron,
                                },
                                '& .MuiOutlinedInput-root': {
                                    height: '43px',
                                    '&:hover fieldset': {
                                        border: `1px solid ${disabled ? colors.beta : colors.alpha} !important`,
                                    },
                                },
                            }}
                            InputProps={{
                                ...params.InputProps,
                            }}
                        />
                        {preSearchLoading && (
                            <CircularProgress
                                className={stls.skuLoading}
                                size={'20px'}
                                style={{ color: colors.alpha }}
                            />
                        )}
                        <button
                            className={
                                currentTheme == 'dark'
                                    ? stls.btnSearchDark
                                    : currentTheme == 'vinpin'
                                    ? stls.btnSearchVinPin
                                    : stls.btnSearch
                            }
                            disabled={inputSearch.length < 3 ? true : disabled}
                            style={{
                                background: disabled ? colors.beta : colors.alpha,
                            }}>
                            <div className={stls.icon}>
                                {disabled ? (
                                    <CircularProgress
                                        className={stls.loading}
                                        size={'20px'}
                                        style={{ color: colors.psi }}
                                    />
                                ) : (
                                    <SearchIcon sx={{ color: colors.psi }} />
                                )}
                            </div>
                        </button>
                    </form>
                </div>
            )}
        />
    );
}
